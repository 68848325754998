.admin-service-sign-up-section {
  font-size: 0.75rem;
  line-height: 2.4rem;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    font-size: 1.375rem;
    line-height: 4.75rem;
  }

  &__container {
    margin-top: 3rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover &__cta {
    background-color: transparent;
    background-image: url("/assets/client/arrow-right.svg");
    border: 1px solid $ride-palette-black;
    background-position: 49% 49%;
  }

  &__link {
    font-size: 0.75rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  &__cta {
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    vertical-align: middle;
    margin-left: 0.5rem;
  }
}
