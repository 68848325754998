.typography {
  font-family: $font-family-figtree;
  font-style: normal;

  &--light {
    font-weight: $ds-font-weight-light;
  }

  &--heavy {
    font-weight: $ds-font-weight-heavy;
  }

  &--headline-100 {
    font-size: 2rem;
    line-height: 2.5rem;

    &.typography--light {
      letter-spacing: -0.0625rem;
    }
  }

  &--headline-200 {
    font-size: 1.5rem;
    line-height: 1.75rem;

    &.typography--light {
      letter-spacing: -0.0625rem;
    }
  }

  &--headline-300 {
    font-size: 1.25rem;
    line-height: normal;
  }

  &--paragraph-100 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &--paragraph-200 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &--paragraph-300 {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &--paragraph-400 {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}
