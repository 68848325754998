.real-estate-section {
  &__real-estate-card {
    height: 16rem;
  }

  &__real-estate-highlight {
    background-image: url("/assets/real-estate/real-estate-background.png");
    background-position: center top;
    background-size: 100% auto;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: lighten;
  }
}
