.legal-documents-confirmation-item {
  display: flex;
  flex-direction: column;
  padding: map-get($v2-spacers, 42) map-get($v2-spacers, 52);

  &__context {
    margin-bottom: map-get($v2-spacers, 12);
  }

  &__download-button {
    margin-bottom: map-get($v2-spacers, 16);
    border-radius: map-get($v2-spacers, 12);

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &__text {
        margin-left: map-get($v2-spacers, 6);
      }
    }
  }
}
