.trustpilot-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;

  &__review-count {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    position: relative;
    top: 0.125rem;
  }
}
