.download-fill-and-upload-pending-task {
  &__submit {
    width: 100%;
    margin-top: 2rem;
  }

  &__download-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__upload-section {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 2rem;
    }
  }
}
