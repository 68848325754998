.v2-button-close {
  display: inline-flex;
  cursor: pointer;
  color: $ride-palette-black;
  border-radius: $v2-border-radius-circle;
  width: map-get($v2-spacers, 44);
  height: map-get($v2-spacers, 44);
  background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/close.svg") no-repeat center;
  background-color: $ride-palette-grey-100;
  background-size: map-get($v2-spacers, 12);
  border-radius: $v2-border-radius-circle;

  &:hover {
    background-color: $ride-palette-grey-300;
  }
}
