.click-to-copy {
  display: inline-block;

  &__copy-button {
    border: 0;
    background-color: transparent;
    padding: 0;

    &--outline {
      border-radius: map-get($v2-spacers, 4);
      border: 1px solid $primary-success-700;
      padding: map-get($v2-spacers, 4) map-get($v2-spacers, 8);
    }

    &--outline#{&}--black {
      background-color: $neutral-50;

      &:active {
        background-color: $neutral-200;
      }

      &:hover {
        background-color: $neutral-100;
      }
    }

    &--outline#{&}--green {
      background-color: $primary-success-50;

      &:active {
        background-color: $primary-success-200;
      }

      &:hover {
        background-color: $primary-success-100;
      }
    }

    &--black {
      color: $ride-palette-black;
      border-color: $ride-palette-black;
    }

    &--green {
      color: $primary-success-700;
      border-color: $primary-success-700;
    }
  }

  &__confirmation-container {
    height: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-left: 50px;
      margin: auto;
    }
  }

  &__confirmation {
    @include h6();
    right: 0;
    top: 35px;
    position: absolute;
    color: $white;
    background-color: $ride-palette-black;
    text-align: center;
    border-radius: $border-radius-md;
    padding: map-get($spacers, 3) map-get($spacers, 5);
    white-space: nowrap;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 11px 45px;
      max-width: 250px;
      right: unset;
      width: auto;
    }

    &:before {
      content: " ";
      background-color: $ride-palette-black;
      width: 20px;
      height: 20px;
      display: block;
      border-radius: $border-radius-xs;
      transform: rotate(45deg) skew(15deg, 15deg);
      position: absolute;
      top: -7px;
      right: 50px;

      @include media-breakpoint-up(md) {
        right: calc(50% - 9px);
      }
    }

    &--hidden {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }

    &--show {
      opacity: 1;
    }
  }
}
