.upload-to-folder {
  &__hint {
    @include h10();
    vertical-align: middle;
    margin-left: map-get($spacers, 3);
    display: none;

    @include desktop() {
      display: inline-block;
    }
  }

  &__filename {
    @include h8-light();
    color: $ride-palette-grey-600;

    &--error {
      color: $ride-palette-error-light;
    }
  }

  &__error-feedback {
    position: relative;
    margin-top: map-get($spacers, 21);
  }

  &__repeat-link {
    @include h9-light();
    margin-top: map-get($spacers, 4);

    @include desktop() {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
    }
  }
}
