.wepa-upsell {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 37.5rem;
  margin: auto;

  &__feature-list {
    padding-left: 1.5rem;
    margin: 0;
  }

  &__feature {
    & + & {
      margin-top: 1rem;
    }
  }

  &__feature-text {
    display: block;
    white-space: pre-wrap;
  }

  &__price {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 4);
    align-items: flex-end;
  }

  &__ctas {
    display: flex;
    flex-direction: row;
    gap: map-get($v2-spacers, 16);
    justify-content: flex-end;
  }

  &__cta {
    max-width: fit-content;
  }
}
