$ride-palette-white: #ffffff;
$ride-palette-white-100: #e5e5e5;
$ride-palette-tilt: #fbfbfb;
$ride-palette-grey-000: #f0f0f0;
$ride-palette-grey-100: #f7f7f7;
$ride-palette-grey-200: #e1e1e1;
$ride-palette-grey-300: #ececec;
$ride-palette-grey-400: #dadada;
$ride-palette-grey-500: #e8e8e8;
$ride-palette-grey-600: #a3a3a3;
$ride-palette-grey-700: #b6b6b6;
$ride-palette-grey-800: #56595c;
$ride-palette-grey-900: #606367;
$ride-palette-black: #303436;
$ride-palette-light-green: #f2fff9;
$ride-palette-pastel-light-green: #dcfde7;
$ride-palette-pastel-green: #9ef8bc;
$ride-palette-green: #39d390;
$ride-palette-dark-green: #279766;
$ride-palette-error: #f53434;
$ride-palette-error-dark: #c12727;
$ride-palette-error-light: #f89090;
$ride-palette-red: #ff5b5b;
$ride-palette-red-transparent-1: rgba(255, 91, 91, 0.1);
$ride-palette-red-transparent-2: rgba(255, 91, 91, 0.3);
$ride-palette-pale-red: #ed6661;
$ride-palette-pale-cyan: #82d6e8;
$ride-palette-pale-cyan-light: #e0faff;
$ride-palette-bright-red: #f53434;
$ride-soft-orange: #e5ae5c;
$ride-warning: #fbf2d2;
$ride-palette-yellow-light: #fff3e0;
$ride-palette-yellow: #ffc266;
$ride-palette-yellow-dark: #e5ae5c;
$ride-palette-extra-dark-grey: #303436;
$ride-palette-neutrals-black-65: #787b7c;
$ride-palette-red-light: #fff4f4;
$ride-palette-light-grey: #fafafa;
$ride-palette-grey-medium-dark: #969696;
$ride-palette-purple: #dda2eb;
$ride-palette-light-purple: #f4ecfd;
$ride-transition-time: 0.3s ease;

$ride-input-border-color: #eaebeb;
$ride-input-border-color-focus: #595d5e;
$ride-input-text-color: #000;

$primary: #53d0c4;
$enable-gradients: true;
$info: #b6b6b6;
$success: #48ffaf;

$dark: $ride-palette-black;

$ride-extra-light-grey: #a3a3a3;
$ride-extra-dark-grey: #303436;

// custom variables

$ride-active-bg-img: linear-gradient(159deg, rgba(72, 255, 175, 0.89), #5ddbcf);

// bootstrap overrides

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-heavy: 900;

/*
$container-max-widths: (
  xl: 1366px
);*/

$font-family-sans-serif: "Avenir", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-display: "Bebas Neue", sans-serif;
$font-family-figtree: "Figtree", sans-serif;
$font-size-base: 0.9rem;

$headings-font-weight: $font-weight-bold;

$ride-radius: 0.625rem;
$border-radius: 0;
$border-radius-xxs: 2px;
$border-radius-xs: 5px;
$border-radius-sm: 8px;
$border-radius-md: 10px;
$border-radius-md-extra: 16px;
$border-radius-lg: 1rem;

// modals

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    308: (
      $spacer * 0.094
    ),
    309: (
      $spacer * 0.125
    ),
    1: (
      $spacer * 0.25
    ),
    14: (
      $spacer * 0.3125
    ),
    307: (
      $spacer * 0.313
    ),
    15: (
      $spacer * 0.375
    ),
    2: (
      $spacer * 0.5
    ),
    21: (
      $spacer * 0.625
    ),
    22: (
      $spacer * 0.7
    ),
    23: (
      $spacer * 0.75
    ),
    25: (
      $spacer * 0.875
    ),
    27: (
      $spacer * 0.9
    ),
    3: $spacer,
    31: (
      $spacer * 1.1
    ),
    32: (
      $spacer * 1.125
    ),
    35: (
      $spacer * 1.25
    ),
    37: (
      $spacer * 1.35
    ),
    4: (
      $spacer * 1.5
    ),
    405: (
      $spacer * 1.56
    ),
    41: (
      $spacer * 1.62
    ),
    42: (
      $spacer * 1.75
    ),
    45: (
      $spacer * 1.875
    ),
    306: (
      $spacer * 1.938
    ),
    47: (
      $spacer * 2
    ),
    475: (
      $spacer * 2.2
    ),
    48: (
      $spacer * 2.5
    ),
    49: (
      $spacer * 2.75
    ),
    5: (
      $spacer * 3
    ),
    52: (
      $spacer * 3.125
    ),
    53: (
      $spacer * 3.4375
    ),
    54: (
      $spacer * 3.625
    ),
    55: (
      $spacer * 3.75
    ),
    6: (
      $spacer * 4.5
    ),
    65: (
      $spacer * 5
    ),
    66: (
      $spacer * 5.4
    ),
    67: (
      $spacer * 5.7
    ),
    7: (
      $spacer * 6
    ),
    72: (
      $spacer * 7.4
    ),
    112: (
      $spacer * 7
    ),
    8: (
      $spacer * 12
    ),
    9: (
      $spacer * 15.125
    ),
    305: (
      $spacer * 19.063
    ),
    800: (
      $spacer * 50
    )
  ),
  $spacers
);

// custom-controls (e.g. radios, checkboxes, etc.)
$custom-control-gutter: 0.5rem;
$custom-control-indicator-size: 1.25rem;

// Globals
$ride-header-height: 70px;
$ride-header-height-sm: 70px;

// Override `hamburger` styles
$hamburger-padding-x: map-get($spacers, 45) !default;
$hamburger-padding-y: map-get($spacers, 45) !default;
$hamburger-layer-width: map-get($spacers, 45) !default;
$hamburger-layer-height: map-get($spacers, 1) !default;
$hamburger-layer-spacing: map-get($spacers, 1) !default;
$hamburger-layer-color: $success !default;
$hamburger-layer-border-radius: none !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: true !default;
$hamburger-hover-filter: none !default; //opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;
$hamburger-types: (spin) !default;

// link customizations
$link-color: $ride-palette-green !default;
$link-decoration: none !default;
$link-hover-color: $ride-palette-dark-green !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Icons
$icon-size-base: 24px;

@function vh-fix($number, $offset: 0) {
  @return calc(var(--vh, 1vh) * #{$number} + #{$offset});
}

