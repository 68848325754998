.text-input {
  width: 100%;
  height: 48px;
  border-radius: $trader-gmbh-input-border-radius;
  border: solid 2px $trader-gmbh-input-border-color;
  background-color: $white;
  box-shadow: none;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: $trader-gmbh-black;
  padding-left: 10px;
  padding-right: 10px;

  &:focus {
    border: solid 2px $trader-gmbh-input-border-color-focus;
    box-shadow: none;
  }
}

select.form-control {
  @extend .text-input;

  background-position: right 10px center;
  background-size: 15px;
  background-image: $trader-gmbh-fa-chevron-down-image;

  &:disabled {
    color: $trader-gmbh-disabled-color;
  }
}

.invalid-text-input {
  .form-control.is-invalid {
    background-image: none;
  }
}

.valid-text-input {
  .form-control.is-valid {
    background-position: right 10px center;
    background-size: 15px;
    background-image: $trader-gmbh-fa-chevron-down-image;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}
