.drag-drop-pop-up-splashscreen {
  position: fixed;
  bottom: 2%;
  left: 50%;
  max-width: 488px;
  transform: translateX(-50%);
  z-index: 1;
  background-color: $success;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-xs;
  padding: 25px 60px;

  &__image {
    size: 4px;
  }

  &__hint {
    @include h8-light();
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
  }
}
