.continue-order-banner {
  display: flex;
  flex-direction: row;
  padding: map-get($v2-spacers, 16);
  border-radius: map-get($v2-spacers, 4);
  background: $informational-100;
  justify-content: space-between;
  gap: map-get($v2-spacers, 64);
  align-items: center;

  @include media-breakpoint-down(xs) {
    gap: map-get($v2-spacers, 32);
    flex-direction: column;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    line-height: map-get($v2-spacers, 20);
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-bold;
  }

  &__message {
    line-height: map-get($v2-spacers, 20);
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-normal;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: map-get($v2-spacers, 16);

    @include media-breakpoint-down(xs) {
      align-self: end;
    }
  }

  &__cancel {
    cursor: pointer;
    line-height: map-get($v2-spacers, 16);
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-bold;
    color: $primary-success-600;

    &:hover {
      color: $primary-success-700;
      font-weight: $font-weight-bolder;
    }
  }

  &__continue {
    cursor: pointer;
    border: 1px solid $primary-success-700;
    border-radius: map-get($v2-spacers, 4);
    padding: map-get($v2-spacers, 8) map-get($v2-spacers, 16);
    background: $primary-success-50;
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-bold;
    line-height: map-get($v2-spacers, 16);
    text-align: center;

    a {
      color: $primary-success-700;
    }
  }
}
