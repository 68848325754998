.ride-progress-bar {
  background-color: #eaebeb;
  border-radius: 4px;

  &--progress {
    background-color: #000000;
    height: 4px;
    border-radius: 4px;
    width: var(--percentage);
    max-width: 100%;
    overflow: hidden;
  }
}
