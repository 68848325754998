.phoneForm {
  text-align: center;
  max-width: 21.75rem;
  margin: 0 auto;

  &__description {
    font-size: 1rem;
  }

  &__input {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    text-align: left;

    //label {
    //  font-weight: bold;
    //  font-size: 0.875rem;
    //  color: $ride-palette-black;
    //}

    //.phone-input {
    //  box-shadow: none;
    //  border: 1px solid $ride-palette-grey-300 !important;
    //  border-radius: 5px !important;
    //
    //  &--error {
    //    border: 1px solid $ride-palette-error !important;
    //  }
    //}

    //input {
    //  width: 100% !important;
    //  border: none !important;
    //  &:focus {
    //    box-shadow: none !important;
    //  }
    //}

    .flag-dropdown {
      border: none !important;
    }
  }
  &__error {
    color: $ride-palette-error;
    margin-top: 0.313rem;
  }
}
