.home-tab {
  display: flex;
  flex-direction: column;
  gap: 0.38rem;
  width: 41.38rem;
  margin: 2.69rem auto 1rem;

  &__tasks-header {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
  }

  &__welcome {
    color: $text-icon-primary-black;
  }
  &__welcome-subtitle {
    color: $text-icon-disabled-black;
  }

  @media only screen and (max-width: 765px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  //
  @media only screen and (max-width: 512px) {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
