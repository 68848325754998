.ride-table-cell {
  @include h8-light;
  grid-column: 1 / -1;

  @include desktop {
    grid-column: initial;
  }

  &--aligned {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  &-secondary-text {
    color: $ride-palette-grey-600;
  }

  &-document {
    &-button--container {
      display: flex;
      align-items: center;

      &:hover {
        .ride-table-cell-document-icon {
          color: $ride-palette-dark-green;
        }
      }
    }

    &-icon {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      color: $ride-palette-green;
    }

    &-text {
      font-size: 16px;
      color: $ride-extra-dark-grey;
    }
  }

  &-action-done {
    width: 24px;
    height: 24px;
    color: $ride-palette-green;
  }

  &-no-action {
    font-size: 14px;
    color: $ride-palette-grey-200;
    font-style: italic;
  }

  &-action-download-icon {
    width: 24px;
    height: 24px;
    color: $ride-palette-green;

    &:hover {
      color: $ride-palette-dark-green;
    }
  }

  &.secondary-text-color {
    color: $ride-palette-grey-600;
  }
}
