.auth-message {
  text-align: center;
  width: 306px;
  margin: auto;

  &__text {
    @include h5();
    color: $ride-palette-black;
    padding-top: map-get($spacers, 14);
    padding-bottom: map-get($map: $spacers, $key: 53);
  }
}
