.upload-file-list {
  padding-top: map-get($spacers, 47);
  padding-bottom: map-get($spacers, 47);

  @include media-breakpoint-up(md) {
    padding-top: map-get($spacers, 6);
    padding-left: map-get($spacers, 65);
    padding-right: map-get($spacers, 65);

    &__upload-file {
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      align-self: stretch;
    }
  }

  &__upload-file {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: map-get($spacers, 47);

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($spacers, 21);
      flex-direction: row;
    }
  }

  &__file-name {
    @include h8-light();
    display: inline-block;
    width: calc(100% - 25px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $ride-palette-black;
    vertical-align: middle;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__checkbox {
    width: 100%;
    margin-bottom: $spacer;

    @include media-breakpoint-up(md) {
      width: 50%;
      padding-right: $spacer;
      margin-bottom: 0;
    }
  }

  &__folder-select-wrapper {
    margin: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      padding-left: $spacer;
    }
  }
}
