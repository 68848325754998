.ltb-overview {
  display: flex;
  flex-direction: column;
  width: 47.1875rem;
  align-items: stretch;
  justify-content: center;
  padding-top: map-get($v2-spacers, 48);
  padding-bottom: 13.5rem;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__banner {
    margin-bottom: map-get($v2-spacers, 32);
  }

  &__progress {
    margin-bottom: map-get($v2-spacers, 28);

    .ride-progress-bar--progress {
      background-color: #b98bf9;
    }
  }

  &__progress-text {
    font-weight: 600;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    padding-left: map-get($v2-spacers, 6);
    margin-top: map-get($v2-spacers, 14);
  }

  &__cards {
    display: flex;
    flex-direction: column;
  }

  &__content-box {
    background-color: $ride-palette-grey-100;
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 20);
    margin-bottom: map-get($v2-spacers, 16);
  }

  &__content-title {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    margin-bottom: map-get($v2-spacers, 6);
  }

  &__content {
    color: $ride-palette-grey-600;
    font-weight: 500;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: map-get($v2-spacers, 16);
  }

  &__primary-action-btn {
    flex-grow: 1;
    height: map-get($v2-spacers, 48);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    color: $ride-palette-black;
    background-color: #efe3ff;
    border: none;
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 10) map-get($v2-spacers, 20);
    transition: 0.2s ease-in all;
    text-align: center;

    &:hover {
      background-color: #dfc8ff;
    }

    &:active {
      background-color: #c49efa;
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      cursor: auto;
      color: $ride-palette-grey-600;
      background-color: $ride-palette-grey-300;
    }
  }

  &__secondary-action-btn {
    flex-grow: 1;
    height: map-get($v2-spacers, 48);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    color: $ride-palette-white;
    background-color: $ride-palette-black;
    border: none;
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 10) map-get($v2-spacers, 20);
    transition: 0.2s ease-in all;

    &:hover {
      background-color: rgba($ride-palette-black, 0.92);
    }
  }

  &__action-text-link {
    flex-grow: 0.1;
    font-size: map-get($v2-spacers, 14);
  }

  &__verify-id-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: map-get($v2-spacers, 32) map-get($v2-spacers, 24);

    &__label {
      font-size: map-get($v2-spacers, 14);
      line-height: map-get($v2-spacers, 20);
      color: $ride-palette-grey-600;
    }

    &__person-name {
      font-size: map-get($v2-spacers, 20);
      line-height: map-get($v2-spacers, 28);
      font-weight: 500;
      color: $ride-palette-black;
      white-space: nowrap;
    }

    &__button {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: map-get($v2-spacers, 4);

      &-icon {
        display: inline-block;
        width: map-get($v2-spacers, 20);
        height: map-get($v2-spacers, 20);
        background-image: url("/assets/sharedComponents/arrow-top-right-white.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &__fund-account-card {
    &__button {
      margin-top: map-get($v2-spacers, 16);
    }

    &__alert-message-after-transference {
      margin-top: map-get($v2-spacers, 12);
    }
  }
}

.your-access-information {
  display: flex;
  flex-direction: column;

  .copyable-information {
    justify-content: flex-start;
  }
}
