.ltb-review-field {
  &__label {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-grey-600;
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__value {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 22);
    color: $ride-palette-black;
  }

  &__missing-mandatory-information {
    width: fit-content;
    padding: map-get($v2-spacers, 4) map-get($v2-spacers, 8);
    background-color: $ride-warning;
    border-radius: map-get($v2-spacers, 100);
  }
}
