.ride-form-layout {
  display: flex;
  flex-direction: column;
  min-height: vh-fix(100);

  &__topbar {
    display: flex;
    height: 72px;
    padding: map-get($v2-spacers, 28) map-get($v2-spacers, 24);
    border-bottom: 1px solid $v2-border-color;
    align-items: center;
  }

  &__logo {
    flex-grow: 1;
    flex-basis: 0;
  }

  &__logo-image {
    width: 85.86px;
    height: 21.29px;
    margin-right: map-get($v2-spacers, 24);
    background: url("/assets/RIDE-Logo-Black.svg") no-repeat center;
    background-size: 100%;
  }

  &__title {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 20);
    line-height: 1.2;
  }

  &__close {
    display: flex;
    flex: 0 0 map-get($v2-spacers, 44);
    flex-grow: 1;
    flex-basis: 0;
    justify-content: flex-end;
  }

  &__body {
    display: flex;
    flex-grow: 1;
    height: calc(#{vh-fix(100)} - 80px - 27px);
  }

  &__chapters {
    width: 24%;
    flex-shrink: 0;
    border-right: 1px solid $v2-border-color;
  }

  &__content {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__contextual-help {
    width: 24%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $v2-border-color;
  }

  &__step-navigation-controls {
    display: flex;
    margin-top: map-get($v2-spacers, 44);

    &--modal-control-area {
      padding: 1.75rem;
      border-top: 1px solid #ececec;
      color: #303436;
      font-weight: 500;
      width: 100%;
      margin-top: 0;
    }

    .back-button-obsolete {
      margin-right: map-get($v2-spacers, 14);
    }
  }

  &__next-button {
    height: map-get($v2-spacers, 48);
  }

  &__footer {
    text-align: center;
  }

  &__step-heading {
    display: block;
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 36);
    line-height: map-get($v2-spacers, 48);
    color: $ride-palette-black;
    margin-bottom: map-get($v2-spacers, 32);
  }
}
