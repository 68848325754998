.button-card {
  width: 288px;
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $ride-palette-grey-100;
  border-radius: map-get($v2-spacers, 12);
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  }

  &:active {
    box-shadow: none;
  }

  &:active .button-card-icon {
    background: $ride-palette-grey-200 no-repeat center !important;
  }

  &:hover .button-card-icon {
    background: $ride-palette-grey-300 no-repeat;
  }

  &:hover .button-card-icon--plus-BLACK {
    background-image: url("/assets/sharedComponents/plus-grey-icon.svg");
  }

  &:active .button-card-icon--plus-BLACK {
    background-image: url("/assets/sharedComponents/plus-black-icon.svg");
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-basis: 65%;
  }

  &--text {
    font-family: $font-family-sans-serif;
    font-size: map-get($v2-spacers, 18);
    line-height: map-get($v2-spacers, 22);
    color: $ride-palette-black;
    font-weight: $font-weight-heavy;
    margin-top: map-get($v2-spacers, 10);
    text-align: center;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 0;
    border-radius: $v2-border-radius-circle;
    width: map-get($v2-spacers, 64);
    height: map-get($v2-spacers, 64);
    background: $ride-palette-grey-200 no-repeat center;
    background-size: map-get($v2-spacers, 20);
    position: relative;
    text-decoration: none;

    &--plus-BLACK {
      width: map-get($v2-spacers, 16);
      height: map-get($v2-spacers, 16);
      background-image: url("/assets/sharedComponents/plus-black-icon.svg");
      object-fit: cover;
      object-position: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
