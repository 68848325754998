.order-details-page {
  &__card {
    @include shadow3();
    width: auto;
    max-height: 75rem;
    overflow-y: scroll;
    margin-bottom: map-get($spacers, $key: 48);
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
  }

  &__shelf-company-section {
    box-shadow: initial;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: map-get($spacers, 32);
    padding-bottom: map-get($spacers, 25);
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 5);
  }

  &__unreserve-button {
    margin-right: map-get($spacers, $key: 3);
  }
}
