.setup-account-card {
  &__complete-btn-container {
    display: flex;
    margin-top: map-get($v2-spacers, 32);
  }

  &__complete-account-setup-btn {
    flex-grow: 1;
    height: map-get($v2-spacers, 48);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    color: $ride-palette-black;
    background-color: #efe3ff;
    border: none;
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 10) map-get($v2-spacers, 20);
    transition: 0.2s ease-in all;

    &:hover {
      background-color: #dfc8ff;
    }

    &:active {
      background-color: #c49efa;
    }
  }

  &__step {
    display: flex;
    flex-direction: column;
    background: $ride-palette-light-grey;
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 18);

    & + & {
      margin-top: map-get($v2-spacers, 20);
    }

    &-description {
      color: $ride-palette-grey-600;
      font-weight: 500;
      font-size: map-get($v2-spacers, 16);
      line-height: map-get($v2-spacers, 24);
      margin-bottom: map-get($v2-spacers, 8);
    }
  }

  &__copyable-info {
    width: map-get($v2-spacers, 40);
  }
}
