.documents-list {
  &__shareholder-name {
    font-size: map-get($v2-spacers, 20);
    font-weight: $font-weight-medium;
    line-height: map-get($v2-spacers, 20);
    margin-bottom: map-get($v2-spacers, 18);
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, max-content);
    gap: map-get($v2-spacers, 24);

    .document-card {
      margin-bottom: map-get($v2-spacers, 48);
    }
  }
}

.review-documents {
  &__subsection-title {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-grey-600;
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__list {
    display: grid;
    grid-row-gap: map-get($v2-spacers, 16);
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: map-get($v2-spacers, 22);
  }

  &__entry {
    display: contents;
  }

  &__document-name {
    color: $ride-palette-black;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 28);
  }

  .review-personal-documents {
    &__person-name {
      color: $ride-palette-black;
      font-weight: $font-weight-bold;
      font-size: map-get($v2-spacers, 16);
      line-height: map-get($v2-spacers, 22);
      margin-bottom: map-get($v2-spacers, 12);
    }
  }
}
