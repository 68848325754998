.ride-date-picker-v2 {
  display: flex;
  flex-direction: column;
  margin: map-get($v2-spacers, 30) 0 map-get($v2-spacers, 30) 0;

  &__label {
    color: $ride-extra-dark-grey;
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-bold;
    line-height: map-get($v2-spacers, 22);
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__input {
    border: 1px solid $ride-input-border-color;
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 12) map-get($v2-spacers, 16);
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  .selected {
    font-weight: $font-weight-bold;
  }

  .error {
    border: 1px solid $ride-palette-error;
  }
}
