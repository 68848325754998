.ride-link {
  color: initial;
  text-decoration: none;

  &:hover {
    color: initial;
    text-decoration: none;
  }

  &--DECORATED {
    text-decoration: underline !important;
  }
}
