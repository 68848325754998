.brokerage-account-list {
  .card-body {
    padding-top: 0;
  }

  &--add {
    border: 0;
    font-size: 0.875rem;
    color: $ride-palette-green;
    padding-left: 15px;
    margin-top: map-get($spacers, 23);
    background-color: transparent;
    background-image: url("/assets/client/plus.svg");
    background-position: left center;
    background-repeat: no-repeat;
  }

  &--edit {
    background: transparent;
    border: 0;
    font-size: 1rem;
    color: $ride-palette-grey-600;
    text-decoration: underline;
    margin-top: map-get($spacers, 23);

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &--empty {
    color: $ride-palette-grey-600;
    font-size: 1rem;
    margin-bottom: map-get($map: $spacers, $key: 3);
  }

  &--item {
    color: $ride-palette-grey-800;
    font-size: 1rem;
    display: flex;
    align-items: stretch;
  }

  &__state {
    text-align: right;

    @include media-breakpoint-up(md) {
      text-align: initial;
    }
  }

  &__activation-info {
    @include h10-light();
    padding: map-get($spacers, 23) 0;
  }
}

.brokerage-account-list-tokenUpdatedAt {
  color: $ride-extra-light-grey;
}

.soft-badge {
  font-weight: 400;
  font-size: 14px;
  color: $ride-palette-black;

  &--warning {
    background: $ride-palette-yellow-light;
    border: 1px solid $ride-palette-yellow;
  }

  &--info {
    background: $ride-palette-pale-cyan-light;
    border: 1px solid $ride-palette-pale-cyan;
  }

  &--danger {
    background: $ride-palette-red-light;
    border: 1px solid $ride-palette-red;
  }
}
