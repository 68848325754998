.ride-select-field {
  div[class*="option"] {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .option {
      &__lock-icon {
        align-self: baseline;
      }
    }
  }

  &.is-invalid {
    & > div[class*="control"] {
      border: 2px solid $ride-palette-bright-red;
    }
  }
}

.option {
  &--fixed-option + :not(&--fixed-option) {
    border-top: 1px solid $ride-palette-grey-200;
  }

  &__name {
    &--fixed-option {
      font-weight: bold;
    }

    &--fixed-description {
      color: $ride-extra-light-grey;
    }

    @for $i from 0 through 5 {
      &--indent-#{$i} {
        margin-left: #{$i}rem;
      }
    }
  }
}
