.wepa-general-data {
  &__bookings-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: map-get($v2-spacers, 16);
  }

  &__bookings-table {
    margin-top: map-get($v2-spacers, 16);
  }
}
