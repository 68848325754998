.notary-appointment-task {
  margin-bottom: map-get($spacers, 53);

  .form-group {
    margin: 0;

    .form-label {
      display: none;
    }

    textarea {
      border: 1px solid $ride-input-border-color;
      border-radius: $border-radius-sm;
      box-shadow: none;
      padding: map-get($spacers, 3);
      font-size: 1rem;
      color: $ride-palette-black;
      height: 400px;
      @include media-breakpoint-up(md) {
        height: 265px;
      }
    }
  }

  &__completed {
    background-color: $ride-input-border-color;
    border-radius: $border-radius-sm;
    padding: map-get($spacers, 3);
    box-shadow: none;
    font-size: 1rem;
    color: $ride-palette-black;
  }
}
