.ltb-dashboard-action-card {
  display: flex;
  height: min-content;
  border-radius: map-get($v2-spacers, 8);
  padding: map-get($v2-spacers, 16);
  background-color: $ride-palette-white;

  &__text {
    flex-grow: 1;
    margin-left: map-get($v2-spacers, 10);
    height: min-content;
  }

  &__title {
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-bolder;
  }

  &__description {
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-medium;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: map-get($v2-spacers, 40);
    width: 9.5rem;
    background-color: $ride-palette-white;
    border: 1px solid $ride-palette-white-100;
    border-radius: map-get($v2-spacers, 8);

    color: $ride-palette-black;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 16);

    transition: background-color 0.2s ease-in-out;

    &:hover {
      color: $ride-palette-black;
      background-color: $ride-palette-grey-100;
    }

    &:active {
      background-color: $ride-palette-grey-000;
    }
  }
}
