.club-banner {
  @include box-hover-shadow();
  cursor: pointer;
  border-radius: $border-radius-md;
  overflow: hidden;
  display: block;
  border: 0;
  padding: 0;
  text-align: left;
  color: $ride-palette-black;
  transition: all $ride-transition-time;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 280px;
  }

  &:active,
  &:hover {
    color: $ride-palette-black;
  }

  &:hover {
    .club-banner__overlay {
      opacity: 0;
    }

    .default {
      display: none;
    }

    .hovered {
      display: inline-block;
    }
  }

  .hovered {
    display: none;
  }

  &__ride-club-mobile {
    @include h8-light();
    line-height: 2.22;

    svg {
      vertical-align: baseline;
      margin-right: map-get($spacers, 15);
      width: 22.5px;
      height: 16.5px;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__title-container {
    background: linear-gradient(96.3deg, #48ffaf 7.03%, #82d6e8 100%);
    padding-top: map-get($spacers, 35);
    padding-left: map-get($spacers, 25);
    padding-right: map-get($spacers, 27);
    padding-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(lg) {
      padding-top: map-get($spacers, 45);
      padding-bottom: map-get($spacers, 405);
      padding-left: map-get($spacers, 31);
      padding-right: map-get($spacers, 25);
    }
  }

  &__ride-club {
    display: none;
    line-height: 1;
    @include h11-light();
    margin-bottom: map-get($spacers, 23);

    svg {
      vertical-align: baseline;
      margin-right: map-get($spacers, 15);
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__title {
    @include h8-light();
    max-width: 315px;
  }

  &__image {
    display: none;
    background-image: url("/assets/investments/investment-club-bg.png");
    height: 241px;
    background-position: center center;
    background-size: cover;
    position: relative;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__arrow {
    margin-top: map-get($spacers, 27);
    text-align: right;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-top: 0;
    }
  }

  &__overlay {
    transition: all $ride-transition-time;
    background: linear-gradient(0deg, rgba(48, 52, 54, 0.5), rgba(48, 52, 54, 0.5));
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
  }
}
