.country-select {
  &__label {
    color: $ride-palette-black;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__select-input__control {
    font-size: map-get($v2-spacers, 16) !important;
    font-weight: $font-weight-bold !important;
    height: map-get($v2-spacers, 48);
    padding: 0;
    border-color: $ride-input-border-color !important;
    border-radius: map-get($v2-spacers, 12) !important;
    margin-bottom: map-get($v2_spacers, 32);
  }
  &__select-input__value-container {
    height: map-get($v2-spacers, 48);
    padding: map-get($v2-spacers, 12) map-get($v2-spacers, 16) !important;

    & > .country-select__select-input__placeholder {
      font-weight: $font-weight-medium;
    }

    &
      > div:not(.country-select__select-input__placeholder):not(.country-select__select-input__single-value) {
      margin-top: -(map-get($v2-spacers, 12) / 4);
    }
  }
}
