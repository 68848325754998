.fiscal-year {
  &-date-picker {
    width: 72px;
  }

  &-datepicker-container {
    .react-datepicker__day-names {
      display: none;
    }

    .react-datepicker {
      border: none;

      &__header {
        border-bottom: none;
        background-color: $ride-palette-grey-300;
      }

      &__day {
        font-size: 13px;

        &--today {
          font-weight: normal;
          color: $black;
          background-color: $white;
        }

        &--selected {
          background-color: $success;
          color: $black;
          font-weight: 500;
        }
      }
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
      border-bottom-color: transparent;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
      border-bottom-color: $ride-palette-grey-300;
    }

    .client-select__container {
      min-width: 7rem;
    }
  }
}
