$space: 1rem;
$space-xs: 0.25rem;
$space-s: 0.5rem;
$space-l: 2rem;
$space-xl: 4rem;

$space-48: 3rem;
$space-80: 5rem;
$space-96: 6rem;
$space-800: 50rem;
$space-m-l: 1.5rem;
