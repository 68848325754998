.ltb-overview-card {
  border: 1px solid $ride-palette-grey-500;
  border-radius: map-get($v2-spacers, 12);
  background-color: $ride-palette-white;

  &__header {
    height: map-get($v2-spacers, 72);
    padding: map-get($v2-spacers, 18) map-get($v2-spacers, 26);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: map-get($v2-spacers, 20);
    line-height: map-get($v2-spacers, 28);
    gap: map-get($v2-spacers, 16);
  }

  &__title {
    width: 100%;
    display: flex;

    &--editable {
      justify-content: space-between;
    }
  }

  &__content {
    border-top: 1px solid $ride-palette-grey-500;
    padding: map-get($v2-spacers, 28);
  }

  &__number-icon,
  &__check-icon {
    border-radius: 50%;
    width: map-get($v2-spacers, 32);
    height: map-get($v2-spacers, 32);
  }

  &__number-icon {
    background-color: $ride-palette-grey-100;
    font-weight: 500;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__check-icon {
    background: #e7faf2 url("/assets/sharedComponents/check-green-icon.svg") no-repeat center !important;
  }

  &--LOCKED {
    .ltb-overview-card__header {
      color: #888d9a;
    }
  }
}
