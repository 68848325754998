@keyframes slide-from-above {
  from {
    opacity: 0;
    transform: translateY(-25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tax-advisor-client-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  transition: all 0.2s ease-in-out;
  animation: 0.2s ease-in-out 1 both slide-from-above;
  animation-delay: var(--slide-delay);

  &__wrapper {
    align-self: stretch;
  }

  &__left-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }

  &__client-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &__status-badges {
    height: fit-content;
    & > span {
      border-radius: 5rem;
      font-size: 0.7rem;
      margin: 0 0.5rem;
    }
  }

  &__client-number {
    color: $neutral-500;
  }

  &__arrow {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    @extend .elevation-200;

    .tax-advisor-client-card__arrow {
      transform: translateX(0.375rem);
    }
  }
}
