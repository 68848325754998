.fx-flat-entity-details-citizenship-information {
  padding-left: map-get($v2-spacers, 48);
  padding-right: map-get($v2-spacers, 48);
  padding-bottom: map-get($v2-spacers, 40);

  display: flex;
  flex-direction: column;

  &__fields {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 30);
  }

  &__date-picker {
    margin: 0;
  }
}
