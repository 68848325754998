.upload-file {
  padding-top: map-get($v2-spacers, 36);
  padding-bottom: map-get($v2-spacers, 40);
  padding-left: map-get($v2-spacers, 48);
  padding-right: map-get($v2-spacers, 48);

  &__section-title {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__requirements {
    color: $ride-palette-grey-600;
    font-weight: $font-weight-normal;
    font-size: map-get($v2-spacers, 16);
    margin-bottom: map-get($v2-spacers, 24);
  }
}
