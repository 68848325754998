.password-policy {
  @include h10-light();
  margin-top: map-get($spacers, 14);
  margin-bottom: map-get($spacers, 35);

  &__title {
    color: $ride-palette-black;
  }

  &__requirement {
    color: $ride-palette-grey-600;
  }
}

.invalid-feedback {
  @include h9-light();
  margin-top: map-get($spacers, 0);
}
