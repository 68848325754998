.company-header-section {
  font-family: $font-family-figtree;
  background-color: $neutral-0;
  padding-top: 1.5rem;
  padding-bottom: 1rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 1.5rem;
  }

  &__container {
    padding-left: 0;
    padding-right: 0;
  }

  &__company-name {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &__title {
      color: $text-icon-primary-black;
      font-style: normal;
      font-weight: $ds-font-weight-heavy;
      line-height: map-get($v2-spacers, 40);
      margin-bottom: 0.5rem;
      text-align: center;

      @include media-breakpoint-up(md) {
        margin-top: 1rem;
      }
    }
  }

  &__client-tags {
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__admin-service {
    margin-top: 1.25rem;
    display: flex;

    @include media-breakpoint-up(md) {
      justify-content: center;
      margin-bottom: 0.375rem;
    }
  }

  .gmbh-tile__company-icon {
    margin: 0;
    display: inline-block;
  }

  h1 {
    font-size: 3.125rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
      overflow-wrap: anywhere;
    }
  }

  &__company-info {
    display: grid;
  }

  &__icon {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      justify-content: flex-end;
    }
  }

  &__company-name {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }

  &__company-status {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &--spacer {
      margin-bottom: 1.875rem;

      @include desktop {
        margin-bottom: 4.5rem;
      }
    }
  }
}

.company-header-section-obsolete {
  background-color: $ride-palette-black;
  padding-top: 1.5rem;
  padding-bottom: 1rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 1.5rem;
  }

  &__container {
    padding-left: 0;
    padding-right: 0;
  }

  &__company-name {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &__title {
      color: $white;
      margin-top: 0;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  &__client-tags {
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__admin-service {
    margin-top: 1.25rem;
    display: flex;

    @include media-breakpoint-up(md) {
      justify-content: center;
      margin-bottom: 0.375rem;
    }
  }

  .gmbh-tile__company-icon {
    margin: 0;
    display: inline-block;
  }

  h1 {
    font-size: 3.125rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
      overflow-wrap: anywhere;
    }
  }

  &__company-info {
    display: grid;
  }

  &__icon {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      justify-content: flex-end;
    }
  }

  &__company-name {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }

  &__company-status {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;

    @include media-breakpoint-down(sm) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &--spacer {
      margin-bottom: 1.875rem;

      @include desktop {
        margin-bottom: 4.5rem;
      }
    }
  }
}
