.short-order-form-layout {
  display: grid;
  grid-template:
    "header header" auto
    "sidebar main" 1fr
    / 25rem 1fr;
  height: vh-fix(100);
  width: 100vw;

  .guest-header {
    grid-area: header;
  }

  &__info-bar {
    max-height: 70px;
    margin: 15px;
  }

  &__sidebar {
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem 2.5rem 2rem;
  }

  &__content {
    grid-area: main;
    background-color: $neutral-50;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
    display: grid;
    grid-template:
      "step" 1fr
      "footer" auto / 1fr;
  }

  &__step {
    grid-area: step;
    padding: 1.5rem 1rem 0;
  }

  .ride-footer {
    grid-area: footer;
    border: none;
    padding: 2rem 3.5rem;
  }

  .step-progress-bar {
    align-self: stretch;
    padding-left: 0.5rem;
    margin-bottom: auto;
  }

  .short-order-form-partners {
    margin-top: map-get($v2-spacers, 32);
    align-self: center;
  }

  @include media-breakpoint-down(md) {
    grid-template:
      "header" auto
      "sidebar" auto
      "main" 1fr
      / 100vw;

    &__sidebar {
      padding: 1.5rem;
      border-bottom: 1px solid $neutral-200;
    }

    &__step {
      padding: 0 1rem 0;
      min-width: 0;
    }

    .step-progress-bar {
      align-self: unset;
      padding: 0;
      max-width: 100%;
    }

    .book-a-call-card {
      display: none;
    }

    .short-order-form-partners {
      display: none;
    }
  }
}
