.profile-password-edit {
  max-width: 348px;
  margin: auto;

  &__forgot-password {
    @include h9-light();
    color: $ride-palette-grey-600;
    margin-top: 24px;
    margin-bottom: 45px;
  }
}
