.key-value-tag {
  &__key {
    color: $ride-extra-light-grey;
    font-size: 0.875rem;
    font-weight: 500;
  }

  &__value {
    font-weight: 900;
    font-size: 1rem;
    color: $ride-palette-black;
  }
}
