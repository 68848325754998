$gmbh-tile-gutter-base: $grid-gutter-width / 2;
$gmbh-tile-gutter-small: 0.625rem;
$gmbh-tile-border-radius: 0.75rem;

.gmbh-tile {
  border-radius: $gmbh-tile-border-radius;
  box-shadow: 0 8px 40px rgba(53, 38, 69, 0.1);
  transition: all $ride-transition-time;

  &__footer {
    @include h10-light();
    color: $ride-palette-black;
    line-height: 1.1;
    background-color: $ride-palette-grey-100;
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
    padding: map-get($spacers, 31);
    cursor: default;

    @include media-breakpoint-up(lg) {
      padding-left: map-get($spacers, 42);
      padding-bottom: map-get($spacers, 23);
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgba(53, 38, 69, 0.1);
  }

  &__company-icon {
    background-repeat: no-repeat;
    background-position: center;
    width: 2.8125rem;
    height: 2.8125rem;
    border-radius: 50%;
    margin-right: $gmbh-tile-gutter-small;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      width: 5rem;
      height: 5rem;
      margin-right: $gmbh-tile-gutter-base;
    }

    &--generic {
      background-image: url("/assets/client/star.svg");
      background-size: 60%;
      background-color: #e5899a;
    }

    &--stocks {
      background-image: url("/assets/client/trending-up.svg");
      background-size: 50%;
      background-color: #c691d3;
    }

    &--holding {
      background-image: url("/assets/client/holding.svg");
      background-size: 45%;
      background-position-x: 52%;
      background-position-y: 47%;
      background-color: $ride-soft-orange;
    }

    &--real-estate {
      background-image: url("/assets/client/home.svg");
      background-size: 60%;
      background-color: #cbb594;
    }
  }

  h2 {
    background-image: none;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.167;

    @include media-breakpoint-up(md) {
      margin-top: 3px;
      font-size: 1.5rem;
    }
  }

  &__cta {
    margin-right: 0.25rem;
    margin-top: 0.25rem;
  }

  &__extra-section {
    background-color: $ride-palette-tilt;
    font-size: 0.75rem;
    font-weight: 400;
    color: $ride-palette-black;
    border-bottom-left-radius: $gmbh-tile-border-radius;
    border-bottom-right-radius: $gmbh-tile-border-radius;

    padding: 1.125rem 1.5rem;
    margin-left: -$gmbh-tile-gutter-base;
    margin-right: -$gmbh-tile-gutter-small;
    margin-bottom: -$gmbh-tile-gutter-base;

    @include media-breakpoint-up(md) {
      font-size: 0.875rem;
      padding: 1.625rem 2.3125rem 1.625rem 1.5rem;
      margin-left: -$gmbh-tile-gutter-small * 2;
      margin-right: -$gmbh-tile-gutter-small * 2;
      margin-bottom: -$gmbh-tile-gutter-base * 2;
    }
  }

  .gmbh-tile__link {
    color: $ride-palette-black;
    text-decoration: underline;

    &:hover {
      color: $ride-palette-black;
      font-weight: 800;
    }
  }

  &__mute {
    color: $ride-palette-grey-600;
  }

  .gmbh-tile__admin-service-cta {
    text-decoration: none;
    color: $ride-palette-black;

    &:hover {
      color: $ride-palette-black !important;
    }
  }

  &__company-status {
    color: $ride-palette-red;
    font-size: 0.75rem;
    font-weight: 500;
  }

  // FIX-ME: remove me after the new actionable tasks sync have been implemented (#961)
  &__tasks {
    font-size: 0.75rem;
    font-weight: 500;
    color: $ride-palette-grey-800;

    @include media-breakpoint-up(md) {
      margin-left: 5rem;
    }

    ul {
      list-style: disc;
      color: $ride-palette-grey-600;
      margin-bottom: 0;
      padding-left: 1rem;
    }
  }

  &__admin-service-link {
    font-size: 0.8rem;

    @include media-breakpoint-down(md) {
      margin-left: -1.3rem;
    }

    &--title {
      font-weight: 800;
      color: $dark;
    }

    &--continue {
      color: $dark;
      text-decoration: underline;
      font-weight: 400;

      a {
        text-decoration: none;
        color: $dark;
      }
    }

    &--cancel {
      color: $ride-palette-grey-600;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
