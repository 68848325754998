.club-deal-logo {
  padding-bottom: map-get($spacers, 308);
  margin-left: map-get($spacers, 309);
  display: flex;

  &__icon {
    align-self: baseline;
  }

  &__description {
    color: $ride-palette-extra-dark-grey;
    line-height: 31px;
    margin-left: map-get($spacers, 307);
    font-size: 0.7rem;
  }
}
