.year-item {
  vertical-align: top;

  &__year {
    @include h8-light();
    color: $ride-extra-light-grey;
  }

  &__value {
    @include h8-light();
    color: $ride-palette-black;
  }

  &__after {
    word-break: keep-all;
    white-space: nowrap;
  }
}
