.tab-group-obsolete {
  display: grid;
  grid-auto-flow: column;
  background-color: rgba($white, 0.15);
  box-shadow: inset 0 0 4px 1px $dark;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: unset;
    min-width: 42rem;
    grid-auto-columns: 1fr;
  }

  &__item {
    text-align: center;
    transition: color 0.2s ease-in-out;
    font-size: map-get($v2-spacers, 12);
    font-weight: 400;
    color: $success;
    padding: map-get($v2-spacers, 12);
    border-radius: map-get($v2-spacers, 48);

    @include media-breakpoint-up(md) {
      font-size: map-get($v2-spacers, 20);
      padding: map-get($v2-spacers, 12);
    }

    &--active {
      color: $ride-palette-black;
      background-color: white !important;
    }

    &:hover {
      background-color: rgba($white, 0.15);
    }
  }
}

.tab-group {
  font-family: $font-family-figtree;
  display: flex;
  flex-direction: row;
  gap: map-get($v2-spacers, 16);

  @include media-breakpoint-down(md) {
    overflow-x: auto;
  }

  &__item {
    color: $text-icon-disabled-black;
    text-align: center;
    line-height: map-get($v2-spacers, 24);
    font-size: map-get($v2-spacers, 16);
    font-weight: $ds-font-weight-heavy;
    border-radius: map-get($v2-spacers, 8);
    padding: map-get($v2-spacers, 10) map-get($v2-spacers, 16);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      min-width: fit-content;
    }

    &--active {
      color: $text-icon-primary-white;
      background-color: $neutral-900;
    }
  }
}
