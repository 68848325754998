.ride-dropdown {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  border-radius: 0.5rem;
  background-color: $neutral-0;
  max-height: 26rem;
  overflow-y: auto;
}
