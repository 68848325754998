.ride-icon {
  border-radius: $v2-border-radius-circle;
  width: map-get($v2-spacers, 48);
  height: map-get($v2-spacers, 48);
  background: $ride-palette-grey-000 no-repeat center;
  background-size: map-get($v2-spacers, 20);
  position: relative;

  // In the begging of the file to not have the background-image "above" the colour
  &--missing_information {
    background: $ride-warning no-repeat center;
  }

  &--SMALL {
    width: map-get($v2-spacers, 32);
    height: map-get($v2-spacers, 32);
    background-size: map-get($v2-spacers, 16);
  }

  &--structure {
    background-image: url("/assets/sharedComponents/structure-icon.svg");
  }

  &--bulb {
    background-image: url("/assets/sharedComponents/bulb-icon.svg");
  }

  &--pen {
    background-image: url("/assets/sharedComponents/pen-icon.svg");
  }

  &--profile {
    background-image: url("/assets/sharedComponents/profile-icon.svg");
  }

  &--grid {
    background-image: url("/assets/sharedComponents/grid-icon.svg");
  }

  &--id-card {
    background-image: url("/assets/sharedComponents/id-card-icon.svg");
  }

  &--location {
    background-image: url("/assets/sharedComponents/location-icon.svg");
  }

  &--rocket {
    background-image: url("/assets/sharedComponents/rocket-icon.svg");
  }

  &--calendar {
    background-image: url("/assets/sharedComponents/calendar.svg");
  }

  &--visible {
    background-image: url("/assets/sharedComponents/visible.svg");
  }

  &--visible-big {
    background-image: url("/assets/sharedComponents/visible-big.svg");
  }

  &--delete {
    background-image: url("/assets/sharedComponents/delete.svg");
  }

  &--delete-max {
    background-image: url("/assets/sharedComponents/delete-max.svg");
  }

  &--edit-max {
    background-image: url("/assets/sharedComponents/edit-max.svg");
  }

  &--document-upload {
    background-image: url("/assets/sharedComponents/document-upload.svg");
  }

  &--document-lines {
    background-image: url("/assets/sharedComponents/document-lines.svg");
  }

  &--close-max {
    background-image: url("/assets/sharedComponents/close-max.svg");
  }

  &--error {
    background-image: url("/assets/sharedComponents/error.svg");
  }

  &--brush {
    background-image: url("/assets/sharedComponents/brush.svg");
  }

  &--business {
    background-image: url("/assets/sharedComponents/business.svg");
  }

  &--compass {
    background-image: url("/assets/sharedComponents/compass.svg");
  }

  &--warning {
    background-image: url("/assets/sharedComponents/warning.svg");
  }

  &--info-blue {
    background-image: url("/assets/sharedComponents/info-blue.svg");
  }

  &--success-green {
    background-image: url("/assets/sharedComponents/success-green.svg");
  }

  &--error-red {
    background-image: url("/assets/sharedComponents/error-red.svg");
  }

  &--warning-yellow {
    background-image: url("/assets/sharedComponents/warning-yellow.svg");
  }

  &--arrow-right {
    background-image: url("/assets/client/arrow-right.svg");
  }

  &--bank {
    background-image: url("/assets/client/bank.svg");
  }

  &--check-mark {
    background-image: url("/assets/sharedComponents/check-icon-centered.svg");
  }

  &--completed {
    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: $ride-palette-green no-repeat center;
      border-radius: $v2-border-radius-circle;
      background-image: url("/assets/sharedComponents/check-white-icon.svg");
      background-size: map-get($v2-spacers, 18);
      width: map-get($v2-spacers, 18);
      height: map-get($v2-spacers, 18);
      right: 0;
      bottom: 0;
    }

    &--SMALL {
      &::after {
        width: map-get($v2-spacers, 12);
        height: map-get($v2-spacers, 12);
        background-size: map-get($v2-spacers, 12);
      }
    }
  }

  &--locked {
    &::after {
      content: " ";
      display: block;
      position: absolute;
      box-sizing: content-box;
      background: $ride-palette-grey-300 no-repeat center;
      border-radius: $v2-border-radius-circle;
      background-image: url("/assets/sharedComponents/lock-mini.svg");
      background-size: 0.8125rem;
      // TODO: We shouldn't use white we should use a polygon to respect grandparents background
      border: map-get($v2-spacers, 2) solid $ride-palette-white;
      width: map-get($v2-spacers, 18);
      height: map-get($v2-spacers, 18);
      right: -0.3125rem;
      bottom: -0.3125rem;
    }

    &--SMALL {
      &::after {
        width: map-get($v2-spacers, 12);
        height: map-get($v2-spacers, 12);
        background-size: map-get($v2-spacers, 12);
      }
    }
  }

  &--clickable-control {
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      background-color: $ride-palette-grey-500;
    }
  }
}
