.selected-external-company-card {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $neutral-200;
  background: $neutral-0;

  &__company-identification {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
  }

  &__company-name {
    flex: 1 0 0;
  }

  &__close-button {
    display: flex;
    align-self: center;
    justify-content: center;
    cursor: pointer;
  }

  &__registration {
    color: $text-icon-secondary-black;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  &__register-court-label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__register-court {
    display: flex;
    padding: 0 1.25rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }
}
