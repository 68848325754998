.action-page {
  padding-top: $spacer;
  background-color: $white;
  flex: 1;

  &--title {
    @include h3();
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: map-get($map: $spacers, $key: 7);

    &--title {
      @include h2();
      text-align: center;
      margin-bottom: map-get($map: $spacers, $key: 48);
    }

    &--content {
      background-color: $ride-palette-grey-100;
      border-radius: $border-radius-md;
      padding: $spacer;
      max-width: 904px;
      margin: auto;
    }
  }
}
