.contract-signature-card {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  &__annex-2 {
    margin-left: map-get($v2-spacers, 40);
  }

  &__modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &-contact {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    &--tabbed {
      padding-left: 0.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    border-radius: 0.75rem;
    background: $neutral-50;
  }

  &__section + &__section {
    border-top: 1px solid $neutral-200;
    padding-top: 2rem;
  }

  &__section-title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__button-center {
    justify-content: center;
  }

  .alert-bar--info {
    background: $informational-100;
  }
}

.ride-checkbox-v2--light {
  input[type="checkbox"] {
    &:checked {
      box-sizing: border-box;
      width: map-get($v2-spacers, 24);
      height: map-get($v2-spacers, 24);
      border: 0;
      background: url("/assets/uiLibrary/icons/checkbox-checked.svg") no-repeat center;
      background-size: 100%;

      &::after {
        width: map-get($v2-spacers, 24);
        height: map-get($v2-spacers, 24);
        background-image: none;
      }
    }

    &:disabled:checked {
      background: url("/assets/uiLibrary/icons/checkbox-checked-gray.svg") no-repeat center;
    }
  }
}
