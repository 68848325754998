.product-learn-more-modal {
  display: contents;

  &__body {
    @include media-breakpoint-up(sm) {
      padding: 2.5rem;
    }
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__description {
    margin-bottom: 2rem;
  }

  &__feature-list {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 1.5rem;
  }

  &__price_and_partners {
    display: flex;
    justify-content: space-between;
    gap: map-get($v2-spacers, 10);
    padding-right: map-get($v2-spacers, 32);

    @include media-breakpoint-down(xs) {
      padding-right: map-get($v2-spacers, 8);
    }
  }

  &__price-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: map-get($v2-spacers, 4);
    height: 4rem;
    width: 18rem;
  }

  &__price-label {
    color: $text-icon-disabled-black;
  }

  &__price {
    color: $primary-success-600;
  }
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  &__icon {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: var(--primary-success-100, #d0f8d9);
  }
}

.low-tax-broker-modal-partners {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 8);

  color: $text-icon-disabled-black;
  text-align: left;

  &__logos {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: map-get($v2-spacers, 24);

    @include media-breakpoint-down(xs) {
      gap: map-get($v2-spacers, 16);
    }
  }

  &__partner-logo {
    opacity: 0.5;
  }
}
