.user-broker-survey {
  &__radios {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0 1.5rem;
    }
  }

  &__radio {
    padding-bottom: 1.5rem;
  }
}

.brokers-list {
  background: $ride-palette-grey-100;
  padding: 1.25rem;
  border-radius: 5px;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    width: calc(100% - 31px);
    margin-left: 31px;
  }

  &-description {
    font-size: 0.875rem;
    color: $ride-palette-black;
    margin-bottom: 13px;
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
      flex-direction: row;
    }
  }

  &-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &-link {
    padding: 5px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &-different-provider {
    resize: none;
    width: 100%;
    box-shadow: none !important;
    padding: 10px;
  }

  &-dropdown {
    max-width: 320px;

    &-container {
      width: calc(100% - 48px);
      font-size: 14px;
      color: $ride-palette-black;
      margin: 0 0 30px 30px;
    }

    &-subtitle {
      font-weight: bold;
      margin-bottom: 4px;
      text-align: left;
    }

    &-text-area {
      margin-top: 0.25rem;
      border-radius: 5px;
      border: solid 1px $ride-palette-grey-300;

      &-subtitle {
        margin-top: 1rem;
        font-weight: bold;
        font-size: 0.875rem;
        color: $ride-palette-black;
        text-align: left;
      }
    }
  }
}
