.define-shares {
  &__context-label {
    font-size: map-get($v2-spacers, 20);
    line-height: map-get($v2-spacers, 20);
    font-weight: $font-weight-medium;
    color: $ride-palette-black;
    margin-bottom: map-get($v2-spacers, 36);
  }

  &__current-share-count-span {
    padding-right: map-get($v2-spacers, 14);
    display: block;
    text-align: right;

    &__default {
      color: $ride-palette-grey-600;
    }

    &__error {
      color: $ride-palette-error;
    }

    &__success {
      color: $success;
    }
  }
}
