.investment-card-details {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;

    & > div {
      width: 50%;
      height: auto;
    }
  }

  & + & {
    margin-top: map-get($spacers, 405);

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 475);
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-top-right-radius: $border-radius-md;
    border-top-left-radius: $border-radius-md;
    height: 132px;
    background-color: $ride-palette-grey-100;

    @include media-breakpoint-up(lg) {
      border-top-left-radius: 0;
      border-top-right-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;
      height: 305px;
    }

    &--default {
      background-size: 120px;

      @include media-breakpoint-up(lg) {
        background-size: 240px;
      }
    }

    &--RealEstate {
      background-image: url("/assets/investments/real-estate-large-placeholder.svg");
    }

    &--Company {
      background-image: url("/assets/investments/holding-placeholder.svg");
    }

    &--BrokerageAccountSet {
      background-image: url("/assets/investments/header-brokerageAccountSet-placeholder.svg");
    }
  }

  .chain-ownership__label-ownership,
  .chain-ownership__label-private {
    margin-top: map-get($spacers, 14);
  }

  .chain-ownership__label-ownership,
  .chain-ownership__label-private,
  .chain-ownership__label-in-process {
    @include h8-light();
    margin-bottom: map-get($spacers, 37);

    @include media-breakpoint-up(lg) {
      margin-bottom: map-get($spacers, 25);
    }
  }

  &__content {
    min-height: 310px;
    padding: map-get($spacers, 35);
    padding-bottom: map-get($spacers, 45);

    @include media-breakpoint-up(lg) {
      padding: map-get($spacers, 45);
    }
  }

  &__section {
    margin: map-get($spacers, 23) 0;
  }

  &__section-title {
    @include h8-light();
    color: $ride-palette-grey-600;
    line-height: 1;
    margin-bottom: map-get($spacers, 309);
  }

  &__section-value {
    @include h8-light();
    color: $ride-palette-black;
  }

  &__my-investment-value {
    display: inline-block;
    @include h6();
    color: $ride-palette-black;
  }

  &__my-investment-date {
    display: inline-block;
    @include h8-light();
    color: $ride-palette-grey-600;
    padding-left: map-get($spacers, 2);
  }
}
