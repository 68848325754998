.pay-in-modal {
  display: flex;
  flex-direction: column;

  &__title {
    margin-top: map-get($v2-spacers, 44);
    margin-bottom: map-get($v2-spacers, 30);
    font-weight: $font-weight-bold;
  }

  &__copyable-entry {
    border-bottom: 1px solid $ride-palette-grey-300;
    padding-top: map-get($v2-spacers, 24);
    padding-bottom: map-get($v2-spacers, 24);
  }

  &__time-information {
    margin-top: map-get($v2-spacers, 16);
  }
}
