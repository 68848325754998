.company-tabbed-navigation {
  background-color: rgba($white, 0.15);
  width: 100%;
  box-shadow: inset 0 0 4px 1px $dark;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50rem;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    width: 774px;
  }

  &__option {
    padding: 0.7rem;
    text-align: center;
    position: relative;
    z-index: 10;
    transition: color 0.2s ease-in-out;
    font-size: 0.7rem;
    font-weight: 400;
    border-radius: 50rem;
    color: $success;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }

    &:hover {
      background-color: rgba($white, 0.15);
    }

    &--active {
      color: $black;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    background-color: $white;
    top: 0;
    left: 0;
    transition: left 0.2s ease-in-out;
    border-radius: 50rem;
  }

  &--three-options {
    .company-tabbed-navigation__option {
      width: 30%;

      @include media-breakpoint-up(md) {
        width: 33%;
      }

      &:nth-child(3) {
        width: 40%;

        @include media-breakpoint-up(md) {
          width: 34%;
        }
      }
    }

    .company-tabbed-navigation__background {
      width: 30%;

      @include media-breakpoint-up(md) {
        width: 33%;
      }

      &--one {
        left: 0;

        @include media-breakpoint-up(md) {
          left: 0;
        }
      }

      &--two {
        left: 30%;

        @include media-breakpoint-up(md) {
          left: 33%;
        }
      }

      &--three {
        left: 60%;
        width: 40%;

        @include media-breakpoint-up(md) {
          left: 66%;
          width: 34%;
        }
      }
    }
  }

  &--two-options {
    @include media-breakpoint-up(md) {
      width: 530px;
    }

    .company-tabbed-navigation__option {
      width: 50%;
    }

    .company-tabbed-navigation__background {
      width: 50%;

      &--one {
        left: 0;
      }

      &--three {
        left: 50%;
      }
    }
  }

  &--admin-service {
    background-color: rgba($white, 0.15);
  }
}
