.ride-password {
  position: relative;

  .ride-input__input {
    border-right: 0 !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__toggle {
    right: map-get($spacers, 1);
    border: 1px solid $ride-palette-grey-300;
    border-top-right-radius: $border-radius-xs;
    border-bottom-right-radius: $border-radius-xs;
    border-left: 0;
    background-color: transparent;
    color: $ride-extra-light-grey;

    &:focus {
      outline: 1px dashed $ride-extra-light-grey;
    }
  }

  &__toggle:active,
  &__toggle:hover {
    color: $ride-palette-black;
  }

  // styles for matching the toggle state with the input state
  .ride-input--active &__toggle,
  input:focus + &__toggle {
    border-color: $ride-palette-black;
  }

  .ride-input--active &__toggle,
  .ride-input--active.ride-input--ERROR &__toggle,
  .ride-input--active.ride-input--ACCEPTED &__toggle,
  input:focus + &__toggle {
    border-color: $ride-palette-black;
  }

  .ride-input--ACCEPTED &__toggle {
    border-color: $ride-palette-green;
  }

  .ride-input--ERROR &__toggle {
    border-color: $ride-palette-error;
  }

  input[disabled] + &__toggle {
    border-color: $ride-palette-grey-300;
    background-color: $ride-palette-grey-300;
  }
}
