%ride-cta-hover {
  background-color: $ride-palette-black;
  background-image: url("/assets/client/arrow-right-green.svg");
}

.ride-cta {
  cursor: pointer;
  background-image: url("/assets/client/arrow-right.svg");
  background-size: 46%;
  background-repeat: no-repeat;
  background-position: 49% 50%;
  background-color: $success;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  transition: color $ride-transition-time, background-color $ride-transition-time;
  float: right;

  &:hover {
    @extend %ride-cta-hover;
  }

  &--secondary {
    background-color: transparent;
    background-size: 48%;
    border: 1px solid $ride-palette-black;

    @include media-breakpoint-up(md) {
      width: 1.625rem;
      height: 1.625rem;
    }
  }

  &--primary {
    background-color: $ride-palette-black;
    background-size: 48%;
    border: none;
    background-image: url("/assets/client/arrow-right-green.svg");

    &:hover {
      background-color: transparent;
      background-image: url("/assets/client/arrow-right.svg");
      border: 1px solid $ride-palette-black;
      background-position: 49% 49%;
    }
  }
}

.ride-cta-wrapper {
  cursor: pointer;

  &:hover {
    .ride-cta {
      @extend %ride-cta-hover;
    }
  }
}

a.ride-cta-wrapper {
  text-decoration: none;
  color: $ride-palette-black;
}
