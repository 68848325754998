.ride-modal-deprecated {
  .modal-dialog,
  .modal-content {
    border-radius: $border-radius-md;
  }

  &--rounded {
    margin: 0.5rem auto;
    font-size: 1rem;
    font-weight: normal;

    .modal-dialog,
    .modal-content {
      border-radius: 0.75rem !important;

      .ride-modal-deprecated__title {
        @include h6-light();
      }

      .ride-modal-deprecated__title,
      .ride-modal-deprecated__body {
        margin: 0;
        line-height: 1.25rem;
        text-align: left !important;
      }

      .ride-modal-deprecated__header {
        margin-top: 1rem;
      }

      .ride-modal-deprecated__title--with-close {
        padding: 0;
      }

      ul {
        padding: 1rem;
        list-style-type: "- ";
      }

      .ride-modal-deprecated__modal-buttons {
        display: flex;
        align-items: center;
        align-content: space-between;
        margin: 0 auto;
        justify-content: center;
        gap: 0.5rem;

        button {
          padding: 9px 11.5px;
        }

        .ride-modal-deprecated__button + .ride-modal-deprecated__button {
          margin-left: 0.24rem;
        }
      }
    }
  }

  &__dialog {
    &--large {
      max-width: 702px !important;
    }
  }

  &__title {
    @include h4();

    flex: 1;
    margin-top: map-get($map: $spacers, $key: 4);
    text-align: center;
    color: $ride-palette-black;

    &--with-close {
      padding-left: calc(2rem + 23px);
    }

    @include media-breakpoint-up(md) {
      @include h6();
    }
  }

  &__body {
    text-align: center;
  }

  &__button {
    display: inline-block;
  }

  &__button + &__button {
    margin-left: map-get($map: $spacers, $key: 4);
  }

  &__modal-buttons {
    margin-top: map-get($map: $spacers, $key: 48);
    margin-bottom: map-get($map: $spacers, $key: 6);

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($map: $spacers, $key: 48);
    }
  }

  &__header-icon {
    margin-right: 0.625rem;

    svg {
      top: -0.313rem;
      width: 1.594rem;
      height: 1.594rem;
      vertical-align: initial !important;
    }
  }
}
