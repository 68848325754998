.ltb-review-managing-directors {
  &__list {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 16);
  }

  &__entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: map-get($v2-spacers, 20) map-get($v2-spacers, 24);
    border-radius: 0.75rem;
    background-color: $ride-palette-grey-100;
    color: $ride-palette-black;
    font-size: 1rem;
    font-weight: bold;
  }
}
