.confirm-action-modal {
  width: 26.25rem;
  border-radius: map-get($v2-spacers, 24);
  background-color: white;
  padding: map-get($v2-spacers, 32);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1001;

  &--centered {
    align-items: center;

    .confirm-action-modal__message {
      text-align: center;
    }
  }

  &__background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &__title {
    &--with-illustration {
      text-align: center;
    }
    font-size: map-get($v2-spacers, 28);
    font-weight: $font-weight-medium;
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-black;
    margin-bottom: map-get($v2-spacers, 20);
    margin-top: map-get($v2-spacers, 20);
  }

  &__message {
    font-size: map-get($v2-spacers, 16);
    font-weight: $font-weight-normal;
    color: $ride-palette-black;
    margin-bottom: map-get($v2-spacers, 20);

    a:link {
      text-decoration: underline;
      color: $ride-palette-black;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;

    & > * + * {
      margin-left: map-get($v2-spacers, 12);
    }
  }

  &__illustration {
    &--successful {
      background: url("/assets/uiLibrary/icons/successful-illustration.svg") no-repeat center;
      background-size: 200px;
      width: 200px;
      height: 200px;
    }
    &--success {
      background: url("/assets/uiLibrary/icons/success-illustration.svg") no-repeat center;
      background-size: 98px;
      width: 98px;
      height: 98px;
    }
  }
}

@media screen and (orientation: portrait) {
  .confirm-action-modal {
    width: 20.4375rem;

    &__button-container {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: stretch;

      & > * + * {
        margin-left: 0;
        margin-bottom: map-get($v2-spacers, 12);
      }
    }
  }
}
