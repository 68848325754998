.ride-checkbox-v2 {
  &--heavy {
    background: $ride-palette-grey-100;
    border-radius: map-get($v2-spacers, 12);

    margin: 0;
    padding: map-get($v2-spacers, 24);

    display: flex;
    align-items: center;

    cursor: pointer;
    transition: 0.3s;

    .label {
      font-weight: $font-weight-medium;
      font-size: map-get($v2-spacers, 16);
      line-height: map-get($v2-spacers, 22);
      color: $ride-palette-black;
    }

    input[type="checkbox"] {
      appearance: none;
      margin-right: map-get($v2-spacers, 18);

      background-color: $ride-palette-grey-300;
      min-width: map-get($v2-spacers, 28);
      min-height: map-get($v2-spacers, 28);

      border-radius: map-get($v2-spacers, 6);
      cursor: pointer;

      &:checked {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: " ";
          display: block;
          width: map-get($v2-spacers, 22);
          height: map-get($v2-spacers, 22);
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("/assets/sharedComponents/check-icon-centered.svg");
        }
      }
    }

    &:active {
      transform: scale(0.96);
    }

    &:hover {
      -webkit-box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
      -moz-box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
      box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
    }
  }

  &--light {
    margin: 0;
    padding: 0;

    display: flex;
    align-items: flex-start;

    cursor: pointer;

    .label {
      color: $ride-palette-black;
      font-weight: $font-weight-bold;
      font-size: map-get($v2-spacers, 16);
      line-height: map-get($v2-spacers, 24);
    }

    input[type="checkbox"] {
      background-color: $neutral-0;
      appearance: none;
      cursor: pointer;
      min-width: map-get($v2-spacers, 24);
      min-height: map-get($v2-spacers, 24);

      border: 1px solid $text-icon-primary-black;
      border-radius: map-get($v2-spacers, 2);

      margin-right: map-get($v2-spacers, 16);

      &:checked {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $success;
        border-color: $neutral-700;

        &::after {
          content: " ";
          display: block;
          width: map-get($v2-spacers, 22);
          height: map-get($v2-spacers, 22);
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("/assets/sharedComponents/check-icon-centered.svg");
        }
      }
    }
  }

  &--readonly {
    cursor: not-allowed;

    &:active {
      transform: none;
    }

    .label {
      color: $ride-palette-grey-600;
    }

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    &.ride-checkbox-v2--light {
      input[type="checkbox"] {
        background-color: $text-icon-primary-white;
        border-color: $neutral-300;
      }
    }

    input[type="checkbox"] {
      cursor: not-allowed;

      &:checked {
        &::after {
          background-image: url("/assets/sharedComponents/check-icon-centered-disabled.svg");
        }
      }
    }
  }
}
