.annual-profit {
  &__title {
    @include h8();
    color: $ride-palette-black;
    padding-top: map-get($spacers, 15);
    padding-bottom: map-get($spacers, 21);
  }

  &__year {
    @include h8-light();
    color: $ride-extra-light-grey;
  }

  &__value {
    @include h8-light();
    color: $ride-palette-black;
  }

  .year-item {
    width: 50%;
    display: inline-block;
    margin-bottom: map-get($spacers, 2);

    @include media-breakpoint-up(md) {
      width: 240px;
    }
  }
}
