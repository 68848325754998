.bank-account-card {
  display: block;

  &:first-child {
    margin-top: map-get($spacers, 53);
  }

  & + & {
    margin-top: map-get($spacers, 405);

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 475);
    }
  }

  &__bank-icon {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &__iban-details {
    @include h8-light();

    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    padding-top: map-get($spacers, 35);
    padding-bottom: map-get($spacers, 35);

    &__edit {
      cursor: pointer;
      color: $ride-palette-grey-600;
      text-decoration: underline;
      text-align: right;
      padding-right: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 35);

      @include media-breakpoint-up(lg) {
        padding: 0;
        width: 100%;
      }
    }

    &--light {
      color: $ride-extra-light-grey;
    }

    &--dark {
      color: $ride-extra-dark-grey;
    }

    &--red {
      color: $ride-palette-bright-red;
    }
  }

  .ride-tile__details-slot {
    align-items: flex-end;
    padding: 0;
  }
}
