.loader {
  height: 24px;
  width: 24px;
  -webkit-animation: loader-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  margin: auto;
}
@-webkit-keyframes loader-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  margin: auto;
  width: 6px;
  height: 6px;
  background: $ride-palette-black;
  border-radius: 50%;
  -webkit-animation: loader-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(16px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(16px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  background: $ride-palette-black;
  border-radius: 50%;
  -webkit-animation: loader-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(-16px, 0, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-16px, 0, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 24px;
  width: 24px;
}
.loader span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  background: $ride-palette-black;
  border-radius: 50%;
  -webkit-animation: loader-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, 16px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, 16px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
.loader span::after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  background: $ride-palette-black;
  border-radius: 50%;
  -webkit-animation: loader-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    -webkit-transform: translate3d(0, -16px, 0) scale(0.5);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes loader-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -16px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
