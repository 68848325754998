.return-link-v2 {
  display: flex;
  flex-direction: row;
  gap: map-get($v2-spacers, 8);
  margin: map-get($v2-spacers, 8) map-get($v2-spacers, 4);
  color: $primary-success-600;

  &:hover {
    cursor: pointer;
    color: $primary-success-700;
  }

  &__arrow {
    display: flex;
    align-self: center;
  }
}
