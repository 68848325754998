.ride-navigation__menu-item {
  font-family: $font-family-figtree;
  display: block;
  color: $text-icon-primary-black;
  padding: 0.75rem 1rem;

  svg {
    color: $text-icon-primary-black;
  }

  &.active {
    background-color: $ride-palette-pastel-light-green;
    color: $ride-palette-dark-green;
    border-left: 5px solid $ride-palette-dark-green;

    svg {
      color: $ride-palette-dark-green;
    }
  }

  &:hover {
    background-color: $ride-palette-pastel-light-green;
  }
}
