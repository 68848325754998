.ride-table-cell-multi-slot {
  @include h8-light;
  grid-column: 1 / -1;

  @include desktop {
    grid-column: initial;
  }

  &--aligned {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }
}
