.company-proof-of-address {
  padding-right: map-get($v2-spacers, 48);
  padding-left: map-get($v2-spacers, 48);

  &__type-of-document {
    margin-top: map-get($v2-spacers, 68);
  }

  &__requirement {
    margin-top: map-get($v2-spacers, 32);
    margin-bottom: map-get($v2-spacers, 64);
  }

  &__example-image {
    width: 585px;
    height: 344px;
    margin-bottom: map-get($v2-spacers, 64);
    background-image: url("/assets/low-tax-broker/company-proof-of-address-example.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__file-upload-area {
    margin-bottom: map-get($v2-spacers, 68);
  }
}
