.button-edit {
  @include h8-light();
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;

  svg {
    vertical-align: bottom;
    margin-left: map-get($spacers, 1);
  }

  &__text {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &--light {
    color: $ride-palette-green;

    &:hover {
      color: $ride-palette-dark-green;
    }
  }

  &--dark {
    color: $ride-palette-dark-green;

    &:hover {
      color: $ride-palette-green;
    }
  }
}
