.operationsDataList {
  &__container {
    width: 100%;
    overflow-x: scroll;

    .react-bootstrap-table {
      width: 100%;
    }

    .table-container {
      box-shadow: none;
    }

    table {
      table-layout: fixed;

      th {
        padding-top: 0;
        padding-left: 20px;
        padding-right: 20px;
        width: 255px;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: middle;

        &:nth-child(1) {
          position: sticky;
          left: 0;
          background: inherit;
        }
      }

      tbody {
        td {
          padding: 24px 20px 0 20px;
          width: 255px;

          &:nth-child(1) {
            position: sticky;
            left: 0;
            background-color: $ride-palette-white;
          }
        }

        tr:hover {
          background-color: $ride-palette-grey-300;

          td:nth-child(1) {
            background-color: $ride-palette-grey-300;
          }
        }
      }
    }
  }

  &__emptyContainer {
    width: 100%;
  }

  &__taskName {
    color: $ride-palette-white;
    font-weight: bold;
    min-height: 110px;

    &:hover {
      text-decoration: none;
      font-weight: bold;
      color: $ride-palette-white;
    }
  }

  &__cell {
    padding: 0 20px;
  }

  &__stepName {
    color: $ride-palette-grey-900;
    font-weight: bold;
    min-height: 110px;

    &:hover {
      color: $ride-palette-grey-900;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.noHeader {
  display: none;
}
