.operational-activities-step {
  @include h8-light();

  p + ul {
    padding-top: map-get($spacers, 25);
  }

  ul {
    list-style: square;
  }

  &__details {
    @include h9-light();

    @include media-breakpoint-up(md) {
      padding: map-get($spacers, 45) map-get($spacers, 6);
    }
  }

  &__save-wrapper {
    margin-top: map-get($spacers, 45);
  }

  &__options-wrapper {
    margin: map-get($spacers, 3) 0;
  }

  label {
    line-height: 1.7em;
  }

  &__question,
  label {
    @include h9();
  }
}
