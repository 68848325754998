// Color Palette

$neutral-0: #fff;
$neutral-50: #f5f7f5;
$neutral-100: #e6ebe7;
$neutral-200: #d1d7d2;
$neutral-300: #d2d5da;
$neutral-500: #9aa29b;
$neutral-600: #626d64;
$neutral-700: #48514a;
$neutral-800: #39433c;
$neutral-900: #222824;

$primary-success-50: #ecfcf0;
$primary-success-100: #d0f8d9;
$primary-success-200: #b0f1be;
$primary-success-300: #77e38f;
$primary-success-400: #54cd6e;
$primary-success-500: #31a74a;
$primary-success-600: #0e8127;
$primary-success-700: #096c1f;
$primary-success-800: #075518;

$accent-100: #e5d4fd;
$accent-200: #d0b2fa;
$accent-300: #b380f9;
$accent-400: #9a6bdb;
$accent-600: #7540bf;
$accent-700: #57259d;
$accent-800: #401b74;

$informational-100: #cde7ff;
$informational-200: #add8ff;
$informational-300: #7abfff;
$informational-400: #47a6ff;
$informational-600: #106cc1;
$informational-700: #135696;
$informational-800: #0f4170;

$warning-100: #fcf2cf;
$warning-200: #fbeab1;
$warning-300: #fadd80;
$warning-400: #f8d04f;
$warning-600: #9a6008;
$warning-700: #835209;
$warning-800: #6c4409;

$danger-100: #ffd4d1;
$danger-200: #ffb3ad;
$danger-300: #ff837a;
$danger-400: #ff5447;
$danger-600: #b82d23;
$danger-700: #89221a;
$danger-800: #671913;

$text-icon-primary-black: #1f2921;
$text-icon-secondary-black: rgba(31, 41, 33, 0.8);
$text-icon-disabled-black: rgba(31, 41, 33, 0.65);

$text-icon-primary-white: #fff;
$text-icon-secondary-white: rgba(255, 255, 255, 0.8);
$text-icon-disabled-white: rgba(255, 255, 255, 0.65);

// Font Weights

$ds-font-weight-light: 400;
$ds-font-weight-heavy: 600;
