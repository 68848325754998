.entity-link {
  &--GREEN {
    color: #39d390;
  }
  &--GREY {
    color: $ride-extra-light-grey;
    :hover {
      color: $ride-extra-light-grey;
    }
  }
}
