.ride-card-value-pair {
  display: flex;
  flex-direction: column;

  &__name,
  &__value {
    flex: 1;
  }

  &__edit {
    display: block;
    text-align: right;

    @include media-breakpoint-up(md) {
      display: inline-block;
      float: right;
    }

    &--column {
      margin-top: map-get($spacers, 23);
    }
  }

  &--row {
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
