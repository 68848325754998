.as-onboarding-choose-package {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .product-section-card {
    max-width: unset;
    background: $neutral-50;
    border: 0;
  }
}
