.subchapter-order-layout {
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  overflow: auto;
  z-index: 50;

  &__backdrop {
    display: block;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 55.438rem;
    max-height: 95%;
    width: 43.5rem;
    border-radius: 1.25rem;
    background-color: #ffffff;
    margin: auto;
    transition: top 0.3s;
    display: flex;
    flex-direction: column;

    &--just-appeared {
      top: 120%;
    }
  }

  &__progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.75rem;
    border-bottom: 1px solid #ececec;
    color: #303436;
    font-weight: 500;
  }

  &__scrollable {
    overflow-y: auto;
    flex: 1 1 1px;
  }

  &__scrollable-address {
    overflow-y: auto;
    flex: 1 1 1px;
    padding-bottom: map-get($v2-spacers, 48);
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}
