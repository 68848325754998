.ride-paper {
  box-shadow: 0.25rem 0.25rem 20px rgba(53, 38, 69, 0.1);
  border-radius: $ride-radius;
  padding: $spacer;
  background-color: $ride-palette-white;
  gap: $spacer;
  transition: box-shadow $ride-transition-time;

  &.clickable:hover {
    box-shadow: 0.5rem 0.5rem 20px rgba(53, 38, 69, 0.1);
  }

  &--default {
    background-color: $ride-palette-white;
  }

  &--accent {
    background-color: $ride-palette-green;
  }

  &--disabled {
    background-color: $ride-palette-grey-400;
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 1rem;
  }
}
