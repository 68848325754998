.third-party-tax-advisor-client-card {
  &__details {
    flex-grow: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__list {
    @include h10();

    .key {
      @include h10-light();
      color: $ride-palette-grey-900;
    }

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 14);
    }
  }
}
