.expert-avatars {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__avatar {
    width: 3.5rem;
    height: 3.5rem;

    & + & {
      margin-left: -0.4375rem;
    }
  }
}
