.section-wrapper {
  &__header {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  &__body {
    > * {
      margin-bottom: 1rem;
    }
  }
}
