.ltb-review-section-title {
  font-weight: $font-weight-medium;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: $ride-palette-black;
  margin-bottom: map-get($v2-spacers, 24);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: map-get($v2-spacers, 12);

  .ride-icon {
    cursor: pointer;
  }
}
