.pta-migration-flow-to-ride-steuerberatung-success {
  &__title {
    display: flex;
    flex-direction: row;
    gap: map-get($v2-spacers, 16);

    //TODO: create proper Icon
    &__icon {
      //confirm-action-modal__illustration--success
      background: url("/assets/uiLibrary/icons/success-illustration.svg") no-repeat center;
      background-size: 32px;
      width: 32px;
      height: 32px;
    }
  }
}
