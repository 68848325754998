.back-button-obsolete {
  background-color: $ride-palette-grey-100;
  width: map-get($v2-spacers, 48);
  height: map-get($v2-spacers, 48);
  border-radius: map-get($v2-spacers, 12);
  border: 0;
  text-align: center;

  &__arrow {
    height: map-get($v2-spacers, 48);
  }
}
