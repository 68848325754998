.company-registry-number {
  &__ride-input {
    .ride-input {
      &__label {
        font-weight: $font-weight-bold;
        font-size: map-get($v2-spacers, 14);
        line-height: map-get($v2-spacers, 21);
        margin-bottom: map-get($v2-spacers, 8);
      }

      &__input {
        border-radius: map-get($v2-spacers, 12);
        padding: map-get($v2-spacers, 12) map-get($v2-spacers, 16);
        font-weight: $font-weight-bold;
        font-size: map-get($v2-spacers, 16);
        line-height: map-get($v2-spacers, 24);
      }
    }
  }
}
