.chapter-contextual-help {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: map-get($v2-spacers, 40) map-get($v2-spacers, 40) map-get($v2-spacers, 24);
  font-family: $font-family-sans-serif;
  font-size: map-get($v2-spacers, 16);
  line-height: map-get($v2-spacers, 24);

  &__content {
    flex-grow: 1;
    h3 {
      color: $ride-palette-black;
      font-weight: $font-weight-medium;
      margin-bottom: map-get($v2-spacers, 8);
    }

    p {
      color: $ride-palette-grey-600;
      font-weight: $font-weight-normal;
    }

    p:not(:last-of-type) {
      margin-bottom: 0.6rem; //no option in the variables at the moment
    }

    p + h3 {
      margin-top: map-get($v2-spacers, 24);
    }

    a {
      color: $ride-palette-black;
      text-decoration: underline;
    }

    a:hover {
      opacity: 0.7;
    }
  }

  &__footer {
    margin-top: map-get($v2-spacers, 40);
    font-weight: $font-weight-medium;
    color: $ride-palette-grey-600;
    font-size: map-get($v2-spacers, 14);
    line-height: 16.8px;
  }

  &__footer-contact {
    color: $ride-palette-black;
  }
}
