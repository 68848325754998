.attend-notary-meeting {
  margin-bottom: map-get($spacers, 53);

  &__date {
    @include h9();
    color: $ride-extra-dark-grey;
    border-bottom: 1px solid $ride-palette-grey-200;
    padding-bottom: map-get($map: $spacers, $key: 3);
  }

  &__contact {
    @include h9-light();
    color: $ride-extra-light-grey;
    padding-top: map-get($map: $spacers, $key: 4);
  }

  &__title {
    @include h6();
    color: $ride-palette-black;
    line-height: 1;
    padding-bottom: map-get($map: $spacers, $key: 2);

    @include media-breakpoint-up(md) {
      line-height: 1.05;
    }
  }

  &__contact-name {
    @include h8();
    color: $ride-extra-dark-grey;
    padding-top: map-get($map: $spacers, $key: 1);
    padding-bottom: map-get($map: $spacers, $key: 2);
  }

  &__contact-group {
    @include h10-light();
    color: $ride-extra-light-grey;
    margin-top: map-get($map: $spacers, $key: 37);
  }

  &__contact-group-value {
    @include h8-light();
    color: $ride-extra-dark-grey;
  }
}
