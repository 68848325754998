.admin-service-tiers-onboarding {
  display: flex;
  flex-direction: column;
  max-width: 47rem;
  margin: 0 auto;
  margin-bottom: 3rem;

  @media only screen and (max-width: 795px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &__technical-issues-alert {
    margin-bottom: 3rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  &__order-with-costs {
    width: 100%;
  }
}

.ltb-overview-card {
  margin-top: 0.5rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
    padding: 1.5rem !important;
  }
}
