.ride-pagination {
  margin-top: map-get($spacers, 4);

  &-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-top: map-get($spacers, 4);
    justify-content: center;
  }

  &-container {
    margin-top: 20px;
  }

  &__button {
    display: inline-flex;
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-right: 4px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: $ride-palette-grey-600;

    &-number {
      &--active {
        border: 1px solid $ride-palette-grey-200;
        border-radius: 3px;
        color: $ride-palette-black;
        text-align: center;
        width: 100%;
        height: 100%;
      }
    }

    &--page {
      padding: 2px;
    }

    &:hover {
      color: $ride-palette-black;
    }

    &-icon {
      &:hover {
        path {
          stroke: $ride-palette-black;
        }
      }
    }
  }
}
