.shareholder-card {
  &__type {
    color: $ride-extra-light-grey;
    font-size: 0.875rem;
    font-weight: 500;
  }

  &__name {
    font-weight: 900;
    font-size: 1rem;
    color: $ride-palette-black;
  }
}
