.send-ib-credentials-form {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 8);
  padding: map-get($v2-spacers, 32);
  text-align: left;

  &__title {
    @include h4();
    margin: auto;
    margin-bottom: map-get($v2-spacers, 32);
  }

  &__label {
    font-weight: $font-weight-bold;
    margin-right: map-get($v2-spacers, 4);
    @include h9();
  }

  &__input {
    margin-top: map-get($v2-spacers, 8);
  }

  &__submit {
    margin: map-get($v2-spacers, 16) auto auto;
  }
}
