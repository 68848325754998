.trading-transaction-history {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;

  &__data {
    width: 100%;
    padding: 5px;

    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }

  @include desktop {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ride-paper {
    @include desktop {
      flex-basis: 50% !important;
    }
  }

  &__label {
    font-weight: bold;
  }
}
