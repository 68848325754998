.tasks-list {
  &__button {
    font-size: 1rem;
    display: inline-block;
  }

  &__description {
    font-size: 1rem;
    color: $ride-palette-black;
  }

  &__icon {
    $icon-size: 90px;
    width: $icon-size;
    height: $icon-size;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &--small {
      $icon-size: 35px;
      width: $icon-size;
      height: $icon-size;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--sign {
      background-image: url("/assets/client/sign.svg");
    }

    &--in_progress {
      background-image: url("/assets/client/in-progress.svg");
    }

    &--completed {
      background-image: url("/assets/client/done-icon.svg");
    }
  }

  &__state-message {
    font-weight: 800;
    font-size: 1.5rem;

    &--in-progress {
      color: $ride-palette-yellow;
    }

    &--done {
      color: $ride-palette-pale-cyan;
    }
  }

  &__modal {
    @include media-breakpoint-up(md) {
      .modal-body,
      .modal-header {
        background-color: $ride-palette-grey-100;
      }
    }
  }

  &__arrow {
    background-image: url("/assets/client/arrow-task.svg");
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    position: absolute;
    padding: 0;
    width: 13px;
    top: 0;
    right: map-get($spacers, 3);
    bottom: 0;
  }

  &__action {
    display: block;
    text-align: right;

    @include media-breakpoint-up(md) {
      display: inline-block;
      float: right;
    }
  }

  &__edit-link {
    font-size: 0.62rem;
    @include media-breakpoint-up(md) {
      font-size: 0.87rem;
    }
  }

  &__state {
    font-style: italic;
    font-size: 0.62rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      font-size: 0.87rem;
    }

    &--TO_BE_DONE {
      color: $ride-palette-bright-red;
    }

    &--COMPLETED {
      color: $ride-palette-pale-cyan;
    }

    &--NOT_POSSIBLE_YET {
      color: $ride-palette-grey-600;
    }

    &--IN_PROGRESS {
      color: $ride-soft-orange;
    }
  }

  &__container {
    margin-top: map-get($spacers, 4);
  }

  &__nominee-task {
    padding-top: map-get($spacers, 3);
    padding-left: map-get($spacers, 25);
    padding-bottom: map-get($spacers, 3);
    background: transparent;
    border: 0;
    border-top: 1px solid $ride-palette-grey-200 !important;

    @include media-breakpoint-up(md) {
      padding-left: map-get($spacers, 3) + map-get($spacers, 35);
    }

    &__title {
      color: $ride-palette-black;
      font-weight: 500;
      font-size: 0.75rem;

      @include media-breakpoint-up(md) {
        color: $ride-palette-grey-600;
        font-size: 0.87rem;
      }
    }
  }

  &__client-task {
    position: relative;
    border: 0;
    cursor: pointer;
    padding-top: map-get($spacers, 3);
    padding-left: map-get($spacers, 25);
    padding-bottom: map-get($spacers, 3);

    & + & {
      border-top: 1px solid $ride-palette-grey-200;
    }

    &__state {
      display: none;
    }

    @include media-breakpoint-up(md) {
      padding: 0;

      &__container {
        padding: map-get($spacers, 25);
        padding-left: map-get($spacers, 45);
        border-left: map-get($spacers, 2) solid $ride-palette-grey-100;
        margin-left: 0;

        &--TO_BE_DONE {
          border-color: $ride-palette-bright-red;
        }

        &--COMPLETED {
          border-color: $ride-palette-pale-cyan;
        }

        &--IN_PROGRESS {
          border-color: $ride-soft-orange;
        }
      }
    }

    &__title {
      padding: 0;
      color: $dark;
      font-size: 0.75rem;
      font-weight: 500;

      @include media-breakpoint-up(md) {
        font-size: 0.87rem;
      }
    }
  }

  &__client-container {
    position: relative;
    z-index: 2;
    border-radius: $border-radius-lg;
    border: 1px solid $ride-palette-grey-200;
    overflow: hidden;
  }

  &__client-tasks-empty {
    background-color: $white;
    border: 1px solid $ride-palette-grey-200;
    border-radius: $border-radius-lg;
    padding: map-get($spacers, 45) map-get($spacers, 4);
    font-weight: 800;
    font-size: 0.75rem;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      padding-left: map-get($spacers, 35) + map-get($spacers, 35);
      font-size: 0.875rem;
    }

    svg {
      margin-right: map-get($spacers, 4);
    }
  }

  &__nominee-container {
    padding-top: $border-radius-lg;
    margin-top: $border-radius-lg * -1;

    background-color: $ride-palette-grey-100;
    border: 1px solid $ride-palette-grey-200;
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
    margin-bottom: map-get($spacers, 48);
    position: relative;
    z-index: 1;

    &__heading {
      font-size: 0.75rem;
      font-weight: 800;
      color: $ride-palette-black;
      margin: 0;
      padding: map-get($spacers, 35) map-get($spacers, 25);

      @include media-breakpoint-up(md) {
        padding: map-get($spacers, 35) map-get($spacers, 45) + map-get($spacers, 2);
        font-size: 0.875rem;
      }
    }
  }
}
