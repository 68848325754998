.clickable {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-md-white {
  @include media-breakpoint-up(md) {
    background-color: $ride-palette-white;
  }
}

.ride-rounded-md {
  @include media-breakpoint-up(md) {
    border-radius: $ride-radius;
  }
}
