.company_assets_choice {
  &__card {
    @include media-breakpoint-up(md) {
      padding-left: map-get($spacers, $key: 6);
      padding-right: map-get($spacers, $key: 6);
      padding-bottom: map-get($spacers, $key: 52);
      background: $ride-palette-tilt;
      border-radius: $border-radius-md;
    }
  }

  &__description {
    margin-bottom: map-get($spacers, $key: 35);

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($spacers, $key: 45);
    }

    & > .steps-form-description:nth-child(2) {
      color: $ride-palette-neutrals-black-65;
      padding-top: map-get($spacers, $key: 2);
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 110%;

    @include media-breakpoint-up(md) {
      width: auto;
      margin-right: map-get($spacers, $key: 1);
    }

    &__item {
      padding-top: map-get($spacers, $key: 45);
      flex-basis: 50%;

      @include media-breakpoint-up(md) {
        flex-basis: auto;
      }
    }
  }

  &__validation-message {
    padding-top: map-get($spacers, $key: 35);
  }

  &__save {
    padding-top: map-get($spacers, $key: 35);

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
}
