.employer-information-form {
  padding-left: 3rem;
  padding-right: 3rem;

  &__field + &__field {
    margin-top: map-get($v2-spacers, 30);
  }

  &__country-mismatch-alert {
    margin-top: map-get($v2-spacers, 30);
    margin-bottom: map-get($v2-spacers, 30);
  }
}

.ride-form-v2 {
  margin-top: map-get($v2-spacers, 30);
}
