.ride-table-row {
  display: grid;
  padding: 20px 0;
  border-bottom: 1px solid $ride-palette-grey-200;
  align-items: center;

  &:hover {
    background: $ride-palette-grey-100;
  }

  .form-group {
    margin-right: 11px;
  }

  .form-label {
    font-size: 0.875rem;
    font-weight: 900;
    color: $ride-palette-black;
  }

  .input-group {
    border-radius: 5px;
    height: 46px;
    border: solid 1px $ride-palette-grey-300;
  }

  .form-control {
    margin-right: 11px;
    border: 0 none;
    box-shadow: none;
    font-size: 1rem;
    height: 46px;
  }

  @include media-breakpoint-up(md) {
    padding: 20px 30px;
  }

  &[data-columns="1"] {
    grid-template-columns: repeat(1, 1fr);

    .mobile-full {
      grid-column: 1/2;
    }

    @include media-breakpoint-up(sm) {
      .mobile-full {
        grid-column: auto;
      }
    }
  }

  &[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);

    .mobile-full {
      grid-column: 1/3;
    }

    @include media-breakpoint-up(sm) {
      .mobile-full {
        grid-column: auto;
      }
    }
  }

  &[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);

    .mobile {
      &-full {
        grid-column: 1/4;
      }

      &-right {
        grid-column: 2/4;
        text-align: right;
      }

      &-13 {
        grid-column: 1/4;
      }

      &-4 {
        grid-column: 4/5;
        grid-row: 1/3;
      }
    }

    @include media-breakpoint-up(sm) {
      .mobile {
        &-full {
          grid-column: auto;
        }

        &-right {
          grid-column: auto;
          text-align: initial;
        }

        &-13 {
          grid-column: auto;
        }

        &-4 {
          grid-column: auto;
          grid-row: auto;
        }
      }
    }
  }

  &[data-columns="4"] {
    grid-template-columns: repeat(4, 1fr);

    .mobile {
      &-full {
        grid-column: 1/5;
      }

      &-right {
        grid-column: 3/5;
        text-align: right;
      }

      &-left {
        grid-column: 1/3;
      }
    }

    @include media-breakpoint-up(sm) {
      .mobile {
        &-full {
          grid-column: auto;
        }

        &-right {
          grid-column: auto;
          text-align: initial;
        }

        &-left {
          grid-column: auto;
        }
      }
    }
  }

  &[data-columns="5"] {
    grid-template-columns: repeat(5, 1fr);

    .mobile {
      &-full {
        grid-column: 1/6;
      }

      &-right {
        grid-column: 4/6;
        text-align: right;
      }

      &-left {
        grid-column: 1/4;
      }
    }

    @include media-breakpoint-up(sm) {
      .mobile {
        &-full {
          grid-column: auto;
        }

        &-right {
          grid-column: auto;
          text-align: initial;
        }

        &-left {
          grid-column: auto;
        }
      }
    }
  }

  &[data-columns="6"] {
    grid-template-columns: repeat(6, 1fr);
    .mobile {
      &-full {
        grid-column: 1/6;
      }
      &-right {
        grid-column: 4/6;
        text-align: right;
      }
      &-left {
        grid-column: 1/4;
      }
    }
    @include media-breakpoint-up(sm) {
      .mobile {
        &-full {
          grid-column: auto;
        }
        &-right {
          grid-column: auto;
          text-align: initial;
        }
        &-left {
          grid-column: auto;
        }
      }
    }
  }

  &[data-columns="6"] {
    grid-template-columns: repeat(6, 1fr);
    .mobile {
      &-full {
        grid-column: 1/6;
      }
      &-right {
        grid-column: 4/6;
        text-align: right;
      }
      &-left {
        grid-column: 1/4;
      }
    }
    @include media-breakpoint-up(sm) {
      .mobile {
        &-full {
          grid-column: auto;
        }
        &-right {
          grid-column: auto;
          text-align: initial;
        }
        &-left {
          grid-column: auto;
        }
      }
    }
  }

  &[data-columns="7"] {
    grid-template-columns: repeat(7, 1fr);
    .mobile {
      &-full {
        grid-column: 1/8;
      }
      &-right {
        grid-column: 4/8;
        text-align: right;
      }
      &-left {
        grid-column: 1/4;
      }
    }
    @include media-breakpoint-up(sm) {
      .mobile {
        &-full {
          grid-column: auto;
        }
        &-right {
          grid-column: auto;
          text-align: initial;
        }
        &-left {
          grid-column: auto;
        }
      }
    }
  }

  &-column-label {
    font-size: 12px;
    font-weight: 800;
    color: $ride-extra-light-grey;
    display: flex;

    &-text {
      margin-right: 5px;
    }
  }
}
