.confirm-accounting-year {
  &-info {
    margin-top: map-get($spacers, 3);
    color: $ride-palette-grey-600;
  }

  &-banner {
    margin-top: map-get($spacers, 3);
  }

  &-contact-ops {
    display: block;
    margin-top: map-get($spacers, 3);
  }
}
