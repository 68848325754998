$min-height: 30px;

.storage-node {
  display: flex;
  min-height: $min-height;
  color: $ride-palette-black;
  font-size: 1rem;
  font-weight: 400;

  &:hover {
    text-decoration: none;
    color: $ride-palette-black;

    .storage-node__icon--download {
      color: $ride-palette-dark-green;
    }
  }

  & + &,
  &__wrapper + &__wrapper {
    margin-top: map-get($spacers, 1);
  }

  &__name {
    line-height: $min-height;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  &__icon {
    width: $icon-size-base;
    height: $icon-size-base;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &--folder {
      background-image: url("/assets/client/folder.svg");
    }

    &--download {
      color: $ride-palette-green;
    }

    &--delete {
      cursor: pointer;
      color: $ride-palette-bright-red;
    }

    &--disabled {
      cursor: default !important;
      color: $ride-palette-grey-800;
    }
  }

  &--disabled {
    cursor: default;
  }

  &--file {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      .storage-node__name {
        flex-basis: 50%;
      }

      .storage-node__icon {
        flex-basis: 50%;
        background-position-x: left;
      }
    }
  }

  &--pending {
    color: $ride-palette-grey-600;
    cursor: default;

    &:hover {
      color: $ride-palette-grey-600;
    }

    .storage-node__tag {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(md) {
      .storage-node__name {
        flex-basis: 50%;
      }

      .storage-node__tag {
        flex-basis: 50%;
      }
    }
  }

  &--toggle-delete {
    color: $ride-palette-pale-cyan;
    cursor: default;

    &:hover {
      color: $ride-palette-pale-cyan;
    }

    .storage-node__tag {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(md) {
      .storage-node__name {
        flex-basis: 50%;
      }

      .storage-node__tag {
        flex-basis: 50%;
      }
    }
  }

  &__knowledge-documents {
    border-bottom: 1px solid $ride-palette-grey-300;
    padding: $spacer 0;

    &:last-child {
      border: none;
    }
  }
}
