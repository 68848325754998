@mixin box-hover-shadow() {
  box-shadow: 0 8px 40px rgba($ride-palette-grey-600, 0.4);

  &:hover {
    box-shadow: 0 0 10px rgba($ride-palette-grey-600, 0.1);
  }
}

.background-gray {
  background-color: $ride-palette-grey-100;
}

.hf-100 {
  height: vh-fix(100);
}

.white-space--pre-line {
  white-space: pre-line;
}

@import "../components/client/company/components/tasks/TaskContainer/style";
@import "../sharedComponents/ChoiceForm/style";
@import "./components/ChaptersOrderLayout/AdminServiceOnboardingForm/CurrentTaxAdvisorDetails/style";
@import "./components/AdminServiceSignUpSection/style";
@import "./components/AdminServiceTiersOnboarding/style";
@import "./components/AdminServiceTiersOnboarding/AdminServiceOrderFormTask/style";
@import "./components/AdminServiceTiersOnboarding/ChoosePackage/style";
@import "./components/AdminServiceTiersOnboarding/PreliminaryTask/style";
@import "./components/AdminServiceTiersOnboarding/ReviewStep/style";
@import "./components/AdminServiceTiersOnboarding/SelectAdditionalFiscalYears/style";
@import "./components/AdminServiceTiersOnboarding/SelectAdditionalFiscalYears/SelectFiscalYearCard/style";
@import "./components/AdminServiceTiersOnboarding/ThankYouPage/style";
@import "./components/AdvertisementCampaignBanner/style";
@import "./components/Banking/AddNewBankAccount/style";
@import "./components/Banking/BankAccountCard/style";
@import "./components/Banking/BankAccountDetailsHeaderSection/style";
@import "./components/Banking/BankAccountForm/style";
@import "./components/Banking/BankAccountTransactionCard/style";
@import "./components/Banking/BankIcon/style";
@import "./components/Banking/EditBankAccount/style";
@import "./components/Banking/EditBankAccountModal/style";
@import "./components/Banking/SelectGmbh/style";
@import "./components/BrokerageAccountForm/style";
@import "./components/BrokerageAccountList/style";
@import "./components/ChaptersContextualHelp/style";
@import "./components/ChangePaymentMethodPopup/style";
@import "./components/ChaptersOrderLayout/AddExistingCompany/CompanyInformation/CompanyBasicInfoStep/style";
@import "./components/ChaptersOrderLayout/AddExistingCompany/CompanyInformation/CompanyMainActivitiesStep/style";
@import "./components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/ProofOfIdFileStep/style";
@import "./components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/SelectIdDocumentTypeForAdminServiceStep/style";
@import "./components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/LastYearlyReportFileStep/style";
@import "./components/ChaptersOrderLayout/AdminServiceOnboardingForm/FiscalYearStart/style";
@import "./components/ChaptersOrderLayout/AdminServiceOnboardingForm/ReviewAndSubmitAdminServiceForm/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/BusinessPurpose/ReviewPurpose/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyAddress/SelectCompanyAddress/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyName/CompanyName/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/OwnershipDetails/ReviewOwnershipData/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/OwnershipDetails/OwnershipDetails/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/ReviewAndSubmitCompanyFounding/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/ReviewShareholders/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyAddress/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentative/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentativeCitizenship/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/sharedComponents/CitizenshipInformation/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/sharedComponents/GenericAddress/style";
@import "./components/ChaptersOrderLayout/CompanyFounding/sharedComponents/PersonAddress/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ConfirmationOfAcceptedBusinessActivitiesTradingStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ConsentToHandlePersonalDataStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ConsentToTradingOnOwnAccountStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/DeclareTaxInTheUSStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/SecurityQuestionsStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/ExperienceLevelStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TaxDeclarationCountryStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TradesLastThreeYearsStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyBankAccountStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/ReviewCompanyInformation/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/DefineShares/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ConfirmLegalDocuments/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/DoneButton/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/LegalDocumentsConfirmationItem/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ReviewDocuments/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/SelectIdDocumentTypeStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ShareholderIdFile/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ReviewShareholderIdDocument/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ShareholderProofOfAddressStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/CompanyProofOfAddressStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ChapterSummary/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/UploadFile/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/FileUploadArea/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/DocumentCard/DocumentStatusBadge/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/DocumentCard/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ErrorFileCard/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/FileCard/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/EntityStatusInformation/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewFieldList/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewTable/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/CitizenshipInformation/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/AreasOfInvestmentStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/InvestingYearsStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/SourceOfWealthStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TypeOfInvestedCapitalDisclaimerStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyRegistryNumberStep/style";
@import "components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyLEINumberStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyRegulatoryInformationStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/EmployerInformationStep/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholderSection/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholder/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareDistribution/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ManagingDirectors/ReviewManagingDirectors/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/ReviewTrader/style";
@import "./components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/style";
@import "./components/ChaptersOrderLayout/OrderCompanyFounding/sharedComponents/CardWithButton/style";
@import "./components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/SelectCompanyFoundationProduct/style";
@import "./components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ContactDetails/ReviewContactDetails/style";
@import "./components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ReviewAndSubmit/ShortOrderReview/style";
@import "./components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/LegalEntityForm/style";
@import "./components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/NaturalPersonForm/style";
@import "./components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/style";
@import "./components/ChaptersOrderLayout/sharedComponents/LegalEntityCard/style";
@import "./components/ChaptersOrderLayout/sharedComponents/ManagingDirectorsList/style";
@import "./components/ChaptersOrderLayout/sharedComponents/PercentageInputCard/style";
@import "./components/ChaptersOrderLayout/sharedComponents/PriceSection/style";
@import "./components/ChaptersOrderLayout/sharedComponents/ReviewEntity/style";
@import "./components/ChaptersOrderLayout/sharedComponents/ShareholderDetailsSectionTitle/style";
@import "./components/ChaptersOrderLayout/sharedComponents/ShareholdersList/style";
@import "./components/ChaptersOrderLayout/style";
@import "./components/ChaptersProgressBar/style";
@import "./components/ChaptersSidebar/style";
@import "./components/ClientHeader/style";
@import "./components/ClientInput/style";
@import "./components/ClientSelect/style";
@import "./components/ClientSubmit/style";
@import "./components/ClientTextFieldEdit/style";
@import "./components/CompanyDropdown/style";
@import "./components/Company/tasks/BankSelectCard/style";
@import "./components/Company/tasks/BankSelectionOptions/style";
@import "./components/Company/tasks/ChooseFiscalYearTask/style";
@import "./components/Company/tasks/IbanViewTask/style";
@import "./components/Company/tasks/LTBActionableTask/style.scss";
@import "./components/Company/tasks/OpenBankAccountDetailsFootnote/style";
@import "./components/Company/tasks/OpenBankAccountFyrstTask/style";
@import "./components/Company/tasks/OpenBankAccountOwnTask/style";
@import "./components/Company/tasks/OpenBankAccountPentaTask/style";
@import "./components/Company/tasks/OpenBankAccountFinomTask/style";
@import "./components/Company/tasks/OpenBankAccountSwanTask/style";
@import "./components/Company/tasks/ProcessGroup/ProcessGroupWrapper/style";
@import "./components/CompanyHeaderSection/style";
@import "./components/CompanyProcessStatesSection/style";
@import "./components/CompanyTabbedNavigation/style";
@import "./components/CompletionDonut/style";
@import "./components/ConfirmAccountingYear/style";
@import "./components/ContinueOrderBanner/style";
@import "./components/CopyableInformation/style";
@import "./components/Entity/style";
@import "./components/Entity/HomeTab/style";
@import "./components/Entity/HomeTab/PreliminaryTask/style";
@import "./components/Entity/ClientTasks/Tasks/PortfolioDataCompletenessTask/style";
@import "./components/ExternalCompanySearchInput/style";
@import "./components/Files/FileMultiSelectionHeader/_style.scss";
@import "./components/Files/FolderBreadcrumb/style";
@import "./components/Files/FolderSelectField/_style.scss";
@import "./components/Files/MultipleFoldersSelection/style";
@import "./components/Files/NodeTreeCard/_style.scss";
@import "./components/Files/UploadControls/style";
@import "./components/Files/UploadFileList/style";
@import "./components/Files/UploadModal/style";
@import "./components/FiscalYearDataUploadConfirmation/style";
@import "./components/FxFlatOrder/CompanyAddress/style";
@import "./components/FxFlatOrder/AssetsAvailableToTrade/style";
@import "./components/FxFlatOrder/ConfirmEmail/style";
@import "./components/FxFlatOrder/SourcesOfWealthSpread/style";
@import "./components/GmbH/AddExistingGmbH/style";
@import "./components/GmbH/AddNewGmbH/style";
@import "./components/GmbHServiceBadge/style";
@import "./components/LeiApplication/style";
@import "./components/LeiNumberSection/style";
@import "./components/Loader/style";
@import "./components/LowTaxBrokerDashboard/style";
@import "./components/LowTaxBrokerDashboard/WepaDirectBanner/style";
@import "./components/LowTaxBrokerDashboard/sharedComponents/LTBDashboardActionCard/style";
@import "./components/LowTaxBrokerDashboard/PayOut/PayOutModal/PayOutSuccessStep/style";
@import "./components/LowTaxBrokerDashboard/PayOut/PayOutModal/PayOutInputStep/style";
@import "./components/LowTaxBrokerDashboard/PayOut/PayOutModal/PayOutReviewStep/style";
@import "./components/LowTaxBrokerDashboard/PayIn/PayInModal/style";
@import "./components/LowTaxBrokerDashboard/ViewSecurityQuestions/ViewSecurityQuestionsModal/style";
@import "components/OnboardingStepCard/style";
@import "./components/LowTaxBrokerOverview/style";
@import "./components/LowTaxBrokerOverview/SetupAccountCard/style";
@import "./components/LowTaxBrokerOverview/ApplicationReviewWithPendingTasksCard/PendingTaskSwitcher/DownloadAndAgreePendingTask/style";
@import "./components/LowTaxBrokerOverview/ApplicationReviewWithPendingTasksCard/PendingTaskSwitcher/DownloadFillAndUploadPendingTask/style";
@import "./components/LowTaxBrokerOverview/ApplicationReviewWithPendingTasksCard/PendingTaskSwitcher/UploadDocumentPendingTask/style";
@import "./components/MobileViewError/style";
@import "./components/OrderAdminServiceGSUpsell/style";
@import "./components/OrderApplicationSubmitted/style";
@import "./components/OrderCompanyFounding/style";
@import "./components/OrderCompanyFoundingServiceConfirmation/style";
@import "./components/PopUpPanel/style";
@import "./components/ProductCard/style";
@import "./components/ProductSectionCard/style";
@import "./components/ReviewEntry/style";
@import "./components/RealEstateTab/style";
@import "./components/RideAddressSearch/style";
@import "./components/RideFormLayout/style";
@import "./components/RideBrokerInternalServerError/style";
@import "./components/SelectedExternalCompanyCard/style";
@import "./components/SelectedProductCard/style";
@import "./components/AdminServicePTAMigrationToRideSteuerberatung/PtaSwitchOfferPartnerBanner/style";
@import "./components/AdminServicePTAMigrationToRideSteuerberatung/PtaTerminationContractModal/style";
@import "./components/AdminServicePTAMigrationToRideSteuerberatung/PtaMigrationFlowToRideSteuerberatung/style";
@import "./components/AdminServicePTAMigrationToRideSteuerberatung/PtaMigrationFlowToRideSteuerberatungSuccess/style";
@import "./components/UnifiedShortOrderForm/CompanyInformationStep/style";
@import "./components/UnifiedShortOrderForm/CompanyInformationStep/Questions/CompanyActivelyOperatingQuestion/style";
@import "./components/UnifiedShortOrderForm/CompanyInformationStep/Questions/CompanyCategoriesQuestion/style";
@import "./components/UnifiedShortOrderForm/CompanyInformationStep/Questions/CryptoTradingQuestion/style";
@import "./components/UnifiedShortOrderForm/CompanyInformationStep/Questions/RealStateQuestion/style";
@import "./components/UnifiedShortOrderForm/PersonalInformationStep/style";
@import "./components/UnifiedShortOrderForm/ProductSelectionStep/style";
@import "./components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceAdditionalYears/_style.scss";
@import "./components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceProductCard/_style.scss";
@import "./components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceTiersModal/_style.scss";
@import "./components/ShortOrderFormEngine/ShortOrderFormLayout/StepLayout/style";
@import "./components/ShortOrderFormEngine/ShortOrderFormLayout/BookACallCard/style";
@import "./components/ShortOrderFormEngine/ShortOrderFormLayout/BookACallCard/ExpertAvatars/style";
@import "./components/ShortOrderFormEngine/ShortOrderFormLayout/BookACallCard/TrustpilotInfo/style";
@import "./components/ShortOrderFormEngine/ShortOrderFormLayout/Partners/style";
@import "./components/ShortOrderFormEngine/ShortOrderFormLayout/style";
@import "./components/UnifiedShortOrderForm/CheckoutStep/OrderCostSummary/style";
@import "./components/UnifiedShortOrderForm/CheckoutStep/style";
@import "./components/SubchapterOrderLayout/style";
@import "./components/TaskCard/style";
@import "./components/TabGroup/style";
@import "./components/UpcomingTasks/style";
@import "./components/UserBrokerSurvey/style";
@import "./components/booking/AdminServiceUpsellOffering/style";
@import "./components/booking/BookingGmbHFlow/style";
@import "./components/booking/_steps/CompanyAssetsChoice/style";
@import "./components/booking/_steps/OperationalActivities/style";
@import "./components/investments/AnnualProfit/style";
@import "./components/investments/CashMultiple/style";
@import "./components/investments/ClubBanner/style";
@import "./components/investments/ClubDealCard/style";
@import "./components/investments/ClubDealDetails/style";
@import "./components/investments/ClubDealDetailsCard/style";
@import "./components/investments/ClubDealsList/AvailableClubDealsList/style";
@import "./components/investments/ClubDealsList/UnavailableClubDealsList/style";
@import "./components/investments/InternalRateOfReturn/style";
@import "./components/investments/InvestmentAssetManagement/style";
@import "./components/investments/InvestmentCard/style";
@import "./components/investments/InvestmentCardDetails/style";
@import "./components/investments/InvestmentList/style";
@import "./components/MigrateAdminServicePackage/AdminServicePackageUpdateSuccess/style";
@import "./components/MigrateAdminServicePackage/style";
@import "./components/MigrateAdminServicePackage/ChoosePackageCard/style";
@import "./components/MigrateAdminServicePackage/ContractSignatureCard/style";
@import "./components/MigrateAdminServicePackage/SetupPaymentCard/PaymentOption/style";
@import "./components/MigrateAdminServicePackage/SetupPaymentCard/style";
@import "./components/MigrateAdminServicePackage/UpgradeAdminServiceToSelectTierBanner/style";
@import "./components/ProductLearnMoreModal/style";
@import "./components/ProductPlaceholderPage/style";
@import "./components/profile/ProfileDetails/style";
@import "./components/profile/ProfilePasswordCard/style";
@import "./components/profile/ProfilePasswordEdit/style";
@import "./components/profile/ProfilePhoneCard/style";
@import "./components/profile/ProfileReferralCodeCard/style";
@import "./components/templates/ActionPage/style";
@import "./components/templates/BlankPage/style";
@import "./components/templates/InvestmentDetailsLayout/style";
@import "./components/templates/InvitePage/style";
@import "./components/ThankYouStep/style";
@import "./components/WepaSection/WepaOnboarding/style";
@import "./components/WepaSection/WepaOnboarding/FiscalYearStartingDate/style";
@import "./components/WepaSection/WepaOnboarding/WepaOnboardingPricingModal/style";
@import "./components/WepaSection/WepaOnboarding/WepaSelectAdditionalFiscalYears/style";
@import "./components/WepaSection/WepaUpsell/style";
@import "./components/WepaSection/WepaGeneralData/style";
@import "./scenes/MobileViewError/style";
@import "./scenes/OrderCompanyFounding/style";
@import "./scenes/OrderCompanyFoundingConfirmation/style";
