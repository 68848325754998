.successForm {
  width: 21.75rem;
  margin: 0 auto;

  &__text {
    margin-bottom: 1.25rem;
    padding-bottom: 0;
  }

  &__info {
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
  }
}
