.ride-card {
  .caret {
    display: inline-block;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: " ";
      background: url("/assets/client/caret.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 22px;
      height: 11px;
    }
  }

  @include media-breakpoint-down(sm) {
    &--collapsed {
      .caret {
        transform: rotate(180deg);
      }

      .card-body {
        display: none;
      }

      &,
      .card-header {
        border-bottom-left-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
      }
    }
  }

  &,
  .card-header {
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: $border-radius-sm;
  }

  &,
  .card-body {
    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
  }

  &.no-padding .card-body {
    padding: 0;
  }

  .card-body {
    overflow: hidden;
  }
}
