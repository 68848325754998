.ride-date-picker {
  padding: 11px 8px 11px 10px;
  border-radius: 12px;
  border: 1px solid $ride-palette-grey-300;
  font-size: 16px;
  color: $ride-palette-grey-600;
  box-shadow: none !important;
  cursor: pointer;

  &-label {
    @include h9();
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 13px 10px;

    &__icon {
      width: 16px;
      height: 16px;
      color: $ride-palette-grey-900;
    }

    &__currentMonth {
      font-size: 12px;
      font-weight: 800;
    }
  }
}
