.file-section-table {
  margin-top: map-get($v2-spacers, 24);

  &__file-upload-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    width: fit-content;
  }

  &__file-input {
    display: none;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.file-edit-modal {
  padding-top: map-get($v2-spacers, 32);
  padding-left: map-get($v2-spacers, 48);
  padding-right: map-get($v2-spacers, 48);

  &__form {
    &-fields {
      display: flex;
      flex-direction: column;

      & > div {
        margin-top: map-get($v2-spacers, 8);
        margin-bottom: map-get($v2-spacers, 8);
      }
    }
  }

  &__file-card {
    margin-top: map-get($v2-spacers, 16);
    margin-bottom: map-get($v2-spacers, 40);
  }
}
