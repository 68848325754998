.company-shareholders-card {
  margin-top: map-get($spacers, 5);

  .ride-card-bis {
    &__body {
      .member-full-name {
        font-weight: bold;
      }

      .member-address {
        color: $ride-palette-grey-600;
      }

      @include media-breakpoint-up(md) {
        .member {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
        }

        .member-full-name {
          order: 0;
        }

        .member-address {
          order: 2;
        }

        .member-shares {
          order: 1;
        }

        .member-votes {
          order: 3;
        }

        .member-full-name,
        .member-address,
        .member-votes,
        .member-shares {
          flex-basis: 50%;

          &__value {
            @include h8();
          }
        }
      }
    }
  }
}
