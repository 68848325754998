.investments-card {
  &__image {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;

    @include media-breakpoint-up(lg) {
      display: block;
      width: 120px;
      height: 120px;
    }

    &--RealEstate {
      background-image: url("/assets/investments/real-estate-placeholder.svg");
    }

    &--Company {
      background-image: url("/assets/investments/holding-placeholder.svg");
    }

    &--BrokerageAccountSet {
      background-image: url("/assets/investments/brokerageAccountSet-placeholder.svg");
    }
  }

  &__ride-club {
    @include h10-light();
    color: $ride-palette-black;
    line-height: 1.1;
    background-color: $ride-palette-grey-100;
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
    padding: map-get($spacers, 31);
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      padding-left: map-get($spacers, 42);
      padding-bottom: map-get($spacers, 23);
    }
  }

  &__ride-club-header {
    @include h10-light();

    &--completed {
      padding-left: map-get($spacers, 31);
      padding-bottom: map-get($spacers, 37);
      border-bottom-left-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;
    }

    svg {
      vertical-align: baseline;
      margin-right: map-get($spacers, 1);
    }

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 2);
      margin-bottom: map-get($spacers, 15);

      &--completed {
        background-color: $ride-palette-grey-100;
        padding: map-get($spacers, 31) map-get($spacers, 42);
      }
    }
  }

  &__ride-club-list {
    color: $ride-palette-grey-600;
    line-height: 1rem;
    margin-bottom: map-get($spacers, 4);
    padding-top: map-get($spacers, 15);
    padding-left: 0;
    list-style: disk;
    list-style-position: inside;

    li {
      @include h10-light();
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

.card-details {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }

  &__header {
    padding-right: map-get($spacers, 3);
    display: flex;
    align-items: baseline;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      min-width: 350px;
    }
  }

  &__title {
    @include h6-light();
    padding-right: map-get($spacers, 14);

    @include media-breakpoint-up(lg) {
      @include h6();
    }
  }

  &__subtitle {
    @include h11-light();
    color: $ride-palette-grey-900;

    @include media-breakpoint-up(lg) {
      color: $ride-extra-light-grey;
    }
  }
}

.card-details-info-line {
  display: flex;
  @include h10();
  margin-top: map-get($spacers, 14);

  &__title {
    @include h10-light();
    color: $ride-palette-grey-900;
  }
}
