.company-regulatory-information {
  &__countries-list {
    margin-bottom: map-get($v2-spacers, 48);
  }
  &__checkboxes {
    > *:not(:last-child) {
      margin-bottom: map-get($v2-spacers, 24);
    }
  }
}
