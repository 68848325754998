.complete-application-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;

  button {
    width: 100%;
  }
}
