.ride-navigation__sidebar {
  width: 18rem;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $ride-palette-white;
  overflow-y: auto;

  &-logo {
    min-height: 1.5 * $ride-header-height;
  }
}

.ride-navigation__sidebar-placeholder {
  width: 18rem;
}
