.document-dropdown-ride-button {
  background-image: none !important;
  box-shadow: none !important;
  width: 80% !important;
  margin: 0 auto !important;

  background-color: $success !important;
  color: $ride-palette-black !important;

  & > svg {
    color: $ride-palette-black !important;
  }

  &--hover,
  &:hover {
    background-color: $ride-palette-green !important;
  }

  &--focus,
  &:focus {
    @include shadow();
  }

  &--active,
  &:active {
    color: $white !important;
    background-color: $ride-palette-dark-green !important;

    & > svg {
      color: $white !important;
    }
  }

  &--disabled,
  &:disabled,
  &:disabled:hover,
  &--disabled:hover {
    color: $ride-palette-grey-600 !important;
    background-color: $ride-palette-grey-300 !important;

    & > svg {
      color: $ride-palette-grey-600 !important;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.documentTemplateDropdown {
  &--toggle {
    &:hover,
    &:active,
    &:focus {
      background: #3bc9bc !important;
      color: $white;
    }
  }
}
