.ride-badge-status {
  padding: 4px 8px;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 9px;
  line-height: 2rem;
  font-weight: normal;

  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
  }

  &__small {
    @include media-breakpoint-up(md) {
      font-size: 9px;
    }
  }

  & + & {
    margin-left: map-get($spacers, 1);
  }

  &__ride_green_outlined {
    &__outlined {
      border-color: $ride-palette-green;
      color: $ride-palette-green;

      &:hover {
        border-color: $ride-palette-dark-green;
        color: $ride-palette-dark-green;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.875rem;
      }
    }
  }

  &__danger {
    &__outlined {
      border-color: $ride-palette-red;
      background: $ride-palette-red-light;
    }

    &__solid {
      border-color: $ride-palette-red;
      background: $ride-palette-red;
      color: $ride-palette-white;
    }

    &__invert-solid {
      border-color: $ride-palette-red;
      background: $ride-palette-red;
      color: $ride-palette-black;
    }
  }

  &__success {
    &__outlined {
      border-color: $ride-palette-green;
      background: $ride-palette-light-green;
    }

    &__solid {
      border-color: $ride-palette-green;
      background: $ride-palette-green;
      color: $ride-palette-white;
    }

    &__invert-solid {
      border-color: $success;
      background: $success;
      color: $ride-palette-black;
    }
  }

  &__info {
    &__outlined {
      border-color: $ride-palette-pale-cyan;
      background: $ride-palette-pale-cyan-light;
    }

    &__solid {
      border-color: $ride-palette-pale-cyan;
      background: $ride-palette-pale-cyan;
      color: $ride-palette-black;
    }

    &__invert-solid {
      border-color: $ride-palette-pale-cyan;
      background: $ride-palette-pale-cyan;
      color: $ride-palette-white;
    }
  }

  &__warning {
    &__outlined {
      border-color: $ride-palette-yellow;
      background: $ride-palette-yellow-light;
    }

    &__solid {
      border-color: $ride-palette-yellow;
      background: $ride-palette-yellow;
      color: $ride-palette-black;
    }

    &__invert-solid {
      border-color: $ride-palette-yellow;
      background: $ride-palette-yellow;
      color: $ride-palette-white;
    }
  }

  &__dark {
    &__outlined {
      border-color: $ride-palette-black;
      background: transparent;
    }

    &__solid {
      border-color: $ride-palette-black;
      background: $ride-palette-black;
      color: $success;
    }

    &__invert-solid {
      border-color: $ride-palette-black;
      background: $ride-palette-black;
      color: $ride-palette-white;
    }

    &__dashed {
      &:hover {
        background-color: $ride-palette-grey-100;
        border-color: #b6b6b6;
        transition: 0.3s;
      }

      font-size: 0.75rem !important;
      font-weight: 500;
      border-style: dashed;
      border-color: $ride-palette-grey-300;
      border-radius: 6px;
      background: transparent;
    }
  }

  &__light_grey {
    &__outlined {
      border-color: $ride-palette-grey-300;
      background: transparent;
      color: $ride-palette-grey-600;
    }

    &__solid {
      border-color: $ride-palette-grey-300;
      background: $ride-palette-grey-300;
      color: $ride-palette-black;
    }

    &__invert-solid {
      border-color: $ride-palette-grey-300;
      background: $ride-palette-grey-300;
      color: $ride-palette-white;
    }
  }

  &__gmbh_badge_status {
    &__solid {
      font-size: 0.75rem !important;
      font-weight: 500;
      border-radius: 6px;
      border-color: $ride-palette-grey-100;
      background: $ride-palette-grey-100;
      color: $ride-palette-black;
    }
  }

  &__accent {
    &__solid {
      border-color: $accent-100;
      background: $accent-100;
      color: $accent-700;
    }
  }

  &__icon,
  &__add-icon {
    padding: 0 6px 0 0;

    &__green {
      circle {
        fill: $ride-palette-green;
      }
    }

    &__orange {
      circle {
        fill: $ride-palette-yellow;
      }
    }

    &__light_blue {
      circle {
        fill: $ride-palette-pale-cyan;
      }
    }

    &__grey {
      circle {
        fill: $info;
      }
    }

    &__red {
      circle {
        fill: $danger;
      }
    }

    &__purple {
      circle {
        fill: $accent-700;
      }
    }
  }

  &__add-icon {
    svg {
      width: 6.67px;
      height: 6.67px;
    }
  }
}
