.button-trash {
  @include h9-light();

  border: 0;
  background-color: transparent;
  background-image: url("/assets/client/delete.svg");
  background-repeat: no-repeat;
  background-position: left 0;
  padding-left: map-get($map: $spacers, $key: 4);
  color: $ride-palette-bright-red;
  padding-top: 4px;
}
