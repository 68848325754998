.upload-document-pending-task {
  &__requirements {
    margin-top: map-get($v2-spacers, 30);
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  &__secondary-text {
    color: $neutral-500;
  }

  &__document-type {
    margin-top: map-get($v2-spacers, 30);
  }
}

.ride-date-picker-v2 {
  margin-bottom: 0;
}
