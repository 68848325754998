.enter-tax-number-task {
  margin-bottom: map-get($spacers, 53);

  .form-group {
    .form-label {
      font-weight: bold;
    }
  }

  &__completed {
    background-color: $ride-input-border-color;
    border-radius: $border-radius-sm;
    padding: map-get($spacers, 3);
    box-shadow: none;
    font-size: 1rem;
    color: $ride-palette-black;
  }
}
