.wepa-direct-banner {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 8);
  padding: map-get($v2-spacers, 16) map-get($v2-spacers, 22);
  background: $primary-success-100;
  border-radius: map-get($v2-spacers, 12);
  margin-bottom: map-get($v2-spacers, 32);

  &__cta {
    margin-top: map-get($v2-spacers, 8);
    align-self: flex-end;
  }
}
