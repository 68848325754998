.ride-card-text-pair {
  display: flex;
  flex-direction: column;

  &__first-text {
    flex: 1;
    color: $ride-palette-black;
    font-size: 16px;
    line-height: 22px;
    margin: 2px 0;
  }

  &__second-text {
    flex: 1;
    color: $ride-palette-grey-600;
    font-size: 16px;
    line-height: 22px;
    margin: 2px 0;
  }
}
