.admin-service-onboarding-review-step {
  padding: 1.5rem;
  background: $neutral-50;
  border-radius: 12px;

  &__company-information {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  &__company-information-field {
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;

    & + & {
      margin-top: 0.5rem;
    }
  }

  &__payment-disclaimer {
    text-align: center;
    color: $text-icon-disabled-black;
  }
}
