.button-delete {
  @include h8();

  border: 0;
  height: 2.5rem;
  background-color: $ride-palette-error;
  color: $white;
  border-radius: 2.5rem;
  padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 4);

  &--hover,
  &:hover {
    background-color: $ride-palette-error-dark !important;
  }

  &__icon {
    margin-right: map-get($map: $spacers, $key: 14);
  }
}
