.codeForm {
  text-align: center;
  &__description {
    font-size: 1rem;
  }
  &__title {
    font-weight: bold;
    margin-top: 1.25rem;
  }
  &__codeInput {
    margin: 0.313rem auto;
  }
  &__resendLink {
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
  }
  &__adviceText {
    margin-top: 2.5rem;
    margin-right: auto;
    margin-left: auto;
    font-size: 0.875rem;
    max-width: 21.75rem;
  }
  &__error {
    color: $ride-palette-error;
    margin-top: 0.313rem;
  }
  &__countdown {
    font-size: 1rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
