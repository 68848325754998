.invoice-conditions-section {
  .ride-card-bis__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.labeled-field-section {
  &__label {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 1.188rem;
    padding-bottom: 1.188rem;
  }

  &:not(:last-child) {
    border-bottom: #e1e1e1 1px solid;
  }
}

.labeled-string-field {
  &__label {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 1.188rem;
    padding-bottom: 1.188rem;
  }

  &__value {
    font-size: 1rem;
    padding-top: 1.188rem;
    padding-bottom: 1.188rem;
  }

  &:not(:last-child) {
    border-bottom: #e1e1e1 1px solid;
  }
}
