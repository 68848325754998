.ride-footer {
  padding-top: map-get($map: $spacers, $key: 45);
  padding-bottom: map-get($map: $spacers, $key: 45);
  border-top: 1px solid $ride-palette-grey-200;
  margin-top: auto;
  background-color: $ride-palette-grey-100;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-up(lg) {
      :first-child {
        margin-left: auto;
      }
    }
  }

  &__section {
    width: 100%;
  }

  &__urls {
    a {
      text-decoration: underline;
    }
  }

  &__language {
    display: flex;
    flex-direction: initial;
    justify-content: center;
  }

  &__img {
    margin-right: 10px;
  }

  &__link-item {
    display: inline-block;
    padding-right: map-get($spacers, 309);
  }

  @include media-breakpoint-up(lg) {
    padding-left: map-get($map: $spacers, $key: 5);
    padding-right: map-get($map: $spacers, $key: 5);
    padding-top: map-get($map: $spacers, $key: 53);
    padding-bottom: map-get($map: $spacers, $key: 53);

    &__container {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      text-align: left;
      justify-content: flex-end;
      align-items: center;
      max-width: 1300px;
    }

    &__section {
      width: auto;
      color: $ride-palette-grey-medium-dark;
    }

    &__icon {
      margin-right: map-get($map: $spacers, $key: 47);
      line-height: 16px;

      svg {
        vertical-align: text-top;
      }
    }

    &__copyright {
      margin-right: map-get($map: $spacers, $key: 45);
      line-height: 1.4;
    }

    &__urls {
      line-height: 1.4;

      @include media-breakpoint-up(lg) {
        margin-right: 0.6rem;
      }
    }
  }
}
