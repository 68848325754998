.entity-details-status-information {
  padding-left: map-get($v2-spacers, 48);
  padding-right: map-get($v2-spacers, 48);

  .shares-input {
    display: flex;
    flex-direction: column;

    margin-bottom: map-get($v2-spacers, 44);

    .percentage-input-card {
      margin-bottom: map-get($v2-spacers, 16);
    }

    .hint {
      align-self: flex-end;

      font-size: map-get($v2-spacers, 14);
      font-weight: $font-weight-medium;
      color: $ride-palette-grey-600;
      line-height: map-get($v2-spacers, 20);
    }
  }

  .tax-jurisdiction-select {
    margin-bottom: map-get($v2-spacers, 30);
  }

  .tax-id-input {
    margin-bottom: map-get($v2-spacers, 30);
  }

  .employment-status-select {
    margin-bottom: map-get($v2-spacers, 30);
  }

  .pep-select {
    margin-bottom: map-get($v2-spacers, 24);
  }

  .pep-context-input {
    height: map-get($v2-spacers, 112);
  }
}
