.file-multi-selection-header {
  background-color: $ride-palette-grey-200;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  @include media-breakpoint-up(md) {
    border-top-right-radius: $border-radius-md;
    border-top-left-radius: $border-radius-md;
    position: absolute;
    top: 0;
    bottom: initial;
  }

  &__container {
    display: block;
    align-items: center;
    width: 100%;
    padding: map-get($spacers, 35) map-get($spacers, 48) map-get($spacers, 47);

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      margin: auto;
      padding: map-get($spacers, 3);
      padding-right: map-get($spacers, 65);
      padding-left: map-get($spacers, 65);
    }
  }

  &__checkbox-section {
    display: flex;
    align-items: baseline;
    text-align: left;
  }

  &__checkbox-label {
    @include h8-light();
    color: $ride-palette-black;
    vertical-align: middle;
  }

  &__submit-section {
    text-align: right;
  }

  &__select_section {
    margin-bottom: map-get($spacers, 3);
    margin-top: map-get($spacers, 23);

    @include media-breakpoint-up(md) {
      flex: 1;
      margin: 0 map-get($spacers, 47);
    }
  }
}
