.order-confirmation {
  color: $ride-palette-black;
  font-size: map-get($v2-spacers, 16);

  &__title {
    font-weight: 500;
    font-size: 3rem;
    line-height: 3.75rem;
    margin-bottom: 0.875rem;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 4.5rem;
  }

  &__title-image {
    margin-bottom: 2.25rem;
  }

  &__title,
  &__title-image,
  &__subtitle {
    text-align: center;
  }

  &__summary {
    margin-top: map-get($v2-spacers, 32);
  }

  &__summary-header {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;

    h3 {
      font-weight: 500;
      font-size: 1.5rem;
      width: 100%;
      line-height: 2rem;
      text-align: center;
    }
  }

  &__summary-confirm-email {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;
  }

  &__summary-confirm-email-icon {
    background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/inbox.svg") no-repeat center;
    background-size: map-get($v2-spacers, 28);
    border-radius: $v2-border-radius-circle;
    padding: 1.75rem;

    &::before {
      display: inline-block;
      position: absolute;
    }
  }

  @include media-breakpoint-down(md) {
    &__summary-confirm-email,
    &__summary-setup-company,
    &__summary-track-status,
    &__summary-status-complete,
    &__summary-company-foundation {
      flex-direction: column;
    }

    &__summary-confirm-email-icon,
    &__summary-setup-company-icon,
    &__summary-track-status-icon,
    &__summary-status-complete,
    &__summary-company-foundation-icon {
      margin-bottom: 1.125rem;
    }

    &__summary-confirm-email-text,
    &__summary-setup-company,
    &__summary-track-status,
    &__summary-status-complete,
    &__summary-company-foundation {
      text-align: center;
    }

    &__title {
      font-size: 2rem;
    }
  }

  &__summary-setup-company {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;
  }

  &__summary-setup-company-icon {
    background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/edit.svg") no-repeat center;
    background-size: map-get($v2-spacers, 28);
    padding: 1.75rem;
    border-radius: $v2-border-radius-circle;
  }

  &__summary-track-status {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;
  }

  &__summary-track-status-icon {
    background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/clock.svg") no-repeat center;
    background-size: map-get($v2-spacers, 28);
    padding: 1.75rem;
    border-radius: $v2-border-radius-circle;
  }

  &__summary-status-complete {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;
  }

  &__summary-status-complete-icon {
    background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/check.svg") no-repeat center;
    background-size: map-get($v2-spacers, 28);
    padding: 1.75rem;
    border-radius: $v2-border-radius-circle;
  }

  &__summary-company-foundation {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;
  }

  &__summary-company-foundation-icon {
    background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/business.svg") no-repeat center;
    background-size: map-get($v2-spacers, 28);
    border-radius: $v2-border-radius-circle;
    padding: 1.75rem;
  }

  &__summary-confirm-email-text,
  &__summary-setup-company-text,
  &__summary-track-status-text,
  &__summary-status-complete-text,
  &__summary-company-foundation-text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;

    h6 {
      font-size: 1rem;
      font-weight: 900;
      line-height: 1.5rem;
    }
  }
}
