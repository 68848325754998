.order-admin-service-gs-upsell {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--card {
    width: 345px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 8px 40px 0 rgba(53, 38, 69, 0.1);
    background-color: #fff;
    margin-bottom: 11.375rem;
  }

  &--card-title {
    font-size: 18px;
    font-weight: 501;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  &--card-bullet-points {
    font-size: 12px;
    font-weight: normal;
    width: 291px;
  }

  &--card-checkbox {
    width: 291px;
    margin-left: 26px;
    padding-bottom: 87px;
  }

  &--title {
    font-size: 24px;
    font-weight: 900;
    padding-top: 4.875rem;
    text-align: center;
    padding-bottom: 2.438rem;
  }
}
