.ride-card-ownership {
  @include h10-light();
  line-height: 16px;
  color: $ride-palette-purple;
  display: flex;
  flex-direction: row;
  align-items: center;

  .ownership + .ownership {
    color: $ride-palette-yellow-dark;
  }

  svg {
    margin-right: map-get($spacers, 14);
    vertical-align: middle;
    margin-top: -4px;
  }

  &__label-ownership-hierarchy {
    line-height: initial;
  }

  &__label-structure-icon {
    flex-shrink: 0;
  }
}
