.book-a-call-card {
  background-color: $neutral-50;
  width: 21.0625rem;
  height: fit-content;
  border-radius: map-get($v2-spacers, 8);
  padding: map-get($v2-spacers, 24);
  display: flex;
  flex-direction: column;

  .expert-avatars {
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__title {
    margin-bottom: map-get($v2-spacers, 8);
    color: #000;
    font-size: map-get($v2-spacers, 16);
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: left;
  }

  &__description {
    margin-bottom: map-get($v2-spacers, 16);
    color: $text-icon-secondary-black;
    font-size: map-get($v2-spacers, 14);
    font-weight: 400;
    line-height: map-get($v2-spacers, 20);
  }

  &__button {
    margin-bottom: map-get($v2-spacers, 24);
    background-color: $accent-100;

    &:hover {
      background-color: $accent-200;
    }

    &:active {
      background-color: $accent-300;
    }
  }
}
