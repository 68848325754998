.admin-service-section {
  font-size: 1rem;
  font-weight: 400;
  color: $black;
  padding-bottom: map-get($spacers, 2);

  &__tax-advisor {
    min-width: 300px;
  }

  &__header {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    &__header {
      max-width: 579px;
      margin-top: 2rem;
      margin-bottom: 3.5rem;
    }

    &__tax-advisor {
      min-width: 236px;
    }

    &__article {
      border-left: 1px solid $ride-palette-grey-300;
    }
  }
}
