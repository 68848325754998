.admin-service-tiers-modal {
  padding: map-get($v2-spacers, 24);
  overflow: auto;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    column-gap: map-get($v2-spacers, 8);
    width: 80rem;

    .product-card {
      height: 100%;
    }
  }

  &__content {
    display: grid;
    column-gap: map-get($v2-spacers, 8);
    max-width: 80rem;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: map-get($v2-spacers, 8);
    max-width: 80rem;
    margin-top: map-get($v2-spacers, 16);
  }

  &__column {
    display: grid;
    grid-auto-rows: 2.5rem 2.5rem 2.5rem map-get($v2-spacers, 96) 9rem 10rem;

    &-features {
      font-family: $font-family-figtree;
      font-size: 1rem;
      font-style: normal;
      font-weight: $ds-font-weight-heavy;
      line-height: 1.5rem;
    }
  }

  &__cell {
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;

    &-not-bulleted {
      text-align: center;
    }

    &:not(&-feature) {
      align-items: center;
    }

    &--grey {
      background: $neutral-50;
    }

    &-textual {
      padding: 0.625rem;
      color: $text-icon-primary-black;
    }

    &-wepa {
      flex-direction: column;
      align-items: center;
    }

    &:not(&-textual, &-feature) {
      display: flex;
      padding-top: map-get($v2-spacers, 4);
      justify-content: flex-start;
    }
  }

  &__supported-icon {
    color: $primary-success-700;
  }

  &__not-supported-icon {
    color: $danger-700;
  }

  .alert-bar {
    display: flex;
    flex-wrap: wrap-reverse;
    align-content: center;

    &--warning {
      background: $warning-100;
      padding: map-get($v2-spacers, 8);
      font-family: $font-family-figtree;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $ds-font-weight-light;
      line-height: 1.25rem;
    }
  }

  @include media-breakpoint-down(md) {
    &__header {
      grid-template-columns: 1fr 1fr 1fr;
      width: 55.17rem;
    }

    &__body {
      grid-template-columns: 1fr 1fr 1fr !important;
      width: 55.17rem;
    }

    &__column {
      display: grid;
      grid-auto-rows: 5rem 5rem 5rem 9.5rem auto auto !important;
    }

    &__cell:not(&-textual, &-feature) {
      gap: map-get($v2-spacers, 8);
      padding-top: map-get($v2-spacers, 8) !important;
    }
  }
}
