.ride-readonly-text {
  border-radius: map-get($v2-spacers, 12);
  padding: map-get($v2-spacers, 24);

  background-color: $ride-palette-grey-100;
  color: $ride-palette-black;

  font-weight: $font-weight-medium;
  font-size: map-get($v2-spacers, 16);
  line-height: map-get($v2-spacers, 24);
}
