.modal-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: $white;
  padding-top: map-get($spacers, 42);
  padding-bottom: map-get($spacers, 5);

  &--light {
    @include media-breakpoint-up(md) {
      background-color: $ride-palette-grey-100;
    }
  }

  &--dark {
    background-color: $ride-palette-black;

    .button-close:hover {
      color: $white;
    }
  }

  &--white {
    @include media-breakpoint-up(md) {
      background-color: $white;
    }
  }

  &__close {
    text-align: right;
  }
}
