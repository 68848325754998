#my-gmbhs {
  ~ .dropdown-menu {
    box-shadow: none;
    background-color: transparent;

    > .dropdown-item {
      &:hover {
        background-color: transparent;
      }
    }
  }
}
