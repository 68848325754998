.preliminary-task-content {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  background: $neutral-50;
  border-radius: 0.5rem;

  &__cta {
    margin: 0 1.5rem 1.5rem auto;
  }
}
