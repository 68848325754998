.product-selection-step {
  &__error {
    margin-bottom: 1rem;
  }

  &__choose-your-products-description {
    margin-bottom: map-get($v2-spacers, 24);
    display: block;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 24);
  }
}
