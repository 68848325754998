.ride-data-gap-bar-wrapper {
  position: relative;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.ride-data-gap-bar {
  position: relative;
  width: 100%;
  height: 2rem;
  background-color: $primary-success-400;
  border-radius: 0.25rem;
}

.ride-data-gap-bar__gap {
  position: absolute;
  height: 100%;
  border-radius: 0.25rem;
  background-image: linear-gradient(
    135deg,
    $warning-400 35%,
    $neutral-600 35%,
    $neutral-600 50%,
    $warning-400 50%,
    $warning-400 85%,
    $neutral-600 85%,
    $neutral-600 100%
  );
  background-size: 0.88375rem 0.88375rem;
}

.ride-data-gap-bar__value {
  position: absolute;
  transform: translateX(-50%);
  font-size: 0.75rem;
  color: $neutral-500;
  padding: 0.125rem 0.25rem;
}

.ride-data-gap-bar__value--top {
  bottom: calc(100% + 0.25rem);
}

.ride-data-gap-bar__value--bottom {
  top: calc(100% + 0.25rem);
}
