.step-button {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: 0;
  margin-bottom: 0;
  color: $dark;
  font-size: 24px;
  font-weight: 800;
  border: solid 1px $dark;
  box-shadow: none;
  padding: 2px 0 0 0;
  pointer-events: none;
  background-color: $white;

  svg {
    width: 35px;
    height: 35px;
    margin-top: -3px;
  }

  @include media-breakpoint-down(sm) {
    width: 35px;
    height: 35px;
    margin-left: -17px;
    font-size: 14px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--open {
    color: $ride-palette-tilt;
    background-color: $dark;
  }
}
