.email-update-confirmation {
  &__reset-link {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 56px;
  }

  .requestFeedbackForm__submit {
    margin-bottom: 32px;
  }
}
