.pop-up-panel {
  .panel {
    width: 21.563rem;
    @include media-breakpoint-up(lg) {
      width: 21.563rem;
    }
    font-size: 1rem;
    font-weight: normal;
    border-radius: 0.75rem;
  }

  .title__section {
    display: flex;
  }

  .panel__title {
    @include h6-light();
    text-align: left;
  }

  .warning-icon {
    margin-right: 0.625rem;

    svg {
      width: 0.9rem;
      height: 0.9rem;
      color: $ride-palette-grey-600;
    }
  }

  .close-button {
    svg {
      width: 0.9rem;
      height: 1.563rem;
    }
  }

  ul {
    padding: 1rem;
    list-style-type: "- ";
  }

  .buttons {
    display: flex;
    align-items: center;
    align-content: space-between;
  }

  button {
    height: 2.5rem;
    margin: auto;
    padding: 0 0.5rem !important;
  }
}
