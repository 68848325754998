.reset-password-form {
  .alert-message {
    margin-bottom: map-get($spacers, 37);
  }

  &__link {
    @include h9-light();
    color: $ride-palette-grey-600;
    text-decoration: underline;
    margin-bottom: map-get($spacers, 53);
    display: block;
  }

  &__submit_area {
    text-align: center;
    margin-bottom: map-get($spacers, 47);
  }

  &__footer {
    text-align: center;
    margin-top: map-get($spacers, 5);
  }
}
