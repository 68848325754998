.labeled-radio-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-text {
    display: block;
    font-family: $font-family-sans-serif;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    color: $ride-extra-dark-grey;
    font-weight: $font-weight-bold;
    margin-bottom: map-get($v2-spacers, 6);
  }

  &-radios-container {
    display: flex;
    flex-direction: row;
    flex-basis: 40px;
    justify-content: flex-start;
    align-items: center;
  }

  &-radio-item {
    padding-right: map-get($v2-spacers, 16);
  }
}
