.chapters-progress-bar {
  &__info {
    display: flex;
    font-family: $font-family-sans-serif;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 22);
    color: $ride-extra-light-grey;
    font-weight: $font-weight-medium;
    margin-top: 10px;
  }
}
