.review-entry {
  padding: map-get($v2-spacers, 36) map-get($v2-spacers, 48);

  &__title {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 20);
    line-height: 1;
    color: $ride-palette-black;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: map-get($v2-spacers, 12);

    .ride-icon {
      cursor: pointer;
    }
  }

  &__label {
    margin-top: map-get($v2-spacers, 30);
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: 1.5;
    color: $ride-extra-light-grey;
  }

  &__value {
    margin-top: map-get($v2-spacers, 8);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: 1.5;
    color: $ride-palette-black;
  }

  &__item {
    margin-top: map-get($v2-spacers, 30);
    padding: map-get($v2-spacers, 20) map-get($v2-spacers, 24);
    background-color: $ride-palette-light-grey;
    border-radius: $v2-border-radius-md;
    color: $ride-palette-black;
    font-weight: $font-weight-bolder;

    & + & {
      margin-top: map-get($v2-spacers, 16);
    }
  }

  & + & {
    border-top: 1px solid $ride-palette-grey-500;
  }
}
