.elevation-100 {
  box-shadow: 0 2px 8px 0 rgba(31, 36, 40, 0.12);
}

.elevation-200 {
  box-shadow: 0 8px 16px 0 rgba(31, 36, 40, 0.12);
}

.elevation-300 {
  box-shadow: 0 8px 32px 0 rgba(31, 36, 40, 0.16);
}
