.return-link {
  font-size: 1rem;
  color: $ride-palette-grey-600;

  &--light {
    &:hover {
      color: $ride-palette-grey-900;
    }
  }

  &--dark {
    &:hover {
      color: $ride-palette-grey-100;
    }
  }
}
