.ltb-review-table {
  display: grid;
  grid-template-columns: repeat(var(--columns), auto);
  row-gap: map-get($v2-spacers, 10);

  &__column-name {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-grey-600;
    margin-bottom: -(map-get($v2-spacers, 2));
  }

  &__entry {
    display: contents;
  }

  &__text {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 22);
    color: $ride-palette-black;
  }

  &__badge {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 12);
    line-height: map-get($v2-spacers, 20);
    color: $ride-palette-black;
    padding: map-get($v2-spacers, 2) map-get($v2-spacers, 6);
    border-radius: map-get($v2-spacers, 4);

    &--grey {
      background-color: $ride-palette-grey-000;
    }

    &--purple {
      background-color: $ride-palette-light-purple;
    }

    &--green {
      background-color: $ride-palette-pastel-light-green;
    }
  }
}
