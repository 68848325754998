.two-fa-notification {
  padding: map-get($spacers, 4);
  text-align: center;

  &__logo {
    margin: auto;
    width: fit-content;
  }

  &__dialog {
    max-width: 702px !important;

    & > div {
      border-radius: 10px !important;
    }
  }

  &__title {
    margin: map-get($spacers, 4) auto;
    font-weight: 800;
    font-size: 1.5rem;
  }

  &__text {
    font-size: 1rem;
    margin-bottom: map-get($spacers, 4);
  }

  &__setupButton {
    margin: map-get($spacers, 5) 0;
  }

  &__rememberLater {
    margin-bottom: map-get($spacers, 2);
  }
}
