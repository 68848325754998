.preliminary-task-home-tab {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__next-step {
    width: max-content;
    margin-bottom: 1rem;
  }

  &__purple-bar {
    height: 0.25rem;
    align-self: stretch;
    background: $accent-400;
  }

  &__alert {
    margin-bottom: 4.5rem;
  }

  &__cta {
    margin: 0 0 1.5rem auto;
  }
}
