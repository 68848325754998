.set_password {
  margin-bottom: map-get($spacers, 45);

  &__content {
    margin-bottom: map-get($spacers, 53);
  }

  &__title {
    @include h8();
    text-align: center;
    margin-bottom: map-get($spacers, 35);
  }

  &__email {
    margin-bottom: map-get($spacers, 45);
  }
}
