.real-state-question {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  &__description {
    margin-top: map-get($v2-spacers, 8);
  }

  &__options {
    .ride-radio-select__options-container {
      display: flex;
      margin-top: map-get($v2-spacers, 24);

      .ride-radio-select__option {
        flex: 1;
        text-align: left;
      }
    }
  }
}
