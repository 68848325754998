.ride-sidebar--obsolete {
  padding-top: 2em;
  height: 100%;

  &__wrapper {
    position: fixed;
    top: $ride-header-height;
    display: flex;
    height: calc(100% - #{$ride-header-height});
    width: 100%;
    z-index: $zindex-modal;

    @include media-breakpoint-down(sm) {
      top: $ride-header-height-sm;
    }

    @include slideout();
  }

  .dropdown-toggle::after {
    border-bottom: 3px solid $ride-palette-grey-600;
    border-right: 3px solid $ride-palette-grey-600;
    border-top: 0;
    border-left: 0;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    position: relative;
    top: 0;
    left: 3px;
  }

  .show .dropdown-toggle {
    color: $ride-palette-white;
  }

  .dropdown-toggle:focus {
    color: $ride-palette-white !important;
  }

  .dropdown-toggle:focus::after {
    border-bottom: 3px solid $ride-palette-white;
    border-right: 3px solid $ride-palette-white;
  }

  .show .dropdown-toggle::after {
    border-top: 3px solid $ride-palette-white;
    border-left: 3px solid $ride-palette-white;
    border-bottom: none;
    border-right: none;
    top: 6px;
  }

  .ride-navbar-dropdown {
    .dropdown-menu {
      position: relative;
      background: none;
      box-shadow: none;
      padding: 0;
      padding-bottom: 10px;
    }

    .dropdown-item {
      padding: 7px 0.5rem;
      font-size: 1.25rem;
      color: $ride-palette-grey-600;
    }

    .dropdown-item:active,
    .dropdown-item:hover {
      background: none;
      color: $ride-palette-white;
    }
  }

  .nav-item {
    padding: 0;

    .nav-link {
      color: $ride-palette-grey-600;
      font-size: map-get($spacers, 45);
      font-weight: $font-weight-bold;
      padding: map-get($spacers, 2);
    }
  }
}

.ride-sidebar {
  height: 100%;
  padding-top: 2em;
  font-family: $font-family-figtree;
  background-color: $ride-palette-white !important;

  &__wrapper {
    position: fixed;
    height: calc(100% - #{$ride-header-height});
    width: 100%;
    z-index: $zindex-modal;

    @include media-breakpoint-down(sm) {
      top: $ride-header-height-sm;
    }

    @include slideout();
  }

  .dropdown-toggle::after {
    background-image: url("/assets/uiLibrary/icons/chevron.svg");
    padding: 0.5rem;
    border-top: 0;
  }

  .show .dropdown-toggle {
    color: $text-icon-primary-black;
  }

  .dropdown-toggle:focus {
    color: $ride-palette-white !important;
  }

  .ride-navbar-dropdown {
    .dropdown-menu {
      position: relative;
      background: none;
      box-shadow: none;
      padding: 0;
      padding-bottom: 10px;
    }

    .dropdown-item {
      padding: 7px 0.5rem;
      font-size: 1.25rem;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: $text-icon-primary-black;
      font-weight: $ds-font-weight-heavy;
      background: none;
    }
  }

  .nav-item {
    padding: 0;

    .nav-link {
      color: $text-icon-primary-black !important;
      font-size: map-get($spacers, 45);
      padding: map-get($spacers, 2);

      &.active,
      &:active {
        font-weight: $ds-font-weight-heavy;
      }
    }
  }
}
