.button {
  min-height: calc(1em);

  &,
  & > svg {
    transition: 0.2s ease-in all;

    * {
      transition: 0.2s ease-in all;
    }
  }

  &--full {
    width: 100%;
  }

  &--large {
    min-height: map-get($v2-spacers, 48);
  }

  &--big {
    @include h7();
    line-height: 1.4;

    $padding-size: 30px;

    border: 0;
    border-radius: $padding-size;
    padding: $padding-size/2 $padding-size;
    min-width: 155px;
  }

  &--medium {
    @include h8();
    line-height: 1.5;

    $padding-size: 25px;

    border: 0;
    padding: $padding-size/3 $padding-size;
    min-width: 115px;

    &-round-radius {
      border-radius: $padding-size;
    }

    &-soft-radius {
      border-radius: 12px;
    }

    &-medium-radius {
      border-radius: 10px;
    }
  }

  &--small {
    @include h10-light();
    line-height: 1.5;

    $padding-size: 15px;

    border: 0;
    border-radius: $padding-size;
    padding: $padding-size/3 $padding-size;
    min-width: 105px;
  }

  &--extraSmall {
    @include h11-light();
    line-height: 1.5;

    $padding-size: 10px;

    border: 0;
    border-radius: $padding-size;
    padding: $padding-size/3 $padding-size;
    min-width: 75px;
  }

  &__icon,
  & > svg {
    margin-top: -2px;
    margin-right: 7px;
    vertical-align: middle;
  }

  &__loading-indicator-container {
    width: 1.34em;
    height: 1.34em;
    margin: auto;
    transform: scale(1.3);
  }

  &__loading-indicator {
    width: 1.34em;
    height: 1.34em;
    border-radius: 1em;
    animation: rotate 1s linear infinite;
  }

  &--loading {
    background: transparent;
    border: 1px solid $ride-palette-grey-300;

    .button__loading-indicator {
      border: 1.5px solid $ride-palette-grey-300;
      border-top-color: $ride-palette-green;
    }
  }

  &--with-loader {
    background: $success;
  }

  &--loading-dark {
    background: transparent;
    border: 1px solid $ride-palette-grey-900;

    .button__loading-indicator {
      border: 1.5px solid $ride-palette-grey-900;
      border-top-color: $ride-palette-green;
    }
  }

  &--secondary {
    background-color: $ride-palette-extra-dark-grey;
    color: $white;

    &--hover,
    &:hover {
      color: $success;
    }

    &--focus,
    &:focus {
      box-shadow: 0 0 5px $success;
    }

    &--active,
    &:active {
      color: $success;
      background-color: $black;
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      cursor: auto;
      color: $ride-palette-grey-600;
      background-color: $ride-palette-grey-300;
    }

    &--with-icon {
      & > svg {
        color: $success;
        --svg-bg-color: #{$ride-palette-extra-dark-grey};
      }

      &.button--secondary--disabled,
      &.button--secondary--disabled:hover,
      &:disabled {
        color: $ride-palette-grey-600;
        background-color: $ride-palette-grey-300;

        & > svg {
          color: $ride-palette-grey-600;
          --svg-bg-color: #{$ride-palette-grey-300};
        }
      }

      &.button--secondary--hover,
      &:hover {
        background-color: $ride-palette-green;
        color: $ride-palette-black;

        & > svg {
          color: $ride-palette-black;
          --svg-bg-color: #{$ride-palette-green};
        }
      }

      &.button--secondary--active,
      &:active {
        color: $white;
        background-color: $ride-palette-dark-green;

        & > svg {
          color: $white;
          --svg-bg-color: #{$ride-palette-dark-green};
        }
      }
    }

    &--soft {
      background-color: $ride-palette-grey-100;
      color: $ride-palette-black;

      &--hover,
      &:hover {
        background-color: $ride-palette-grey-200;
      }

      &--focus,
      &:focus {
        box-shadow: 0 0 5px $ride-palette-grey-600;
      }

      &--active,
      &:active {
        color: $white;
        background-color: $ride-palette-black;
      }

      &--disabled,
      &:disabled,
      &:disabled:hover,
      &--disabled:hover {
        cursor: auto;
        color: $ride-palette-grey-600;
        background-color: $ride-palette-grey-300;
      }

      &--with-icon {
        & > svg {
          color: $success;
          --svg-bg-color: #{$ride-palette-grey-100};
        }

        &.button--secondary--disabled,
        &.button--secondary--disabled:hover,
        &:disabled {
          color: $ride-palette-grey-600;
          background-color: $ride-palette-grey-300;

          & > svg {
            color: $ride-palette-grey-600;
            --svg-bg-color: #{$ride-palette-grey-300};
          }
        }

        &.button--secondary--hover,
        &:hover {
          background-color: $ride-palette-green;
          color: $ride-palette-black;

          & > svg {
            color: $ride-palette-black;
            --svg-bg-color: #{$ride-palette-green};
          }
        }

        &.button--secondary--active,
        &:active {
          color: $white;
          background-color: $ride-palette-dark-green;

          & > svg {
            color: $white;
            --svg-bg-color: #{$ride-palette-dark-green};
          }
        }
      }
    }
  }

  &--secondary-dark {
    border: 2px solid $success;
    background-color: transparent;
    color: $white;

    &--hover,
    &:hover {
      color: $ride-palette-green;
    }

    &--focus,
    &:focus {
      background-color: transparent;
      box-shadow: 0 0 5px $success;
    }

    &--active,
    &:active {
      color: $ride-palette-dark-green;
      border-color: $ride-palette-dark-green;
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-grey-900;
      border-color: $ride-palette-grey-900;
    }

    &--with-icon {
      & > svg {
        color: $success;
        --svg-bg-color: transparent;
      }

      &.button--secondary-dark--active,
      &:active {
        & > svg {
          color: $ride-palette-dark-green;
          --svg-bg-color: transparent;
        }
      }
    }
  }

  &--primary {
    background-color: $success;
    color: $ride-palette-black;

    & > svg {
      color: $ride-palette-black;
      --svg-bg-color: #{$success};
    }

    &--hover,
    &:hover {
      background-color: $ride-palette-green;

      & > svg {
        --svg-bg-color: #{$ride-palette-green};
      }
    }

    &--focus,
    &:focus {
      box-shadow: 0 0 2px $ride-palette-grey-900;
    }

    &--active,
    &:active {
      color: $white;
      background-color: $ride-palette-dark-green;

      & > svg {
        color: $white;
        --svg-bg-color: #{$ride-palette-dark-green};
      }
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-grey-600;
      background-color: $ride-palette-grey-300;

      & > svg {
        color: $ride-palette-grey-600;
        --svg-bg-color: #{$ride-palette-grey-300};
      }
    }
  }

  &--primary-pastel {
    background-color: $ride-palette-pastel-green;
    color: $ride-palette-black;

    & > svg {
      color: $ride-palette-black;
      --svg-bg-color: #{$ride-palette-pastel-green};
    }

    &--hover,
    &:hover {
      background-color: $ride-palette-green;
    }

    &--focus,
    &:focus {
      box-shadow: 0 0 2px $ride-palette-grey-900;
    }

    &--active,
    &:active {
      color: $white;
      background-color: $ride-palette-dark-green;

      & > svg {
        color: $white;
        --svg-bg-color: #{$ride-palette-dark-green};
      }
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-grey-600;
      background-color: $ride-palette-grey-300;

      & > svg {
        color: $ride-palette-grey-600;
        --svg-bg-color: #{$ride-palette-grey-300};
      }
    }
  }

  &--grey {
    background-color: #f9fafa;
    color: $ride-palette-black;

    & > svg {
      color: $ride-palette-black;
      --svg-bg-color: #f9fafa;
    }

    &--hover,
    &:hover {
      background-color: $ride-palette-grey-500;
    }

    &--focus,
    &:focus {
      box-shadow: 0 0 2px $ride-palette-grey-900;
    }

    &--active,
    &:active {
      color: $white;
      background-color: $ride-palette-grey-800;

      & > svg {
        color: $white;
        --svg-bg-color: #{$ride-palette-grey-800};
      }
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-grey-600;
      background-color: $ride-palette-grey-300;

      & > svg {
        color: $ride-palette-grey-600;
        --svg-bg-color: #{$ride-palette-grey-300};
      }
    }
  }

  &--primary-dark {
    background-color: $success;
    color: $ride-extra-dark-grey;

    & > svg {
      color: $ride-extra-dark-grey;
      --svg-bg-color: #{$success};
    }

    &--hover,
    &:hover {
      background-color: $ride-palette-green;
    }

    &--focus,
    &:focus {
      background-color: $ride-palette-green;
      box-shadow: 0 0 5px $success;
    }

    &--active,
    &:active {
      color: $white;
      background-color: $ride-palette-dark-green;

      & > svg {
        color: $white;
        --svg-bg-color: #{$ride-palette-dark-green};
      }
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-black;
      background-color: $ride-palette-grey-900;

      & > svg {
        color: $ride-palette-black;
        --svg-bg-color: #{$ride-palette-grey-900};
      }
    }
  }

  &--danger {
    background-color: $ride-palette-error;
    color: $white;

    &--hover,
    &:hover {
      background-color: $ride-palette-error-dark;
    }

    &--focus,
    &:focus {
      box-shadow: 0 0 5px $ride-palette-grey-600;
    }

    &--active,
    &:active {
      color: $white;
      background-color: $ride-palette-error-light;
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-grey-600;
      background-color: $ride-palette-grey-300;
    }

    &--soft {
      background-color: $ride-palette-red-transparent-1;
      color: $ride-palette-red;

      &--hover,
      &:hover {
        background-color: $ride-palette-red-transparent-2;
      }

      &--focus,
      &:focus {
        box-shadow: 0 0 5px $ride-palette-grey-600;
      }

      &--active,
      &:active {
        color: $white;
        background-color: $ride-palette-red;
      }

      &--disabled,
      &:disabled,
      &:disabled:hover,
      &--disabled:hover {
        color: $ride-palette-grey-600;
        background-color: $ride-palette-grey-300;
      }
    }
  }

  &--danger-dark {
    background-color: $ride-palette-error;
    color: $white;

    &--hover,
    &:hover {
      background-color: $ride-palette-error-dark;
    }

    &--focus,
    &:focus {
      box-shadow: 0 0 5px $ride-palette-grey-600;
    }

    &--active,
    &:active {
      color: $white;
      background-color: $ride-palette-error-light;
    }

    &--disabled,
    &:disabled,
    &:disabled:hover,
    &--disabled:hover {
      color: $ride-palette-black;
      background-color: $ride-palette-grey-900;
    }

    &--link-type {
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--text-v2 {
    font-weight: 900;
    font-size: map-get($v2-spacers, 14);
  }

  &--text-v2-medium {
    font-weight: 800;
    font-size: map-get($v2-spacers, 14);
  }

  &--text-v2-light {
    font-weight: 600;
    font-size: map-get($v2-spacers, 14);
  }

  &--label-type {
    text-align: center;
    cursor: pointer;
  }
}
