.choose-fiscal-year-task {
  &__extra-details {
    @include h8-light();
    margin-top: -#{map-get($spacers, 3)};
    margin-bottom: map-get($spacers, 5);
  }

  &__fiscal-year-options {
    margin-bottom: map-get($spacers, 21);
  }

  &__fiscal-year-select-year-container {
    padding-top: map-get($spacers, 21);
    padding-bottom: map-get($spacers, 21);
  }

  &__fiscal-year-select-quarter-container {
    display: inline-block;
    width: 20%;
  }

  &__selected-fiscal-year {
    display: inline-block;
    margin-left: map-get($spacers, 21);
  }
}
