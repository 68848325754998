/* Trader GmbH Main Page */

$trader-gmbh-black: $ride-palette-black;
$trader-gmbh-bg-tint: #fbfbfb;
$trader-gmbh-theme-color: #0282a6;
$trader-gmbh-disabled-color: $info;
$trader-gmbh-disabled-background: #f2f2f2;
$trader-gmbh-disabled-text: #808080;
$trader-gmbh-danger-color: #f53434;
$trader-gmbh-form-border-radius: 10px;
$trader-gmbh-input-border-radius: 10px;
$trader-gmbh-input-border-color: $ride-input-border-color;
$trader-gmbh-input-border-color-focus: $ride-input-border-color-focus;
$trader-gmbh-white: #ffffff;
$trader-gmbh-green: $success;
$trader-gmbh-legal-text-color: #b6b6b6;
$trader-gmbh-text-link-color: #82d6e8;
$trader-gmbh-light-text: #606367;
$trader-gmbh-optional-color: #787b7c;
$admin-service-order-border-color: #82d6e8;

$trader-gmbh-primary-font-family: "Avenir-Roman", $font-family-sans-serif;
$trader-gmbh-strong-heading-font-family: "Bebas Neue", $font-family-sans-serif;

$trader-gmbh-custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='2.5' fill='#{$trader-gmbh-green}'/></svg>");
$trader-gmbh-custom-checkbox-indicator-icon-checked: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$trader-gmbh-black}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
$trader-gmbh-custom-checkbox-indicator-icon-checked-disabled: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$trader-gmbh-disabled-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$trader-gmbh-fa-chevron-down-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
$trader-gmbh-fa-chevron-down-image-faded: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='lightgray' stroke-width='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");

%disabled-field {
  color: $trader-gmbh-disabled-text;
  background-color: $trader-gmbh-disabled-background;
}

.trader-gmbh {
  .navbar {
    background: $trader-gmbh-black;
  }

  .navbar-brand {
    padding-top: 8px;

    img {
      width: 80px;

      @include media-breakpoint-up(md) {
        width: 120px;
      }
    }
  }

  .navbar-text {
    font-size: 20px;
    font-weight: 500;
    color: $trader-gmbh-black;
  }

  .banner {
    background: $trader-gmbh-white;
    padding-bottom: 0.5rem !important;

    h3 {
      font-size: 38px;
      font-weight: 900;
      color: $trader-gmbh-black;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      color: $trader-gmbh-black;
    }

    @include media-breakpoint-up(md) {
      h3 {
        font-size: 50px;
        margin-bottom: 30px;
      }

      p {
        font-size: 22px;
      }
    }
  }

  .container {
    max-width: 960px;
  }

  .btn {
    box-shadow: none;
  }

  .process-info {
    font-size: 18px;
    font-weight: $font-weight-bolder;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  .start-now-btn {
    border-radius: 40px;
    background-color: $trader-gmbh-green;
    color: $trader-gmbh-black;
    background-image: none;
    font-weight: $font-weight-bolder;
    font-size: 18px;

    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-up(md) {
      padding-left: 4rem;
      padding-right: 4rem;
    }

    &:active {
      color: $trader-gmbh-green;
      background-color: $trader-gmbh-black !important;
    }

    &:hover {
      color: $trader-gmbh-green;
      background-color: $trader-gmbh-black;
    }
  }

  .header-btn {
    border-radius: 40px;
    background: $trader-gmbh-black;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 0;

    svg {
      width: 24px;
      height: 24px;
      color: $trader-gmbh-green;
    }

    &:hover {
      color: $trader-gmbh-black;
      background-color: $trader-gmbh-green;

      svg {
        color: $trader-gmbh-black;
      }
    }

    @include media-breakpoint-up(md) {
      height: 40px;

      font-size: 15px;
      line-height: 28px;

      border: 2px solid $trader-gmbh-green;
      color: $trader-gmbh-white;
      width: auto;

      padding: 0.25rem 1rem !important;

      svg {
        margin-top: -4px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .accordion {
    width: 100%;
  }

  .flow-step {
    border-left: solid 1px $trader-gmbh-black;
    margin-left: -5px;

    .flow-step__body {
      font-weight: 500;
      padding-top: 0;

      margin-left: 70px;

      label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: 900;
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        margin-left: 18px;

        .card-body {
          padding: 0;
        }

        .container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .flow-step__header {
      color: $trader-gmbh-disabled-color;
      background-color: transparent !important;
      font-weight: 500;

      margin-left: -44px;
      font-size: 18px;

      &.flow-step__header--cells {
        display: table-row;
      }

      .flow-step__header-cell {
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 1rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        margin-left: -53px;
        font-size: 28px;
      }

      .circular-steps {
        border-radius: 50%;
        color: $trader-gmbh-black;
        border: solid 1px $trader-gmbh-black;
        background-color: $trader-gmbh-white;
        margin-top: -1px;
        padding: 2px 0 0 0;
        font-weight: $font-weight-bolder;

        font-size: 18px;
        margin-right: 20px;
        width: 40px;
        height: 40px;
        margin-left: 6px;

        &.flow-step__header-inline {
          margin-left: 3px;
        }

        &.flow-step__header-cell {
          margin-left: -20px;
        }

        @include media-breakpoint-up(md) {
          font-size: 25px;
          margin-right: 60px;
          width: 60px;
          height: 60px;
          margin-left: 6px;

          &.flow-step__header-inline {
            margin-left: 2px;
          }

          &.flow-step__header-cell {
            margin-left: -30px;
          }
        }
      }

      svg {
        width: 1rem;
        margin-left: 1.5rem;

        @include media-breakpoint-down(sm) {
          float: right;
          width: 0.75rem;
          margin-left: 0;
          margin-top: 0.6rem;
        }
      }
    }

    &:last-child {
      border-left-color: transparent;

      .flow-step__header {
        padding-bottom: 0;
      }
    }

    &--active {
      border-left-width: 3px;
      margin-left: -7px;

      .flow-step__header {
        cursor: pointer;
        color: $trader-gmbh-black;
        font-weight: 900;

        .circular-steps {
          color: $trader-gmbh-white;
          background-color: $trader-gmbh-black;
          margin-left: 6px !important;

          &.flow-step__header-inline {
            margin-left: 2px;
          }

          &.flow-step__header-cell {
            margin-left: -22px;
          }

          @include media-breakpoint-up(md) {
            margin-left: 5px !important;

            &.flow-step__header-inline {
              margin-left: 1px;
            }

            &.flow-step__header-cell {
              margin-left: -31px;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .step-bg {
    @include media-breakpoint-up(md) {
      border-radius: $trader-gmbh-form-border-radius;
      background-color: $trader-gmbh-bg-tint;
    }
  }

  .custom-radio {
    .custom-control-label {
      &::before {
        border-color: $trader-gmbh-black;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: escape-svg($trader-gmbh-custom-radio-indicator-icon-checked);
        transform: scale(1.6);
      }

      &::before {
        background: $trader-gmbh-black;
      }
    }
  }

  .custom-radio label::before,
  .custom-radio label::after {
    top: 1px;
  }

  .text-input {
    width: 100%;
    height: 48px;
    border-radius: $trader-gmbh-input-border-radius;
    border: solid 1px $trader-gmbh-input-border-color;
    background-color: $white;
    box-shadow: none;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $trader-gmbh-black;
    padding-left: 10px;
    padding-right: 10px;

    &:focus {
      border: solid 2px $trader-gmbh-input-border-color-focus;
      box-shadow: none;
    }

    &:disabled {
      @extend %disabled-field;
    }
  }

  .phone-input.form-control {
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 1.5;

    border-radius: $trader-gmbh-input-border-radius;
    border: solid 1px $trader-gmbh-input-border-color;
    background-color: $white;
    box-shadow: none;

    &:focus {
      border: solid 2px $trader-gmbh-input-border-color-focus;
    }

    &:disabled {
      @extend %disabled-field;
    }
  }

  .react-tel-input .flag-dropdown {
    border: none;
  }

  select.form-control {
    @extend .text-input;

    background-position: right 10px center;
    background-size: 15px;
    background-image: $trader-gmbh-fa-chevron-down-image;

    &:disabled {
      @extend %disabled-field;
      background-image: $trader-gmbh-fa-chevron-down-image-faded;
    }
  }

  .step-title {
    font-size: 24px;
    font-weight: 400;
    color: $trader-gmbh-black;
    font-family: $trader-gmbh-strong-heading-font-family !important;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  .legal-text {
    font-size: 12px;
    color: $trader-gmbh-legal-text-color;
    font-weight: 500;

    a {
      color: $trader-gmbh-text-link-color;
    }
  }

  %action-btn {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.1;
    border-radius: 40px;
    background-color: $white;
  }

  .delete-btn {
    @extend %action-btn;

    width: 130px;
    height: 40px;
    padding: 0;
    color: $trader-gmbh-black;
    border: solid 2px $trader-gmbh-danger-color;

    @include media-breakpoint-down(sm) {
      width: auto;
      padding: 0 1rem;
    }

    svg {
      margin-top: -2px;
      color: $trader-gmbh-danger-color;
    }

    @include media-breakpoint-down(sm) {
      font-size: 12px;
      height: 25px;
      width: auto;
    }
  }

  .delete-btn:hover {
    color: $trader-gmbh-white;
    background-color: $trader-gmbh-danger-color;

    svg {
      color: $trader-gmbh-white;
    }
  }

  .new-additional-btn {
    @extend %action-btn;

    color: $trader-gmbh-white;
    background-color: $trader-gmbh-black;
    width: auto;

    svg {
      margin-top: -4px;
      color: $trader-gmbh-green;
    }

    &:hover {
      color: $trader-gmbh-black;
      background-color: $trader-gmbh-green;

      svg {
        color: $trader-gmbh-black;
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  .step-submit-btn {
    @extend %action-btn;

    width: 168px;
    height: 50px;
    background-color: $trader-gmbh-green;
    color: $trader-gmbh-black;

    &:hover:enabled {
      color: $trader-gmbh-green;
      background-color: $trader-gmbh-black;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  .apply-referral-btn {
    @extend .step-submit-btn;
    height: 47px;
    width: 125px;
    border-radius: 10px;
    margin-top: 0;
  }

  .referral-code-input {
    min-width: 13rem;
  }

  .steps-form-description {
    font-size: 18px;
    line-height: 1.5;
    color: $trader-gmbh-black;
    font-family: $trader-gmbh-primary-font-family !important;

    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  .shares-inline {
    align-items: center;
    margin-bottom: 1.25rem;

    .input-group {
      &.invalid-text-input {
        border: none;
      }
    }

    .input-group-append {
      height: 50px;
      color: $trader-gmbh-black;

      .input-group-text {
        font-size: 18px;
        margin-left: 0.5rem;
      }
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  input {
    &.is-invalid {
      border: solid 2px $trader-gmbh-danger-color;
    }
  }

  .invalid-text-input {
    input.is-invalid:not(.shares-text-input) {
      border: none;
    }
  }

  .shares-text-input {
    width: 55px;
    height: 48px;
    border-radius: $trader-gmbh-input-border-radius !important;
    border: solid 1px $trader-gmbh-input-border-color;
    background-color: $white;
    background-image: none !important;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $trader-gmbh-black;

    text-align: center;

    &:focus {
      border: solid 2px $trader-gmbh-input-border-color-focus;
      box-shadow: none;
    }

    // Ensure that there are no up/down arrows
    /* Firefox */
    -moz-appearance: textfield;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .dateField-text-input {
    width: 118px;
    height: 48px;
    border-radius: $trader-gmbh-input-border-radius;
    border: solid 1px $trader-gmbh-input-border-color;
    text-align: center;

    &:focus {
      box-shadow: none;
    }
  }

  .custom-checkbox .custom-control-input ~ .custom-control-label::before {
    width: 23px;
    height: 23px;
    border: solid 2px $trader-gmbh-black;
    background-color: $white;
    border-radius: 2px;
    margin-top: -3px;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: escape-svg($trader-gmbh-custom-checkbox-indicator-icon-checked);
    margin-left: 1px;
    margin-top: -1px;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background: $trader-gmbh-green;
  }

  .custom-checkbox .custom-control-input[type="checkbox"]:disabled ~ .custom-control-label {
    background-color: initial;
    color: $trader-gmbh-disabled-color;

    &::before {
      border-color: $trader-gmbh-disabled-color;
    }

    &::after {
      background-image: escape-svg($trader-gmbh-custom-checkbox-indicator-icon-checked-disabled);
    }
  }

  .number-post-code-text-input {
    @extend .text-input;
  }

  .disabled-label {
    color: $trader-gmbh-disabled-color;
  }

  .order-btn {
    @extend %action-btn;

    height: 52px;
    background-color: $trader-gmbh-green;
    color: $trader-gmbh-black;

    &:hover {
      color: $trader-gmbh-green;
      background-color: $trader-gmbh-black;
    }

    svg {
      margin-top: -4px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 13px;

      svg {
        margin-top: -4px;
        width: 18px;
      }
    }
  }

  %contact-btn {
    @extend %action-btn;

    border-radius: 40px;
    background: $trader-gmbh-white;
    border-style: solid;
    border-width: 2px;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.1;

    svg {
      margin-top: -4px;
    }
  }

  .contact-btn-blk {
    @extend %contact-btn;
    border-color: $trader-gmbh-black;
    color: $trader-gmbh-black;
    font-size: 15px;
    width: auto;

    svg {
      color: $trader-gmbh-black;
    }

    @include media-breakpoint-down(sm) {
      width: inherit;
      font-size: 13px;

      svg {
        color: $trader-gmbh-black;
        width: 15px;
      }
    }

    @include media-breakpoint-down(md) {
      width: inherit;
      font-size: 13px;
    }
  }

  .price-value {
    font-size: 60px;
    font-weight: 700;
    color: $trader-gmbh-black;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      font-weight: 900;
    }
  }

  .bullet-points {
    background: linear-gradient(180deg, #01769d 0%, #0bccdd 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    color: rgba(255, 255, 255, 0);
  }

  .step-7-agreement {
    margin-left: 1.3rem;

    .list-group-item {
      border: none;
      padding: 0.3rem;
      list-style-type: square;
      display: list-item;
      font-family: $trader-gmbh-primary-font-family !important;
      font-size: 1.25rem;
      background-color: transparent;
    }
  }

  .agreement-checkbox {
    margin-left: -0.375rem;

    .agb-agreement {
      color: $trader-gmbh-light-text;
      font-size: 0.875rem;
      font-weight: 400;
      display: block;
      margin-left: 0.25rem;
      margin-top: -0.125rem;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }

    .custom-control-label {
      padding-left: 0 !important;
      display: inline-block;
    }

    &.agreement-checkbox-error {
      .custom-checkbox .custom-control-input ~ .custom-control-label::before {
        border: solid 1px $trader-gmbh-danger-color;
      }
    }
  }

  .invalid-text-input {
    border-radius: $trader-gmbh-input-border-radius;
    border: solid 2px $trader-gmbh-danger-color;

    .form-control.is-invalid {
      background-image: none;
    }
  }

  .next-steps-items {
    list-style-type: square;
    font-family: $trader-gmbh-primary-font-family;
    font-size: 0.875rem;
    color: $trader-gmbh-black;
    font-weight: normal;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  .next-steps-card {
    box-shadow: none;
    border-radius: 10px;
    border: solid 1px #b6b6b6;
    background-color: $trader-gmbh-white;
    margin-left: -2rem;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }

    h3 {
      color: $trader-gmbh-light-text;
      font-size: 1.5rem;

      @include media-breakpoint-up(md) {
        font-size: 1.75rem;
      }
    }
  }

  .h3-light {
    font-family: $trader-gmbh-strong-heading-font-family;
    font-size: 24px;
    color: $trader-gmbh-light-text;
  }

  .assignment-title {
    padding-top: 50px;
    color: $trader-gmbh-black;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      font-size: 1.25rem;
    }
  }

  .text-muted {
    color: $trader-gmbh-optional-color !important;
    font-size: 14px;
    font-family: $trader-gmbh-primary-font-family;
  }

  .admin-service-radio {
    display: table;
  }

  .admin-service-ordering {
    font-size: 20px;

    .admin-service-package {
      font-weight: 900;
    }
  }

  .admin-service-order-card {
    @extend .next-steps-card;
    border: solid 2px $admin-service-order-border-color;
    border-radius: 6px;
  }

  .order-status-description {
    font-size: 22px;
    text-align: center;
  }

  .admin-service-post-order-banner {
    @extend .banner;
    text-align: center;
  }

  .admin-service-agreement {
    font-size: 16px;
    color: $trader-gmbh-light-text;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .admin-service-assignment-title {
    @extend .assignment-title;
    font-family: $trader-gmbh-strong-heading-font-family;
    font-size: 20px;
  }
}
