.ride-new-entry {
  input[type="checkbox"] {
    border-radius: 50% !important;
  }

  background: $ride-palette-grey-100;
  border-radius: map-get($v2-spacers, 12);

  margin: 0;
  padding: map-get($v2-spacers, 24);

  display: flex;
  align-items: center;

  cursor: pointer;
  transition: 0.3s;

  .label {
    font-weight: $font-weight-heavy;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 22);
    color: $ride-palette-black;
  }

  input[type="checkbox"] {
    appearance: none;
    margin-right: map-get($v2-spacers, 18);

    background-color: $ride-palette-grey-300;
    min-width: map-get($v2-spacers, 28);
    min-height: map-get($v2-spacers, 28);
    border-radius: map-get($v2-spacers, 6);

    align-items: center;
    justify-content: center;
    content: " ";
    display: block;
    width: map-get($v2-spacers, 22);
    height: map-get($v2-spacers, 22);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/assets/sharedComponents/plus-black-icon.svg");
  }

  &:active {
    transform: scale(0.96);
  }

  &:hover {
    -webkit-box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
    -moz-box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
    box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
  }
}
