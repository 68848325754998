.ride-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.25s ease-out;

  &__pointer {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $ride-palette-grey-200;
    margin-right: 5px;
    transition: 0.25s ease-out;

    &--selected {
      transition: 0.25s ease-out;
      background: $success;
      border: 6px solid $ride-extra-dark-grey;
    }
  }

  &__label {
    display: inline-block;
    font-size: 1.125rem;
    color: #303436;
    // 100% of the container minus the 29px of the radio
    width: calc(100% - 29px);
    text-align: left;
  }

  &:hover {
    .ride-radio-button__pointer {
      transition: 0.25s ease-out;
      border: 3px solid $ride-palette-grey-200;

      &--selected {
        transition: 0.25s ease-out;
        border: 8px solid $ride-extra-dark-grey;
      }
    }
  }
}
