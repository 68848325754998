.lost-password-form {
  .requestFeedbackForm__submit {
    margin-top: map-get($map: $spacers, $key: 53);
    margin-bottom: map-get($map: $spacers, $key: 47);
  }

  .alert-message {
    margin-bottom: map-get($map: $spacers, $key: 32);
  }
}
