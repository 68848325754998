.current-tax-advisor-details {
  &__content {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 16);
  }

  &__alert {
    margin-bottom: map-get($v2-spacers, 16);
  }

  &__phone {
    border-radius: map-get($v2-spacers, 12);

    > .ride-input__label {
      line-height: 1.375;
      margin-bottom: map-get($v2-spacers, 8);
    }
  }
}
