.page-content {
  @include media-breakpoint-up(xl) {
    max-width: 1008px;
  }
}

.investment-details-layout {
  &__header {
    background-color: $ride-palette-black;
    margin-bottom: map-get($spacers, 405);
    padding-bottom: map-get($spacers, 8);
  }

  &__children {
    margin-top: -(map-get($spacers, 8));

    .row {
      @extend .page-content;
      margin: auto;
      padding-bottom: map-get($spacers, 6);

      .col {
        padding: 0;
      }
    }
  }

  &-headline {
    @extend .page-content;
    padding: 0;
    padding-top: map-get($spacers, 35);
    display: flex;
    justify-content: space-between;

    &__title {
      @include h4();
      color: $ride-palette-white;
      margin: 0;
    }

    &__edit {
      float: right;
    }
  }

  @include media-breakpoint-up(md) {
    &__header {
      margin-bottom: map-get($spacers, 48);
      padding-bottom: map-get($spacers, 9);
    }

    &__children {
      padding: 0;
      margin-top: -(map-get($spacers, 9));
    }

    &-headline {
      padding-top: map-get($spacers, 6);

      &__title {
        @include h1();
      }
    }
  }
}
