.ride-card-bis {
  box-shadow: none;
  border-radius: $border-radius-sm;

  .caret {
    display: inline-block;
    cursor: pointer;

    &:before {
      display: inline-block;
      content: " ";
      background-image: url("/assets/client/caret.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 22px;
      height: 11px;
    }
  }

  @include media-breakpoint-down(sm) {
    &--collapsed {
      .caret {
        transform: rotate(180deg);
      }

      &,
      .card-header {
        border-bottom-left-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
      }

      .ride-card-bis__body {
        display: none;
      }
    }
  }

  &__header {
    @include h6();
  }

  &,
  &__header {
    border-top-left-radius: $border-radius-sm !important;
    border-top-right-radius: $border-radius-sm !important;
  }

  &,
  &__body {
    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
  }

  &__body {
    padding: map-get($spacers, 23) map-get($spacers, 35);
  }

  &.no-padding {
    .ride-card-bis__body {
      padding: 0;
    }
  }
}
