.client-company {
  &__header-status {
    color: $ride-palette-grey-600;
  }

  &__header-in-founding {
    color: $ride-palette-pale-red;
  }

  &__group-name-container {
    margin-left: 0.875rem;
    margin-top: -1.725rem;

    @include media-breakpoint-up(md) {
      margin-left: 2.4375rem;
      margin-top: -3.125rem;
    }
  }

  &__group-name {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      margin-top: 0.25rem;
    }

    &--faded {
      font-weight: normal;
    }
  }

  &__group-description {
    font-size: 1rem;
    color: $ride-palette-grey-600;
  }

  &__step-name {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: map-get($spacers, 25);
    color: $ride-palette-black;

    @include media-breakpoint-up(md) {
      font-size: 1.37rem;
    }
  }

  &__step-task-header {
    font-size: 0.75rem;
    color: #000000;
    margin-bottom: 1.25rem;
  }

  &__step-task {
    font-size: 1rem;
    color: $ride-palette-grey-800;

    &--faded {
      color: $ride-palette-grey-600;
    }
  }

  &__company-tabbed-navigation-container {
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
      margin-top: 1.413rem;
      margin-bottom: 1.25rem;
    }
  }
}
