.bank-account-form {
  &__form {
    margin-bottom: 57px;

    label {
      line-height: 1;
      margin-bottom: 8px;
    }
  }

  &__input {
    margin-bottom: 16px;
  }

  &__form-line {
    display: flex;
    flex-direction: column;
    margin: 26px 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .client-select__control {
    border-radius: $border-radius-xs;
  }
}
