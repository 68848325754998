.client-submit {
  font-size: 1.125rem;
  padding: 0.5rem 2rem;
  color: $ride-palette-black;
  border-radius: 40px;
  font-weight: 800;
  box-shadow: none;
  background: $success;
  height: 50px;

  &:before {
    content: none;
  }

  &:hover {
    background: $ride-palette-green;
    color: $ride-palette-black;
  }

  &:focus {
    box-shadow: 0px 0px 10px $success;
    background: $success;
    color: $ride-palette-black;
  }

  &:active {
    background: $ride-palette-dark-green !important;
    border: none;
    box-shadow: none;
  }
}
