.admin-service-modal {
  &__wrapper {
    max-width: 650px;
    margin: auto;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 800;
    font-family: $trader-gmbh-primary-font-family;
  }

  &__feature-list {
    padding-left: 0;
    margin-bottom: 2rem;
    font-size: 1rem;
  }

  &__feature {
    margin-top: 1rem;
    list-style: none;
    background-repeat: no-repeat;
    background-image: url("/assets/client/checkmark.svg");
    padding-left: 2rem;
    color: $ride-palette-black;

    strong,
    b {
      font-weight: inherit;
    }

    a {
      text-decoration: underline;
      color: $ride-palette-black;
    }
  }

  &__product-sheet {
    &,
    &:hover {
      color: $ride-palette-grey-800;
      text-decoration: underline;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__price {
    color: $ride-palette-black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    margin-top: 0.25rem;
  }

  &__price-type {
    font-size: 0.875rem;
  }

  &__cta-container {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &__cta {
    color: $ride-palette-black;
    background-color: $success;
    border-radius: 2.5rem;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    font-weight: 800;
    cursor: pointer;

    &:hover {
      color: $ride-palette-black;
      text-decoration: none;
    }
  }
}
