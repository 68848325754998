.document-upload {
  &__done-button-area {
    border-top: 1px solid $v2-border-color;
    display: flex;
    padding: map-get($v2-spacers, 28);
    gap: map-get($v2-spacers, 14);

    &__done-button {
      border-radius: map-get($v2-spacers, 12);
      width: 100%;
    }
  }
}
