.brokerage-account-form {
  margin-top: map-get($map: $spacers, $key: 48);
  margin-bottom: map-get($map: $spacers, $key: 48);

  @include media-breakpoint-up(md) {
    margin-top: map-get($map: $spacers, $key: 4);
    margin-bottom: map-get($map: $spacers, $key: 4);
    margin-left: map-get($map: $spacers, $key: 65);
    margin-right: map-get($map: $spacers, $key: 65);
  }

  &--info {
    @include h10();
    margin-bottom: map-get($map: $spacers, $key: 4);

    @include media-breakpoint-up(md) {
      margin-top: map-get($map: $spacers, $key: 4);
    }
  }

  &--info-links {
    @include h9();
    margin-bottom: map-get($map: $spacers, $key: 4);

    a {
      @include h9();
    }
  }

  &--submit,
  &--delete {
    margin-top: map-get($map: $spacers, $key: 45);
    text-align: center;
  }
}
