.button-expand {
  display: inline-block;
  color: $ride-palette-grey-600;

  &--expanded {
    svg {
      transform: rotate(-180deg);
    }
  }
}
