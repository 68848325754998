.bank-select-card {
  @include column;
  @include h9-light;

  border: 1px solid $ride-palette-grey-200;
  border-radius: $space-s;
  cursor: pointer;

  &--selected {
    border-color: black;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: $space;

    @include desktop {
      flex-direction: row;
    }
  }

  &__header {
    @include row($space);
    @include desktop {
      width: 40%;
    }
  }

  &__title {
    @include row;
  }

  &__logo {
    @include cell;
    align-self: center;
    margin-right: $space-s;
  }

  &__name {
    @include cell;
    @include h8;
    align-self: center;
  }

  &__select-status {
    @include row;
    align-self: center;
  }

  &__footer {
    @include cell;
    padding: $space;
    background-color: $ride-palette-grey-100;
    border-radius: $space-s;
  }

  &:hover {
    border-color: $ride-extra-light-grey;
  }
}

.bank-details {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__info {
    display: flex;
    flex-direction: row;
    margin-top: $space-m-l;

    @include desktop {
      margin-top: 0px;
    }
  }

  &__partner-status {
    margin-top: $space-m-l;

    @include desktop {
      margin-top: 0px;
    }
  }
}

.bank-detail-info {
  margin-right: $space;

  &__label {
    @include h9-light;
    color: $ride-palette-grey-600;
    margin-bottom: 3px;
  }
}
