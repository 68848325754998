.chapters-order-layout {
  display: flex;
  flex-direction: column;
  min-height: vh-fix(100);

  &__application-errors {
    &__list-item {
      margin-top: map-get($v2-spacers, 8);
    }
  }

  &__step-navigation-controls {
    display: flex;
    margin-top: map-get($v2-spacers, 44);

    &--modal-control-area {
      padding: 1.75rem;
      border-top: 1px solid #ececec;
      color: #303436;
      font-weight: 500;
      width: 100%;
      margin-top: 0;
    }

    .back-button-obsolete {
      margin-right: map-get($v2-spacers, 14);
    }
  }

  &__next-button {
    height: map-get($v2-spacers, 48);
  }

  &__footer {
    text-align: center;
  }

  &__step-heading {
    display: block;
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 36);
    line-height: map-get($v2-spacers, 48);
    color: $ride-palette-black;
    margin-bottom: map-get($v2-spacers, 32);
  }

  &__content {
    padding: map-get($v2-spacers, 56) map-get($v2-spacers, 80) map-get($v2-spacers, 96)
      map-get($v2-spacers, 80);
    flex-grow: 1;
    overflow-y: auto;

    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
    }

    &-inner {
      max-width: 600px;
      margin: 0 auto;
    }

    &-step {
      margin-top: map-get($v2-spacers, 40);
    }
  }
}
