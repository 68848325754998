.pay-out-success-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: map-get($v2-spacers, 104);

  &__heading {
    margin-top: map-get($v2-spacers, 32);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 18);
    line-height: map-get($v2-spacers, 20);
  }

  &__alert {
    margin-top: map-get($v2-spacers, 88);
  }

  .ride-icon {
    background-color: rgba(158, 248, 188, 0.2);
  }
}
