.ride-percentage-input {
  &__input {
    margin-top: map-get($v2-spacers, 4);
    padding-right: map-get($v2-spacers, 32);
    height: map-get($v2-spacers, 44);
    width: 78px;
    border: 1px solid $ride-palette-grey-300;
    border-radius: 8px;
    text-align: right;
    font-weight: $font-weight-heavy;
    font-size: 1.123rem;
    line-height: 1.75rem;
    color: $ride-palette-black;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none !important;
      border: 1px solid $ride-palette-grey-300 !important;
    }

    &--error {
      border: 1px solid $ride-palette-error !important;
    }
  }

  &__percent {
    position: absolute;
    right: 28px;
    top: 12px;
    font-weight: $font-weight-heavy;
    font-size: map-get($v2-spacers, 18);
    line-height: map-get($v2-spacers, 28);
  }

  .invalid-feedback {
    display: none !important;
  }
}
