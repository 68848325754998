.thank-you-step-content {
  display: flex;
  flex-direction: column;
  margin: map-get($v2-spacers, 68) auto;
  gap: map-get($v2-spacers, 32);
  max-width: 600px;

  &__title {
    color: $text-icon-primary-black;
  }
  &__content {
    color: $text-icon-disabled-black;
  }
}
