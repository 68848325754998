.company-assets-and-activities {
  @include h8-light();
  padding-top: map-get($spacers, $key: 5);

  &__title {
    padding-bottom: map-get($spacers, $key: 35);
    text-decoration: underline;
  }

  &__label {
    @include h8();

    padding-bottom: map-get($spacers, $key: 2);
  }
}
