.editable-section {
  &__on-edit-buttons {
    display: flex;
    justify-content: space-between;
    background-color: $success;
    padding-top: map-get($spacers, 27);
    padding-bottom: map-get($spacers, 27);
    padding-left: map-get($spacers, 405);
    padding-right: map-get($spacers, 405);
    border-radius: map-get($spacers, 15);
  }

  &__edit-button {
    font-weight: 900;
    float: right;
  }
}
