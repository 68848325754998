.wepa-select-additional-fiscal-years {
  display: flex;
  width: 47rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;

  &__initial-price-button {
    color: $primary-success-600;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: $neutral-50;
    border-radius: 0.75rem;
    padding: 1.5rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__fiscal-years {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__fiscal-year-card {
    width: 100%;

    & + & {
      margin-left: 1rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;
  }

  &__price-summary {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    border-top: 1px solid $neutral-200;
  }

  &__fiscal-year-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid $neutral-200;
  }

  &__total-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
  }
}
