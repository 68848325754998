.review-and-submit-ltb {
  &__entries {
    display: flex;
    flex-direction: column;
    margin-bottom: map-get($v2-spacers, 32);
    gap: map-get($v2-spacers, 24);
  }

  &__missing-information-warning {
    background: rgba(248, 212, 91, 0.15);
    border-radius: map-get($v2-spacers, 8);
    margin-bottom: map-get($v2-spacers, 44);
    font-size: map-get($v2-spacers, 14);
    font-weight: $font-weight-normal;
    padding: map-get($v2-spacers, 16);

    .ride-icon {
      background-color: rgba(248, 212, 91, 0);
      background-size: contain; //TODO: ask designer (charles) about size, etc
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: map-get($v2-spacers, 12);

    &__content {
      .ride-icon {
        display: inline;
      }
      .ride-icon::before {
        content: "";
        margin-right: map-get($v2-spacers, 16);
      }
      .ride-icon::after {
        content: "";
        margin-left: map-get($v2-spacers, 8);
      }
    }
  }

  &__warning-with-tooltip {
    position: relative;

    .ride-icon {
      background-color: #fbf2d2;

      &:hover {
        cursor: pointer;
      }
    }

    &__warning-icon {
      width: fit-content;
    }

    &__tooltip {
      transition: 0.3s ease-in-out;
      position: absolute;
      z-index: 20;
      width: 350px;
      background: $ride-palette-black;
      font-size: map-get($v2-spacers, 14);
      font-weight: $font-weight-normal;
      color: white;
      border-radius: map-get($v2-spacers, 12);
      padding: map-get($v2-spacers, 16);
    }
  }
}
