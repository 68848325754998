$big-logo-height: 31px;
$small-logo-height: 18px;

.ride-logo {
  display: inline-block;
  height: $small-logo-height;

  @include media-breakpoint-up(md) {
    height: $big-logo-height;
  }
}
.ride-logo-black {
  display: inline-block;
  height: $small-logo-height;

  @include media-breakpoint-up(md) {
    height: $big-logo-height;
  }
}
