.company-dropdown {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 8);
  background: #fff;
  padding: map-get($v2-spacers, 8);
  width: min(90vw, 25rem);
  margin: auto;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 2rem 0 rgba(31, 36, 40, 0.16);

  @include media-breakpoint-down(md) {
    padding-bottom: map-get($v2-spacers, 24);
    border-radius: 0;
    border-bottom: 1px solid rgba(31, 36, 40, 0.16);
    box-shadow: none;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 84px;
    padding: 14px 24px 14px 24px;
    border-radius: 8px;
    gap: map-get($v2-spacers, 24);

    &:hover {
      cursor: pointer;
      background: $neutral-50;
    }

    &--active,
    &:active {
      background: $neutral-50;
      font-weight: $ds-font-weight-heavy;
    }
  }

  &__add-company-button {
    display: flex;
    padding: map-get($v2-spacers, 16) map-get($v2-spacers, 24);
    justify-content: center;
    align-items: center;
    gap: map-get($v2-spacers, 16);
    border-radius: 8px;
    border: 1px dashed $neutral-200;
    background: $neutral-0;
    color: $primary-success-600;

    /* Button/100 */
    font-family: $font-family-figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: $ds-font-weight-heavy;
    line-height: 16px; /* 100% */
    letter-spacing: 0.32px;

    &:hover {
      cursor: pointer;
      border: 1px dashed $neutral-200;
      color: $primary-success-700;
    }

    &:active {
      border: 1px dashed $neutral-600;
      background: $neutral-50;
    }
  }

  &__company-details {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 8);

    &__company-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 280px;
    }
  }
}
