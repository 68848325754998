.copyable-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;

  &__label {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 40);
  }

  &__value {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 40);
  }

  &--green {
    color: $primary-success-700;
  }
}
