/* Buttons */

.btn {
  text-transform: none;
  font-size: 1rem;
  margin: 2px;

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-circle {
  width: 47px;
  height: 47px;
  text-align: center;
  border-radius: 25px;
  font-size: 22px;
  line-height: 1;
}

.btn-circle-small {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
  font-size: 11px;
}

.btn-primary {
  color: #fff;
  background-color: transparent;
  border: 0;
  background-image: $ride-active-bg-img;
}

.btn-secondary {
  color: #53d0c4;

  &:hover {
    color: #53d0c4;
  }
}

.line-separator {
  width: 100%;
  margin-bottom: 1rem;
  border: solid 0.5px $info;
}

.ghost-btn {
  background-image: linear-gradient(167deg, rgba(72, 255, 175, 0.89), var(--seafoam-blue));
  font-size: 20px;
  font-weight: $font-weight-normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 0%;
}

.add-plus-btn {
  font-size: 30px;
  font-weight: bolder;
}

/* Header */

h1 {
  margin-bottom: 43px;
  margin-top: 43px;
}

h2 {
  margin-top: 75px;
  margin-bottom: 50px;
  padding-top: 20px;
  background-image: $ride-active-bg-img;
  background-repeat: no-repeat;
  background-size: 70px 5px;
}

.ride-layout {
  margin: 0 -15px -24px -15px;
  padding: 0;

  &__children-container {
    max-width: 1276px !important;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }

  &__header {
    max-width: 1276px !important;

    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }

  .button--secondary {
    @include media-breakpoint-down(sm) {
      margin: 10px 0 10px 0;
    }
  }

  .form-label {
    font-size: 0.875rem;
    font-weight: 900;
    color: $ride-palette-black;
  }

  .input-group {
    border-radius: 5px;
    height: 46px;
    border: solid 1px $ride-palette-grey-300;
  }

  .form-control {
    border: 0 none;
    box-shadow: none;
    font-size: 1rem;
    height: 46px;
  }

  .client-select__label {
    font-size: 0.875rem;
    font-weight: 900;
    color: $ride-palette-black;
  }

  .client-select__control {
    border-radius: 5px;
    height: 46px;
    border: solid 1px $ride-palette-grey-300;
    display: flex;
    flex-direction: row;
  }
}

body {
  font-size: 1.1rem;
}

.dropdown-item {
  font-size: 1.1rem;
}

.project_displayName {
  margin: auto;
  padding-top: 20px;
  font-size: 1.8rem;
  font-weight: 700;
}

.project_thesis {
  margin: auto;
  margin-bottom: 30px;
  padding-top: 20px;
  font-size: 1.2rem;
}

.forgot_pwd {
  padding-left: 20px;
  $font-size: 0.7rem;
  color: #5ddbcf;
}

/* Navbar */

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: $ride-palette-grey-600;
}

.navbar-dark .navbar-nav .nav-link.active:focus {
  color: $white;
}

.navbar-nav {
  .nav-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .nav-link {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 4px solid transparent;
  }
}

.navbar-nav-default {
  .nav-link {
    &.active {
      border-bottom-color: $primary;
    }
  }

  .dropdown.active > .nav-link {
    outline: none;
    border-bottom-color: $primary !important;
  }
}

/* Footer */
.footer-right {
  margin-right: 75px;
}

/* Page */
.page-container {
  min-height: vh-fix(100);
  display: flex;
  flex-direction: column;
}

.content-wrap {
  flex-shrink: 0;
}

html,
body,
#root {
  scrollbar-gutter: stable;
  min-height: vh-fix(100);
}

/* Tables */

.react-bootstrap-table table {
  table-layout: auto;
}

.table th,
.table td {
  border-top: none;
  padding: 0.25rem;

  &:first-child {
    padding-left: 1.8rem;
  }
}

.table thead th {
  border-bottom: none;
  padding-top: 24px;
  padding-bottom: 24px;
  font-weight: bold;
}

.table-container {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07), 0 -10px 30px rgba(0, 0, 0, 0.07),
    10px 0 30px rgba(0, 0, 0, 0.07), -10px 0 30px rgba(0, 0, 0, 0.07);

  &.with-pagination {
    .react-bootstrap-table {
      border-bottom: 1px solid #b6b6b6;
    }

    .react-bootstrap-table-pagination {
      padding: 1rem 5rem 0 5rem;
    }

    .react-bootstrap-table-pagination-total {
      padding: 1rem 5rem 0 5rem;
    }
  }

  .greenTable {
    background-image: $ride-active-bg-img;
    color: white;
    background-color: #5ddbcf;
    background-attachment: fixed;
  }

  .headerHidden {
    display: none;
  }
}

.risiko {
  border-style: solid;
  border-width: 1px;
  text-align: center;
  padding: 20px;
  margin: auto;
}

/* Header images - not included yet */

.wallet {
  padding: 160px 0 160px 42px;
}

/* Basic information */

.basic_information-items {
  margin-top: 25px;
  padding-left: 30px;

  .basic_information-item-title {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
  }

  .basic_information-item-paragraph {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .basic_information-buttons {
    width: 116px;
  }

  .danger {
    color: #d50000;
  }
}

/* Shareholder Resolutions */

.resolution-items {
  margin-top: 25px;
  padding-left: 30px;

  .resolution-item-title {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
  }

  .resolution-item-type {
    font-size: 1rem;
    margin-top: -10px;
    margin-bottom: 20px;
    color: grayscale($color: #5ddbcf);
  }

  .resolution-item-subtitle {
    font-size: 0.8rem;
    font-weight: $font-weight-bold;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .resolution-item-paragraph {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #b6b6b6;
    padding-bottom: 34px;
  }

  .resolution-buttons {
    width: 116px;
  }

  .danger {
    color: #d50000;
  }
}

.assetmanager_dashboard {
  table {
    .parent {
      position: relative;
    }

    .child {
      position: absolute;
      top: 50px;
      right: 0px;
    }
  }

  table.spvs {
    tr {
      cursor: pointer;
    }
  }

  table.invitations tr td:last-child {
    white-space: nowrap;
    width: 1px;
  }
}

/* Images */

.custom-carousel-item {
  background-size: contain;
}

.thumbnail {
  width: 70px;
  height: auto;
}

.asset_detail {
  margin-top: 113px;

  .asset-header {
    margin-left: 48px;
    font-size: 50px;
    margin-bottom: 39px;
  }

  .asset-image {
    width: 100%;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.3);
  }

  .asset-info {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .asset-description {
    margin-left: 48px;
    flex: 1 0 auto;
    font-size: 26px;
  }

  .asset-details {
    margin-left: 22px;
    border-top: 1px solid #b6b6b6;
    font-size: 20px;
  }
}

.assets {
  .card {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07), 0 -10px 30px rgba(0, 0, 0, 0.07),
      10px 0 30px rgba(0, 0, 0, 0.07), -10px 0 30px rgba(0, 0, 0, 0.07);

    img {
      height: 300px;
      object-fit: cover;
    }
  }

  .asset-card-link {
    @include hover {
      text-decoration: none;
    }
  }

  .asset-card-title {
    min-height: 32px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    color: $ride-palette-black;
  }

  .asset-card-description {
    min-height: 40px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $ride-palette-black;
  }

  .asset-card-ownership-gt-0 {
    font-size: 14px;
    line-height: 1.71;
    color: $ride-palette-black;
  }

  .asset-card-ownership-eql-0 {
    font-size: 14px;
    line-height: 1.71;
    color: #a9afb1;
  }

  .parent {
    position: relative;
  }

  .child {
    position: absolute;
    top: -25px;
    right: 16px;
  }
}

.spacer {
  margin: 50px;
}

.space-bottom {
  margin-top: 20px;
  margin-bottom: 50px;
}

.space-bottom-20 {
  margin-top: -20px;
  margin-bottom: 50px;
}

.alert {
  margin-bottom: 30px;
  line-height: 110%;
  font-size: 1.1rem;
  color: $white;
}

.singleBox {
  max-width: 560px;
}

.doubleBox {
  max-width: 800px;
}

.contentBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50rem;
  margin: 30px;

  .card.contentBox {
    width: 95%;
    margin: 0 auto;
    border-radius: 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07), 0 -10px 30px rgba(0, 0, 0, 0.07),
      10px 0 30px rgba(0, 0, 0, 0.07), -10px 0 30px rgba(0, 0, 0, 0.07);
    line-height: 90%;

    .card-title {
      padding: 50px 30px 0px;
      font-size: 1.2rem;
    }

    .card-body {
      margin: 10px;
      line-height: 150%;
      font-size: 1rem;
    }

    .card-header {
      padding: 0;
      background-image: $ride-active-bg-img;
      color: $white;

      .card-header-pills.nav-pills {
        margin: 0;

        .nav-link {
          padding: 30px;
          border-radius: 0;
          font-size: 1.25rem;
          color: $white;

          &.active {
            background-image: $ride-active-bg-img;
          }
        }
      }
    }
  }
}

.floatingCardBox {
  overflow: auto;
}

.floatingCard {
  width: 30%;
  float: left;
}

/* React */

.react-icons {
  vertical-align: middle;
  color: rgba(72, 255, 175, 0.89);
}

.react-datepicker-wrapper {
  display: block;
}

/* Correction */
input:focus,
textarea:focus {
  box-shadow: none;
  border-color: #53d0c4;
}

.center {
  text-align: center;
  padding: 20px;
  margin: auto;
}

.text-underlined {
  text-decoration: underline;
}

.rounded-pill-left {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-pill-right {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.scale-28 {
  width: 2.8rem;
  height: 2.8rem;
}

.btn.border-dark {
  border: 2px solid $black;
}

@for $i from 2 through 5 {
  .border-width-#{$i} {
    border-width: #{$i}px !important;
  }
}

.group-container {
  background-color: red;
}

.roles-section {
  margin: 75px 0;
  box-shadow: 0 8px 40px 0 rgba(53, 38, 69, 0.1);
  &--edit {
    margin-bottom: 80px;
  }
  &--with-warning {
    margin-bottom: 170px;
  }

  &__extraActions {
    margin: 1.5rem 0.5rem !important;
  }
}
