.upcoming-tasks {
  @include h10-light();
  color: $ride-palette-black;

  ul {
    list-style: disc;
    color: $ride-palette-grey-600;
    margin-bottom: 0;
    padding-left: 1rem;
  }
}
