.text-link {
  text-decoration: underline;

  &--PRIMARY {
    color: $ride-palette-green;

    &:hover {
      color: $ride-palette-dark-green;
    }
  }

  &--SECONDARY {
    color: $ride-palette-grey-600;

    &:hover {
      color: $ride-extra-dark-grey;
    }
  }

  &--DANGER {
    color: $ride-palette-error;

    &:hover {
      color: $ride-palette-error-dark;
    }
  }

  &--DARK {
    color: $ride-palette-black;

    &:hover {
      color: $ride-palette-black;
    }
  }

  &--INFO {
    text-decoration: none;
    color: $ride-palette-grey-600;

    &:hover {
      color: $ride-palette-grey-600;
    }
  }
}
