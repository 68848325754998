.review-shareholder-id-document {
  padding-top: map-get($v2-spacers, 16);
  padding-left: map-get($v2-spacers, 48);
  padding-right: map-get($v2-spacers, 48);
  padding-bottom: map-get($v2-spacers, 40);

  &__fields {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 24);
    margin-bottom: map-get($v2-spacers, 16);
  }

  &__uploaded-files {
    margin-top: map-get($v2-spacers, 32);

    &__title {
      font-weight: $font-weight-bold;
      font-size: map-get($v2-spacers, 14);
      margin-bottom: map-get($v2-spacers, 8);
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: map-get($v2-spacers, 8);
    }
  }
}
