.card-header {
  background-color: $ride-palette-grey-300;
  font-weight: bold;
  color: $dark;
}

.shadow-md {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.rounded-md {
  border-radius: 0.75rem;
}

.bg-light-grey {
  @include media-breakpoint-down(sm) {
    background-color: $ride-palette-grey-100;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.ride-muted {
  color: $ride-palette-grey-600;

  &.btn-link {
    text-decoration: underline;

    &:hover {
      color: $ride-palette-grey-800;
    }
  }
}

%view-header {
  background: $secondary;

  h1 {
    font-size: 1.75rem;
    margin: 1.71875rem 0;

    @include media-breakpoint-up(md) {
      font-size: 3.125rem;
      margin: 5.625rem 0 4.375rem 0;
    }
  }
}

.invalid-feedback {
  font-weight: 500;
}

.page-link {
  cursor: pointer;

  .active > &,
  .disabled > & {
    cursor: default;
  }
}

.edit-link {
  font-size: 1rem;
  margin-top: 0.125rem;

  svg {
    margin-left: 0.25rem;
    margin-top: -0.25rem;
  }

  &:hover {
    font-weight: 800;
    text-decoration: none;

    svg {
      margin-bottom: -1px;
    }
  }
}

.text-prewrap {
  white-space: pre-wrap;
}
