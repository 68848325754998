@mixin h1() {
  font-size: 3.125rem;
  font-weight: 800;
}

@mixin h2() {
  font-size: 2.25rem;
  font-weight: 600;
}

@mixin h3() {
  font-size: 1.75rem;
  font-weight: 600;
}

@mixin h3-light() {
  font-size: 1.75rem;
  font-weight: 400;
}

@mixin h4() {
  font-size: 1.5rem;
  font-weight: 800;
}

@mixin h4-light() {
  font-size: 1.5rem;
  font-weight: 400;
}

@mixin h5() {
  font-size: 1.375rem;
  font-weight: 800;
}

@mixin h6() {
  font-size: 1.25rem;
  font-weight: 800;
}

@mixin h6-medium() {
  font-size: 1.25rem;
  font-weight: 600;
}

@mixin h6-light() {
  font-size: 1.25rem;
  font-weight: 400;
}

@mixin h7() {
  font-size: 1.125rem;
  font-weight: 800;
}

@mixin h8() {
  font-size: 1rem;
  font-weight: 800;
}

@mixin h8-light() {
  font-size: 1rem;
  font-weight: 400;
}

@mixin h8-medium() {
  font-size: 1rem;
  font-weight: 900;
}

@mixin h9() {
  font-size: 0.875rem;
  font-weight: 800;
}

@mixin h9-light() {
  font-size: 0.875rem;
  line-height: 1.35;
  font-weight: 400;
}

@mixin h10() {
  font-size: 0.75rem;
  font-weight: 800;
}

@mixin h10-light() {
  font-size: 0.75rem;
  font-weight: 500;
}

@mixin h11-light() {
  font-size: 0.625rem;
  font-weight: 500;
}
