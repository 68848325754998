.admin-service-order-form-task__body {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 16);

  &__information {
    padding: 1rem;
    background: $neutral-50;
    border-radius: 0.5rem;
  }

  &__action-button {
    cursor: not-allowed;
    align-self: flex-end;
  }
}
