.ride-discount-code {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 4);

  &__field-area {
    display: flex;
    flex-direction: row;
    gap: map-get($v2-spacers, 24);
  }

  &__input {
    border-radius: map-get($v2-spacers, 4);
    border: 1px solid $neutral-200;
    height: map-get($v2-spacers, 48);
    box-shadow: none !important;
  }

  &__button {
    min-width: auto;
  }
}
