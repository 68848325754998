.guest-header-deprecated {
  &__contact-button {
    border-radius: map-get($map: $spacers, $key: 4);
    color: $white;

    & > span {
      display: none;
    }

    & + & {
      margin-left: $spacer;
    }

    &-text {
      color: $text-icon-primary-black;
    }

    @include media-breakpoint-up(md) {
      @include h9();
      line-height: 1.5em;
      text-decoration: none;
      border: 2px solid $ride-palette-green;
      padding: map-get($map: $spacers, $key: 14) calc(0.9rem);

      & > span {
        display: initial;
      }

      &:hover {
        text-decoration: none;
        color: $ride-palette-black;
        background-color: $ride-palette-green;

        & > svg {
          color: $ride-palette-black;
        }
      }
    }

    & > svg {
      margin-right: map-get($map: $spacers, $key: 14);
      color: $ride-palette-green;
    }

    & > span {
      vertical-align: middle;
    }
  }
}
