.company-name {
  &__input {
    height: map-get($v2-spacers, 48) !important;
  }

  &__select {
    align-self: flex-start;
    height: map-get($v2-spacers, 48) !important;
    margin-top: map-get($v2-spacers, 30);
    padding-left: 0;
  }
}

.client-select__control--menu-is-open {
  box-shadow: none !important;
  border-color: $ride-palette-black !important;
  outline: none;
}

.client-select__container {
  height: map-get($v2-spacers, 48) !important;
}
