.ride-button-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: map-get($v2-spacers, 4);

  font-family: $font-family-figtree;
  font-weight: $ds-font-weight-heavy;

  &__icon {
    display: contents;
  }

  &__icon-right {
    margin-left: auto;
  }

  &--CENTERED {
    justify-content: center;
  }

  &--BIG {
    flex-grow: 1;
    min-width: 13rem;
    padding: 0.625rem 1rem;
    gap: map-get($v2-spacers, 8);
    height: map-get($v2-spacers, 48);
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 16);
    letter-spacing: 0.02rem;
  }

  &--SMALL {
    height: map-get($v2-spacers, 32);
    padding: 0.5rem 1rem;
    justify-content: center;
    gap: 0.38rem;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 14);
    letter-spacing: 0.015rem;
  }

  &--PRIMARY {
    background: $primary-success-300;
    color: $text-icon-primary-black;

    &:hover {
      background: $primary-success-400;
    }

    &:active {
      background: $primary-success-500;
    }

    &:disabled {
      color: $text-icon-disabled-black;
      background: $primary-success-200;
    }
  }

  &--SECONDARY {
    border: 1px solid $primary-success-700;
    background: $primary-success-50;
    color: $primary-success-700;

    &:hover {
      background: $primary-success-100;
    }

    &:active {
      background: $primary-success-200;
    }

    &:disabled {
      color: $text-icon-disabled-black;
      background: $neutral-50;
      border: 1px solid $neutral-200;
    }
  }

  &--TERTIARY {
    background: $neutral-100;
    color: $text-icon-primary-black;

    &:hover {
      background: $neutral-200;
    }

    &:active {
      background: $neutral-200;
    }

    &:disabled {
      color: $text-icon-disabled-black;
      background: $neutral-100;
    }
  }

  &--GHOST {
    height: auto;
    min-width: unset;
    background: none;
    color: $primary-success-600;

    &.ride-button-v2--BIG {
      padding: map-get($v2-spacers, 8) map-get($v2-spacers, 4);
    }

    &.ride-button-v2--SMALL {
      padding: map-get($v2-spacers, 4) map-get($v2-spacers, 2);
    }

    &:hover {
      color: $primary-success-800;
    }

    &:active {
      color: $primary-success-800;
    }

    &:disabled {
      color: $text-icon-disabled-black;
    }
  }
}
