@mixin slideout() {
  transition: all 300ms linear;

  &--slideout {
    transform: translateX(100%);
  }
}

@mixin cell {
  display: flex;
  box-sizing: border-box;
}

@mixin row($spacing: 0) {
  @include cell;
  flex-direction: row;

  @if $spacing != 0 {
    & > *:not(:last-child) {
      margin: 0 $spacing 0 0;
    }
  }
}

@mixin column($spacing: 0) {
  @include cell;
  flex-direction: column;

  @if $spacing != 0 {
    & > *:not(:last-child) {
      margin: 0 0 $spacing 0;
    }
  }
}

@mixin desktop {
  @include media-breakpoint-up(md) {
    @content;
  }
}
