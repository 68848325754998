$my-gmbh-spacer-height: 1.625rem;
$my-gmbh-spacer-height-desktop: 2.25rem;
$gmbh-tile-gutter-base: $grid-gutter-width / 2;

.my-gmbhs {
  &__header {
    @extend %view-header;
  }

  &__empty {
    @include media-breakpoint-up(md) {
      font-size: 1.375rem;
      font-weight: 800;

      a {
        font-weight: 400;
      }
    }
  }

  &__gmbh {
    margin-bottom: 1.875rem;
  }

  &__gmbhs {
    margin-left: $gmbh-tile-gutter-base;
    margin-right: $gmbh-tile-gutter-base;

    &:first-child {
      margin-top: -$my-gmbh-spacer-height;

      @include media-breakpoint-up(md) {
        margin-top: -$my-gmbh-spacer-height-desktop;
      }
    }

    &:last-child {
      margin-bottom: 1.875rem;
    }
  }

  &--first-gmbh {
    margin-top: -$my-gmbh-spacer-height;

    @include media-breakpoint-up(md) {
      margin-top: -$my-gmbh-spacer-height-desktop;
    }
  }

  &__spacer {
    margin-top: -1px;
    background-color: $white;
    height: $my-gmbh-spacer-height;

    @include media-breakpoint-up(md) {
      height: 0;
    }
  }
}
