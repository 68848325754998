.choice-form {
  &__title {
    @include h3();
    color: $white;
    text-align: center;

    &--light {
      @include h3();
      color: $ride-palette-black;
      text-align: center;
    }
  }
}

.section {
  margin-top: map-get($spacers, 5);

  &__title {
    @include h7();
    color: $white;
    text-align: center;

    &--light {
      @include h7();
      color: $ride-palette-black;
      text-align: center;
    }
  }
}
