.codeInput {
  width: 242px;
  display: flex;
  justify-content: space-between;
  &__input {
    width: 32px;

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button,
    input[type="number"] {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }

    &--error {
      input {
        border: 1px solid $ride-palette-error !important;
      }
    }
  }
}
