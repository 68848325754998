.alert-message {
  @include h10-light();

  border-radius: $border-radius-xs;
  border: 1px solid $ride-palette-grey-200;
  padding: map-get($map: $spacers, $key: 3) map-get($map: $spacers, $key: 22);
  margin-bottom: map-get($map: $spacers, $key: 3);
  color: $ride-palette-black;
  display: flex;

  &--ERROR {
    border-color: $ride-palette-error-light;
    background-color: $ride-palette-red-light;
  }

  &--WARNING {
    border-color: $ride-palette-yellow;
    background-color: $ride-palette-yellow-light;
  }

  &--SUCCESS {
    border-color: #39D390;
    background-color: #F2FFF9;
  }

  &__content {
    align-self: center;
  }

  &__icon {
    margin-right: map-get($map: $spacers, $key: 15);
    flex-shrink: 0;
    width: 24px;
  }

  &__icon-alert {
    color: $ride-palette-error;
  }

  &__icon-warning {
    color: $ride-palette-yellow;
  }
}
