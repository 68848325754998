.chapters-sidebar {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: map-get($v2-spacers, 16);

  &__header {
    padding-top: map-get($v2-spacers, 40);
    padding-left: map-get($v2-spacers, 32);
    padding-right: map-get($v2-spacers, 32);
    margin-bottom: map-get($v2-spacers, 20);
  }

  &__title {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 28);
    line-height: map-get($v2-spacers, 36);
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: map-get($v2-spacers, 72);
    padding: map-get($v2-spacers, 16);
    margin-left: map-get($v2-spacers, 16);
    margin-right: map-get($v2-spacers, 16);
    border-radius: $border-radius-md-extra;
    cursor: pointer;
    transition: 0.3s;

    &--hover,
    &:hover {
      background-color: $ride-palette-light-grey;
    }

    &:active {
      transform: scale(0.96);
    }

    &--ACTIVE {
      background-color: $ride-palette-light-grey;
    }

    &--LOCKED {
      color: $ride-palette-grey-600;
      cursor: default;
      &:hover {
        background-color: inherit;
      }
    }
  }

  &__item-title {
    font-size: map-get($v2-spacers, 16);
    font-weight: 800;
    line-height: map-get($v2-spacers, 22);
    margin-left: map-get($v2-spacers, 20);
  }
}
