.select-fiscal-year-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $neutral-200;
  border-radius: map-get($v2-spacers, 8);
  background-color: $neutral-0;
  width: 9.5rem;
  padding: map-get($v2-spacers, 16);
  cursor: pointer;

  &--selected {
    border: 1px solid $primary-success-700;
    outline: 1px solid $primary-success-700;
  }

  &__checkbox {
    display: flex;
    justify-content: flex-end;
  }
}
