.wealth-navigation-company-header {
  font-family: $font-family-figtree;
  background-color: $neutral-0;
  min-height: 1.5 * $ride-header-height;

  &__company-info {
    width: 100%;
    padding: map-get($v2-spacers, 20) map-get($v2-spacers, 40);

    @include media-breakpoint-up(lg) {
      width: 50rem;
      padding: 0 map-get($v2-spacers, 40);
    }
  }

  &__company-selector-container {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  &__company-name {
    &__title {
      color: $text-icon-primary-black;
      font-style: normal;
      font-weight: $ds-font-weight-heavy;
      line-height: map-get($v2-spacers, 40);
    }
  }

  &__client-tags {
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  h1 {
    font-size: 3.125rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
      overflow-wrap: anywhere;
    }
  }
}
