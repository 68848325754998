.ordered-list {
  $item-order-size: map-get($spacers, 47);

  @include column();
  margin-bottom: map-get($spacers, 5);

  &__item {
    display: flex;
    position: relative;
    padding-bottom: map-get($spacers, 47);

    &:last-child {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      display: block;
      position: absolute;
      top: $item-order-size;
      left: $item-order-size / 2;
      content: "";
      background-color: black;
      width: 1px;
      height: calc(100% - #{$item-order-size});
    }
  }

  &__item-order {
    @include h9();
    text-align: center;
    line-height: $item-order-size;
    width: $item-order-size;
    height: $item-order-size;
    flex-shrink: 0;
    border: 1px solid $ride-palette-black;
    border-radius: 50%;
  }

  &__item-content {
    @include h8-light();
    padding-left: map-get($spacers, 3);
  }
}
