.select-gmbh {
  padding-left: 35px;
  padding-right: 35px;

  &__title {
    font-size: 24px;
    font-weight: 900;
    text-align: left;
    color: $ride-palette-black;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__company-select {
    margin-bottom: 57px;
  }

  @include media-breakpoint-up(md) {
    background-color: $ride-palette-white;
    width: 46.563rem;
    border-radius: map-get($spacers, 23);
    margin: auto;

    &__wrapper {
      padding-top: 25px;
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 50px;
    }

    &__title {
      font-size: 36px;
      font-weight: 900;
      text-align: left;
      color: $ride-palette-black;
      margin-bottom: 30px;
    }

    &__description {
      font-size: 16px;
      margin-bottom: 30px;
    }

    &__company-select {
      margin-bottom: 57px;
      width: 305px;
    }
  }
}
