.ride-broker-internal-server-error {
  height: 100%;
  max-width: 37.25rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16rem;

  &__title {
    text-align: center;
    margin-bottom: map-get($v2-spacers, 16);
  }

  &__subtitle {
    margin-bottom: map-get($v2-spacers, 72);
    font-size: map-get($v2-spacers, 16);
    font-weight: $font-weight-normal;
    text-align: center;
    line-height: map-get($v2-spacers, 22);
    text-align: center;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin-bottom: map-get($v2-spacers, 22);
    padding: 0 map-get($v2-spacers, 24);
  }

  &__button {
    width: 100%;
  }
}
