$card-body-padding-y: map-get($spacers, 23);
$card-body-padding-x: map-get($spacers, 35);

.ride-card-body-separator {
  height: 1px;
  width: calc(100% + #{2 * $card-body-padding-x});
  margin-top: $card-body-padding-y;
  margin-bottom: $card-body-padding-y;
  margin-left: -$card-body-padding-x;
  background-color: $ride-palette-grey-300;
}
