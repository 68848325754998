.ride-body {
  background-color: $ride-palette-grey-100;
  color: $ride-palette-black;
  display: flex;
  flex-direction: column;

  &.full-height {
    min-height: vh-fix(100, -11.5rem);
  }
}
