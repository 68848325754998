.migrate-admin-service-choose-package-card {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .product-section-card {
    max-width: unset;
    background: $neutral-50;
    border: 0;
  }
}
