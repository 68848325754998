.profile-details {
  margin-top: map-get($spacers, 45);

  @include media-breakpoint-up(lg) {
    margin-top: -#{map-get($spacers, 45)};
  }

  &__email {
    @include h8();
  }

  &__email-container {
    .alert-message {
      margin-top: map-get($spacers, 35);
    }
  }

  &__email-edit {
    display: block;
    text-align: right;

    @include media-breakpoint-up(md) {
      display: inline-block;
      float: right;
    }
  }

  .ride-card-bis {
    margin-bottom: map-get($spacers, 5);
  }
}
