.low-tax-broker-dashboard {
  width: 47.1875rem;
  margin: map-get($v2-spacers, 56) auto map-get($v2-spacers, 56);

  .ride-banner {
    margin-bottom: map-get($v2-spacers, 32);
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 12);
    font-weight: $font-weight-bold;
  }

  &__section + &__section {
    margin-top: map-get($v2-spacers, 44);
  }

  &__ibkr-access-info {
    display: flex;
    flex-direction: column;
  }
}
