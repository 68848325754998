.club-deal-details-card {
  display: flex;
  flex-direction: column;
  background-color: $ride-palette-white;
  padding-top: 22px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 40px;

  &__title-container {
    margin-bottom: 20px;
  }

  @mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-container {
    position: relative;
    margin-bottom: 25px;
    @include aspect-ratio(305, 192);

    &--no-image {
      background-image: url("/assets/investments/real-estate-large-placeholder.svg");
      background-color: $ride-palette-grey-100;
      background-position: center center;
      background-size: 300px;
      background-repeat: no-repeat;
    }
  }

  &__description {
    @include h8-light();
    margin-bottom: 20px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }

  &__overlay {
    position: absolute;
    overflow: visible;
    z-index: 1;
    margin-left: auto;
    top: 14px;
    right: 16px;
  }

  &__title-text {
    @include h4();
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__field-name {
    @include h8-light();
    color: $ride-palette-grey-600;
    margin-right: 0.25rem;
  }

  &__field-value {
    @include h8-light();
  }

  &__completion {
    @include h11-light();
    background-color: $ride-palette-black;
    border-radius: 3px;
    color: $success;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;

    &__fully-funded {
      background-color: $ride-palette-pale-cyan;
      color: $ride-palette-black;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 728px;
    border-radius: 10px;
    padding-top: 25px;
    padding-bottom: 55px;

    &__image-container {
      @include aspect-ratio(658, 294);
    }

    &__fields {
      display: flex;
      flex-direction: row;
    }

    &__field {
      margin-right: 30px;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      margin-top: 55px;
    }

    &__title-container {
      margin-bottom: 30px;
    }

    &__title-text {
      @include h2();
      font-size: 36px;
    }
  }
}
