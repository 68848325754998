.task-container {
  background-color: $white;
  max-width: 650px;

  @include media-breakpoint-up(md) {
    padding: map-get($spacers, 4) map-get($spacers, 47) map-get($spacers, 5);
  }

  &__header {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    @include h4();
    margin-top: map-get($spacers, 0);
    margin-bottom: map-get($spacers, 35);
    color: $ride-palette-black;
    line-height: 1.33;

    @include media-breakpoint-up(md) {
      @include h2();
      margin-bottom: map-get($spacers, 47);
    }
  }

  &__icon-wrapper {
    text-align: center;
    margin-bottom: map-get($spacers, 47);

    @include media-breakpoint-up(md) {
      float: right;
    }
  }

  &__icon {
    width: 65px;
    height: 65px;

    &--large {
      width: 100px;
      height: 100px;
    }
  }

  &__description {
    @include h8-light();
    margin-bottom: map-get($spacers, 4);
    color: $ride-palette-black;
    line-height: 1.45;
    white-space: pre-line;

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($spacers, 47);
      line-height: 1.6;
    }

    a {
      color: $ride-palette-black;
      text-decoration: underline;
    }
  }

  &__buttons {
    text-align: center;
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  &__completed-label {
    @include h4();
    color: $ride-palette-pale-cyan;

    svg {
      vertical-align: text-bottom;
    }
  }

  &__button-completed-container {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  &__button-completed {
    @include h8-light();
    color: $ride-palette-grey-600;
    text-decoration: underline;

    &:hover {
      color: $ride-palette-grey-600;
      text-decoration: none;
    }
  }
}
