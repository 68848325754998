.upload-document {
  &__text {
    font-size: 0.75rem;
    font-weight: 400;
    color: $black;
  }

  &__folder-select {
    &__label {
      font-size: 0.875rem;
      font-weight: 800;
      overflow-wrap: anywhere;

      &--renamed {
        font-size: 0.875rem;
        font-weight: 800;
        overflow-wrap: anywhere;
        color: $ride-palette-bright-red;
      }
    }
  }
}
