.page-layout {
  &__header {
    max-width: 1216px;
    margin: 55px auto 0;
  }

  &__children {
    padding-top: 45px;
    padding-bottom: 130px;
    margin-top: 55px;

    &-container {
      max-width: 1216px;
      margin: 0 auto;
    }

    &--grey {
      background: $ride-palette-grey-100;
    }

    &--white {
      background: $ride-palette-white;
    }
  }

  &__title {
    font-size: 3.125rem;
    color: $ride-palette-black;
    background: none;
    margin-bottom: 40px;
    margin-top: 0;
    padding-top: 0;
  }
}
