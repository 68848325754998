.short-order-form-partners {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-size: 14px;
  font-style: normal;
  color: $text-icon-disabled-black;
  font-weight: $ds-font-weight-light;
  line-height: 1.25rem;
  text-align: center;

  &__logos {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin: auto;
  }

  &__interactive-brokers {
    background: url("/assets/interactive-brokers.svg");
    width: 162px;
    height: 24px;
    flex-shrink: 0;
  }

  &__fx-flat {
    background: url("/assets/fx-flat.svg");
    width: 89px;
    height: 24px;
    flex-shrink: 0;
  }
}
