.percentage-input-card {
  margin-bottom: map-get($v2-spacers, 24);
  border: 1px solid $ride-palette-grey-300;
  padding: map-get($v2-spacers, 18) map-get($v2-spacers, 24) map-get($v2-spacers, 18)
    map-get($v2-spacers, 24);
  background-color: white;
  border-radius: map-get($v2-spacers, 12);

  .col {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__type {
    color: $ride-extra-light-grey;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: map-get($v2-spacers, 20);
    margin-bottom: map-get($v2-spacers, 4);
  }

  &__name {
    color: $ride-extra-dark-grey;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    line-height: map-get($v2-spacers, 28);
  }
}
