.entity-general-information {
  &__title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  &__title-icon {
    margin-right: 1rem;
  }

  &__legal-person-switcher {
    margin-bottom: 1.875rem;
  }
}
