.ride-multi-select {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  gap: map-get($v2-spacers, 22);

  &--1-col {
    --number-of-columns: 1;
  }

  &--2-col {
    --number-of-columns: 2;
  }
}
