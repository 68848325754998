.ride-radio-select {
  &__label {
    display: block;
    flex-grow: 0;
    margin-bottom: map-get($v2-spacers, 8);

    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 22);
  }

  &--1-col {
    .ride-radio-select__options-container {
      --number-of-columns: 1;
    }
  }

  &--2-col {
    .ride-radio-select__options-container {
      --number-of-columns: 2;
    }
  }

  &--heavy {
    .ride-radio-select__options-container {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(var(--number-of-columns), 1fr);
      gap: map-get($v2-spacers, 22);
    }

    .ride-radio-select__option {
      background: $ride-palette-grey-100;
      border-radius: map-get($v2-spacers, 12);
      margin: 0;
      padding: map-get($v2-spacers, 24);
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;

      &__label {
        font-weight: $font-weight-medium;
        font-size: map-get($v2-spacers, 16);
        line-height: map-get($v2-spacers, 22);
        color: $ride-palette-black;
      }

      input[type="radio"] {
        appearance: none;
        margin-right: map-get($v2-spacers, 18);

        background-color: $ride-palette-grey-300;
        min-width: map-get($v2-spacers, 28);
        min-height: map-get($v2-spacers, 28);

        border-radius: 50%;

        cursor: pointer;

        &:checked {
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: " ";
            display: block;
            width: map-get($v2-spacers, 22);
            height: map-get($v2-spacers, 22);
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("/assets/sharedComponents/check-icon-centered.svg");
          }
        }
      }

      &:active {
        transform: scale(0.96);
      }

      &:hover {
        -webkit-box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
        -moz-box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
        box-shadow: inset 0 0 0 1px $ride-palette-grey-300;
      }
    }
  }

  &--light {
    .ride-radio-select__options-container {
      display: flex;
      flex-direction: row;
    }

    .ride-radio-select__option {
      margin: 0;
      padding: 0;

      display: flex;
      align-items: flex-start;

      cursor: pointer;

      &__label {
        color: $ride-palette-black;
        font-weight: $font-weight-normal;
        font-size: map-get($v2-spacers, 16);
        line-height: map-get($v2-spacers, 24);
      }

      input[type="radio"] {
        appearance: none;

        width: map-get($v2-spacers, 24);
        height: map-get($v2-spacers, 24);

        border: 1px solid $ride-palette-grey-200;
        border-radius: 50%;

        margin-right: map-get($v2-spacers, 10);

        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &:hover {
          background-color: $ride-palette-grey-100;
        }

        &:checked {
          background-color: $ride-palette-black;

          &::after {
            content: " ";
            display: block;
            width: map-get($v2-spacers, 10);
            height: map-get($v2-spacers, 10);
            border-radius: 50%;
            background-color: $ride-palette-green;
          }
        }
      }
    }

    .ride-radio-select__option + .ride-radio-select__option {
      margin-left: map-get($v2-spacers, 16);
    }
  }
}
