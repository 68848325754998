.third-party-tax-advisor-address {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  &__submit-button {
    width: 100%;
    margin-top: map-get($v2-spacers, 48);
  }
}
