@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.ride-navigation__topbar {
  background-color: $ride-palette-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &-hamburger {
    color: $ride-palette-black;

    .hamburger-inner {
      &,
      &:after,
      &:before {
        height: 0.21875rem;
        background-color: $text-icon-primary-black !important;
      }
    }
  }

  &-header {
    height: $ride-header-height;
  }

  &-menu {
    background-color: $ride-palette-white;
    width: 100%;
    height: calc(100vh - #{$ride-header-height});
    position: absolute;
    overflow-y: scroll;
    animation: slideInFromRight 0.2s linear forwards;
  }
}

.ride-navigation__topbar-placeholder {
  height: $ride-header-height;
}
