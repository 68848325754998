.payment-option {
  display: flex;
  flex-direction: column;
  border: 1px solid $neutral-200;
  border-radius: map-get($v2-spacers, 8);
  background-color: $neutral-0;
  padding: map-get($v2-spacers, 16);
  cursor: pointer;

  &--selected {
    border: 1px solid $primary-success-700;
    outline: 1px solid $primary-success-700;
  }

  &__top-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &--with-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__input {
    display: flex;
    justify-content: flex-end;
  }

  &__labels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 0.25rem;
    vertical-align: center;
  }
}
