.profile-referral-code {
  &__label-container,
  &__value {
    margin-bottom: map-get($spacers, 3);
  }

  &__label,
  &__value,
  &__uses-title {
    @include h8();
  }

  &__uses-title {
    margin-bottom: map-get($spacers, 3);
  }

  &__description {
    @include h8-light();
    color: $ride-palette-grey-600;
  }

  &__action {
    position: relative;
    text-align: right;
    margin-bottom: map-get($spacers, 3);
  }

  @include media-breakpoint-up(md) {
    &__info {
      display: flex;
      justify-content: space-between;
    }

    &__value-container {
      width: 95%;
      text-align: right;
    }

    &__value {
      margin-right: map-get($spacers, 3);
    }

    &__value,
    &__action {
      display: inline-block;
    }
  }
}
