$v2-border-radius-md: 12px;
$v2-border-radius-circle: 50%;

$v2-border-color: #ececec;

$v2-spacers: () !default;
// If you wish to add a spacer here, please talk to our designer
$v2-spacers: map-merge(
  (
    2: $spacer * 0.125,
    4: $spacer * 0.25,
    6: $spacer * 0.375,
    8: $spacer * 0.5,
    10: $spacer * 0.625,
    12: $spacer * 0.75,
    14: $spacer * 0.875,
    16: $spacer * 1,
    18: $spacer * 1.125,
    20: $spacer * 1.25,
    22: $spacer * 1.375,
    24: $spacer * 1.5,
    26: $spacer * 1.625,
    28: $spacer * 1.75,
    30: $spacer * 1.875,
    32: $spacer * 2,
    36: $spacer * 2.25,
    40: $spacer * 2.5,
    44: $spacer * 2.75,
    48: $spacer * 3,
    52: $spacer * 3.25,
    56: $spacer * 3.5,
    60: $spacer * 3.75,
    64: $spacer * 4,
    68: $spacer * 4.25,
    72: $spacer * 4.5,
    80: $spacer * 5,
    88: $spacer * 5.5,
    90: $spacer * 5.625,
    96: $spacer * 6,
    100: $spacer * 6.25,
    104: $spacer * 6.5,
    112: $spacer * 7,
    120: $spacer * 7.5,
    128: $spacer * 8,
    136: $spacer * 8.5,
    144: $spacer * 9,
    156: $spacer * 9.75
  ),
  $v2-spacers
);
