.tile-admin-service {
  font-size: 0.8rem;

  &__link-title {
    font-weight: 800;
    color: $dark;
  }

  &__link-continue {
    color: $dark;
    text-decoration: underline;
    font-weight: 400;
    padding-left: 0.75rem;

    a {
      text-decoration: none;
      color: $dark;
    }

    &:hover {
      color: $ride-palette-black;
      font-weight: 800;
    }
  }

  &__link-cancel {
    color: $ride-palette-grey-600;
    text-decoration: underline;
    font-weight: 400;
    padding-left: 0.75rem;
    cursor: pointer;

    &:hover {
      font-weight: 800;
    }
  }
}
