.guest-header {
  padding: map-get($v2-spacers, 16) map-get($v2-spacers, 48);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $neutral-200;

  @include media-breakpoint-down(md) {
    padding: map-get($v2-spacers, 16);
    align-items: center;

    .ride-logo-black {
      height: 1.5rem;
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    gap: map-get($v2-spacers, 24);

    @include media-breakpoint-down(md) {
      gap: map-get($v2-spacers, 16);
    }
  }
}
