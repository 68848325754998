.alert-bar {
  padding: map-get($v2-spacers, 18);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: map-get($v2-spacers, 10);

  &--rectangle {
    border-radius: 0;
  }

  &--rounded {
    border-radius: map-get($v2-spacers, 8);
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    width: map-get($v2-spacers, 24);
    height: map-get($v2-spacers, 24);
    background: no-repeat center;
    content: "";
    flex-shrink: 0;
    flex-grow: 0;
  }

  &--success {
    background: rgba(158, 248, 188, 0.2);

    .alert-bar__icon {
      background-image: url("/assets/sharedComponents/success-green.svg");
    }
  }

  &--info {
    background: rgba(201, 229, 255, 0.3);

    .alert-bar__icon {
      background-image: url("/assets/sharedComponents/info-blue.svg");
    }
  }

  &--warning {
    background: rgba(253, 249, 232, 0.6);

    .alert-bar__icon {
      background-image: url("/assets/sharedComponents/warning-yellow.svg");
    }
  }

  &--error {
    background: rgba(246, 216, 213, 0.6);

    .alert-bar__icon {
      background-image: url("/assets/sharedComponents/error-red.svg");
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  &__message {
    font-weight: $font-weight-normal;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);

    &--without-title {
      padding-top: 0.125rem;
    }
  }
}
