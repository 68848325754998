.company-selector {
  font-family: $font-family-figtree;

  &__container {
    .dropdown-item {
      color: $text-icon-primary-black;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background: $ride-palette-pastel-light-green;
      color: $ride-palette-dark-green;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      background: $ride-palette-pastel-light-green;
      color: $ride-palette-dark-green;
    }
  }

  &__dropdown {
    background: white;
    border: 1px solid $ride-palette-grey-400;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    min-width: 18rem;
  }
}
