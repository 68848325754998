.external-company-search-input {
  position: relative;

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
  }
}
