.pending-tasks {
  &__all_done {
    @include h5();
    color: $ride-palette-grey-medium-dark;
    text-align: center;
    padding: 1em;
  }

  &__count {
    @include h5();
    padding-left: 0.5em;
  }

  &__item {
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
  }
}
