.iban-view-task {
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 53px;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &__value {
    @include h8-light();
    color: $ride-palette-black;
  }

  &__iban {
    @include h8-light();
    color: $ride-palette-grey-600;
  }

  &__action {
    position: relative;
    text-align: right;
    flex-grow: 1;
  }
}
