.low-tax-broker-order-details {
  &__convert-order-section {
    margin-bottom: 1rem;
  }

  &__convert-order-title {
    display: inline-block;
    margin-right: 1rem;
  }

  &__convert-order-button {
    display: inline-block;
  }

  &__update-order-status-row {
    display: flex;
    flex-direction: row;
    margin-bottom: map-get($v2-spacers, 20);
    align-items: flex-end;
  }

  &__dashboard-status {
    display: flex;
    flex-direction: row;
    gap: map-get($v2-spacers, 4);
    margin-bottom: map-get($v2-spacers, 12);
  }

  .ride-dropdown-select {
    width: 100%;
    padding-right: map-get($v2-spacers, 8);
  }

  .update-status-button {
    height: 50%;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin-top: map-get($v2-spacers, 20);
    align-items: center;
  }

  &__hint {
    padding-right: 1rem;
  }

  &__ib-credentials {
    padding: 5px;
  }

  &__overview-statuses-fields {
    margin-top: map-get($v2-spacers, 24);
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 22);
  }

  &__iban-edit-card {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__field {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}
