.unavailable-club-deals {
  background-color: $ride-palette-grey-100;

  &__wrapper {
    max-width: 305px;
    margin: auto;
  }

  padding-top: 30px;

  &__title {
    padding-bottom: 20px;
    font-size: 16px;
    color: $ride-palette-extra-dark-grey;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 70px;

    .club-deal-card {
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    margin: auto;
    width: 745px;

    &__wrapper {
      max-width: 715px;
      margin-left: 35px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 70px;

      .club-deal-card {
        margin-bottom: 20px;
        margin-right: 25px;
      }
    }
  }
}
