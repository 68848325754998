.error-file-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: map-get($v2-spacers, 14) map-get($v2-spacers, 24);
  background: rgba(255, 91, 91, 0.1);
  border-radius: map-get($v2-spacers, 12);

  .ride-icon {
    background-color: transparent;
  }

  &__error-icon .ride-icon {
    width: map-get($v2-spacers, 24);
    height: map-get($v2-spacers, 24);
  }

  &__error-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-left: map-get($v2-spacers, 16);
  }

  &__description {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    color: $ride-palette-black;
  }

  &__file-name {
    font-weight: $font-weight-normal;
    font-size: map-get($v2-spacers, 12);
    line-height: map-get($v2-spacers, 18);
    color: $ride-palette-grey-600;
  }

  &__error-close {
    &:hover {
      cursor: pointer;
    }
  }
}
