.personal-information-step {
  &__personal-data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__name-fields {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }

  &__input {
    flex-grow: 1;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-top: 1px solid $neutral-100;
    padding-top: 1.5rem;

    .text-link--SECONDARY {
      color: $accent-700;
    }

    .ride-checkbox__label {
      font-size: 1rem;
      font-weight: $ds-font-weight-light;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    .ride-checkbox__images {
      width: 1.5rem;
      height: 1.5rem;
    }

    .ride-checkbox__input + .ride-checkbox__images span {
      color: $text-icon-primary-black;
    }
  }
}
