.confirmation-modal {
  .modal-dialog,
  .modal-content {
    border-radius: $border-radius-md;
  }

  .modal-dialog {
    &--large {
      max-width: 702px !important;
    }
  }

  &__title {
    @include h4();

    margin-top: map-get($map: $spacers, $key: 4);
    text-align: center;
    flex: 1;
    color: $ride-palette-black;

    &-with-close {
      padding-left: calc(2rem + 23px);
    }

    @include media-breakpoint-up(md) {
      @include h6();
    }
  }

  &__body {
    text-align: center;
  }

  &__button {
    display: inline-block;
  }

  &__button + &__button {
    margin-left: map-get($map: $spacers, $key: 4);
  }

  &__modal-buttons {
    margin-top: map-get($map: $spacers, $key: 48);
    margin-bottom: map-get($map: $spacers, $key: 6);

    @include media-breakpoint-up(md) {
      margin-bottom: map-get($map: $spacers, $key: 48);
    }
  }

  &__radios {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 0 1.5rem;
    }
  }

  &__radio {
    padding-bottom: 1.5rem;
  }
}
