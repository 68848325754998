.ride-dropdown-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &--error {
    .ride-dropdown-select__select__control {
      border-color: $ride-palette-red !important;
    }
  }

  &__label {
    display: block;
    flex-grow: 0;
    margin-bottom: map-get($v2-spacers, 8);

    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 22);
  }

  &__select {
    &__control {
      border-radius: map-get($v2-spacers, 12) !important;
      border: 1px solid $ride-palette-grey-300 !important;

      height: map-get($v2-spacers, 48) !important;

      padding-top: map-get($v2-spacers, 12) !important;
      padding-bottom: map-get($v2-spacers, 12) !important;
      padding-left: map-get($v2-spacers, 16) !important;

      font-size: map-get($v2-spacers, 16) !important;
      font-weight: $font-weight-bold;
      line-height: map-get($v2-spacers, 22) !important;

      flex-wrap: nowrap !important;

      &--is-focused {
        border-color: $ride-palette-black !important;
        box-shadow: none !important;
      }
    }

    &__value-container {
      padding: 0 !important;
      margin: 0 !important;

      height: map-get($v2-spacers, 22) !important;
      top: 0 !important;
      flex-wrap: nowrap !important;
    }

    &__single-value {
      padding: 0 !important;
      margin: 0 !important;
      top: map-get($v2-spacers, 12) !important;
    }

    &__placeholder {
      margin: 0 !important;
      color: $ride-palette-grey-600 !important;
      font-weight: $font-weight-medium !important;
    }

    &__input {
      input {
        font-size: map-get($v2-spacers, 16) !important;
        font-weight: $font-weight-bold !important;
        line-height: map-get($v2-spacers, 22) !important;
      }
    }

    &__menu {
      font-size: map-get($v2-spacers, 16) !important;
      font-weight: $font-weight-bold !important;
    }
  }
}
