.breakpoint-down {
  display: none;

  &--xs {
    @include media-breakpoint-down(xs) {
      display: contents;
    }
  }

  &--sm {
    @include media-breakpoint-down(sm) {
      display: contents;
    }
  }

  &--md {
    @include media-breakpoint-down(md) {
      display: contents;
    }
  }

  &--lg {
    @include media-breakpoint-down(lg) {
      display: contents;
    }
  }

  &--xl {
    @include media-breakpoint-down(xl) {
      display: contents;
    }
  }
}
