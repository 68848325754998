.ride-badge-v2 {
  display: flex;
  align-items: baseline;
  width: fit-content;
  border-radius: 12px;
  padding: 4px 8px;
  gap: 4px;

  /* Label/300 */
  font-family: $font-family-figtree;
  font-size: 12px;
  //font-style: normal;
  font-weight: $ds-font-weight-heavy;
  line-height: 12px;
  /* 100% */
  letter-spacing: 0.24px;
  text-transform: capitalize;

  &--OCRE {
    background: $warning-100;
    color: $warning-700;
  }

  &--GREEN {
    background: $primary-success-100;
    color: $primary-success-700;
  }

  &--PURPLE {
    background: $accent-100;
    color: $accent-700;
  }

  &--RED {
    background: $danger-100;
    color: $danger-700;
  }

  &__icon {
    width: 8px;
    height: 8px;
    margin: 4px;
    display: flex;
    align-items: center;

    &--OCRE {
      circle {
        fill: $warning-700;
      }
    }

    &--GREEN {
      circle {
        fill: $primary-success-700;
      }
    }

    &--PURPLE {
      circle {
        fill: $accent-700;
      }
    }

    &--RED {
      circle {
        fill: $danger-700;
      }
    }
  }
}
