.update-ibkr-email {
  border-radius: 0.75rem;
  background-color: $neutral-100;
  padding: 1rem;

  display: flex;
  justify-content: stretch;
  flex-direction: column;
  gap: 1.5rem;

  hr {
    width: 100%;
    border-top: 1px solid $neutral-200;
    margin: 0;
  }

  &__step {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;

    & > * {
      flex: 1;
    }
  }
}
