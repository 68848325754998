.profile-password-card {
  &__text {
    @include h8-light();
    color: $ride-palette-grey-600;
  }

  &__edit {
    display: block;
    text-align: right;

    @include media-breakpoint-up(md) {
      display: inline-block;
      float: right;
    }
  }
}
