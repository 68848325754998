.ride-footer {
  color: $info;
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
  }

  a {
    color: $info;
  }
}
