.important-banners-section {
  background-color: $ride-palette-white;
}

.continue-order-banner,
.continue-order-banner {
  margin-top: map-get($v2-spacers, 40);
  margin-bottom: map-get($v2-spacers, 40);
}

.purchase-admin-service,
.wepa-upsell,
.choice-form,
.bank-selection,
.add-existing-account {
  margin-top: map-get($v2-spacers, 52) !important;
  margin-bottom: map-get($v2-spacers, 52);
}

.bank-accounts-list {
  display: flex;
  flex-direction: column;
  margin: map-get($v2-spacers, 52) auto;

  &__add-account-btn {
    align-self: end;
  }
}

.add-bank-account,
.edit-bank-account {
  display: flex;
  flex-direction: column;

  &__close-btn {
    align-self: end;
    margin-top: map-get($v2-spacers, 12);
  }
}

.company-restrictions-to-admin-service {
  display: flex;
  justify-content: center;
  margin: map-get($v2-spacers, 40);
}
