.managing-directors-list {
  &__item {
    position: relative;
  }

  &__wrapper + &__wrapper {
    margin-top: map-get($v2-spacers, 22);
  }

  &__add-icon {
    margin-right: map-get($v2-spacers, 18);

    min-width: map-get($v2-spacers, 28);
    min-height: map-get($v2-spacers, 28);

    border-radius: 50%;

    background: $ride-palette-grey-300 url("/assets/uiLibrary/icons/plus.svg") no-repeat center;
  }

  &__add-button {
    .label {
      font-size: map-get($v2-spacers, 14);
      font-weight: $font-weight-bold;
    }
  }

  &__icons {
    position: absolute;
    top: 0;
    right: map-get($v2-spacers, 24);

    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    gap: map-get($v2-spacers, 8);
  }
}
