.short-order-form-order-summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  // TODO: these are hacks. We have to create a proper Link component
  .text-link--SECONDARY {
    color: $accent-700;
  }

  &__header,
  &__discount-label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__table {
    display: grid;
    grid-template-columns: 12rem auto auto;
    grid-auto-rows: auto;
    justify-content: space-between;
    align-items: baseline;

    @include media-breakpoint-down(md) {
      grid-template-columns: 10rem min-content min-content;
      gap: 0 0.5rem;
    }
  }

  &__table-headers,
  &__table-rows,
  &__table-row {
    display: contents;
  }

  &__table-header {
    margin-bottom: 1rem;

    &:nth-child(3) {
      text-align: right;
    }
  }

  &__table-cell {
    margin-bottom: 0.5rem;

    &:nth-child(3n + 0) {
      text-align: right;
    }
  }

  &__discount-row,
  &__due-amount-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
  }

  &__product-price-cell,
  &__price-cell {
    text-align: right;
  }

  &__discount-row,
  &__due-amount {
    border-top: 1px solid $neutral-200;
    padding-top: 1rem;
  }

  &__discount-value {
    color: $danger-600;
  }

  &__due-amount {
    margin-bottom: 1rem;
  }

  &__due-amount-value-for-ltb {
    width: auto;
    color: $primary-success-600;
  }

  &__due-amount-observation {
    text-align: right;
    color: $text-icon-disabled-black;
  }
}

.wepa-order-summary {
  &__price-cell-link {
    text-decoration: underline;
  }
}
