.upload-controls {
  text-align: center;
  padding-bottom: $spacer * 10;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &__submit-button {
    margin-bottom: $space-48;
  }

  &__cancel-button {
    font-size: 16px;
  }
}
