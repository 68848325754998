.migrate-3pta-success {
  max-width: 47rem;
  margin: 2.125rem auto 4.5rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 16);
  }

  &__button {
    margin-top: map-get($v2-spacers, 48);
  }
}
