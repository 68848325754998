.asset {
  &__entry {
    display: flex;
    flex-direction: row;
    margin-bottom: map-get($v2-spacers, 16);
    justify-content: space-between;
    align-items: center;
    padding: map-get($v2-spacers, 20) map-get($v2-spacers, 24);
    border-radius: map-get($v2-spacers, 12);
    background-color: $ride-palette-grey-100;
    color: $ride-palette-black;
    font-size: 1rem;
    font-weight: bold;
  }
}

.trading-limitations-information {
  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 20);
  border-radius: map-get($v2-spacers, 12);
  border: 1px solid $border-color;
  padding: map-get($v2-spacers, 24);

  &__title {
    color: $ride-palette-black;
    font-weight: $font-weight-bold;
  }

  &__button {
    border-radius: map-get($v2-spacers, 12);
  }
}
