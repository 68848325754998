.order-form {
  color: $ride-palette-black;
  font-size: map-get($v2-spacers, 16);

  &__inputs {
    margin-top: map-get($v2-spacers, 32);
  }

  &__input {
    margin-top: map-get($v2-spacers, 8);

    &--success {
      & input[disabled] {
        border-color: $ride-palette-green !important;
      }
    }

    &--error {
      & input {
        border-color: $ride-palette-red !important;
      }
    }
  }

  &__input-row {
    margin-top: map-get($v2-spacers, 28);
    align-items: flex-end;
  }

  &__section-title {
    margin-top: map-get($v2-spacers, 56);
    font-weight: 500;
    font-size: map-get($v2-spacers, 24);
    line-height: 1;
  }

  &__summary {
    margin-top: map-get($v2-spacers, 32);
  }

  &__summary-time-frame {
    margin-top: map-get($v2-spacers, 12);
    padding-left: map-get($v2-spacers, 30);
    font-size: 1rem;
    line-height: 1.125;
    font-weight: 800;
    display: flex;
    align-items: center;

    &::before {
      content: " ";
      background: url("/assets/uiLibrary/icons/clock.svg") no-repeat center;
      width: map-get($v2-spacers, 24);
      height: map-get($v2-spacers, 24);
      display: inline-block;
      position: absolute;
      margin-left: map-get($v2-spacers, 30) * -1;
      margin-top: -4px;
    }
  }

  @include media-breakpoint-down(md) {
    &__summary-time-frame {
      justify-content: center;

      &::before {
        position: initial;
      }
    }
  }

  &__summary-header {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-bottom: 1px solid $v2-border-color;
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    &__summary-header {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      text-align: center;
    }

    &__summary-title {
      margin-left: 0 !important;
      padding: 0;
    }
  }

  &__summary-title {
    font-size: map-get($v2-spacers, 24);
    line-height: 1.33;
    font-weight: 500;
    margin-left: map-get($v2-spacers, 26);
  }

  &__summary-icon {
    flex: 0 0 map-get($v2-spacers, 80);
    height: map-get($v2-spacers, 80);
    background: $ride-palette-grey-100 url("/assets/uiLibrary/icons/business.svg") no-repeat center;
    background-size: map-get($v2-spacers, 32);
    border-radius: $v2-border-radius-circle;
  }

  &__summary-body {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
  }

  &__summary-footer {
    padding: map-get($v2-spacers, 26) map-get($v2-spacers, 32);
    border-top: 1px solid $v2-border-color;
  }

  &__summary-items {
    padding-left: 0;
    margin-top: map-get($v2-spacers, 16) * -1;
    margin-bottom: 0;
  }

  &__summary-item {
    list-style: none;
    padding-left: map-get($v2-spacers, 36);
    margin-top: map-get($v2-spacers, 16);
    font-weight: 800;

    &::before {
      content: " ";
      background: url("/assets/uiLibrary/icons/check.svg") no-repeat center;
      width: map-get($v2-spacers, 24);
      height: map-get($v2-spacers, 24);
      display: inline-block;
      position: absolute;
      margin-left: map-get($v2-spacers, 36) * -1;
    }
  }

  &__summary-price {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.3;
    color: $ride-palette-green;
  }

  &__summary-small-print {
    margin-top: map-get($v2-spacers, 8);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &__divider {
    margin-top: map-get($v2-spacers, 40);
  }

  &__divider-2 {
    margin-top: map-get($v2-spacers, 24);
  }

  &__divider-3 {
    margin-top: map-get($v2-spacers, 20);
  }

  &__discount-code {
    margin-top: map-get($v2-spacers, 40);
  }

  &__price-information-row {
    margin-top: map-get($v2-spacers, 18);
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &__price-information {
    margin-top: map-get($v2-spacers, 24);
  }

  &__total-price {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.3;
  }

  &__checkbox {
    margin-top: map-get($v2-spacers, 18);
  }

  &__checkboxes {
    margin-top: map-get($v2-spacers, 30);

    // TODO: these are hacks. We have to create a proper V2 component for checkbox
    .ride-checkbox__label {
      font-size: map-get($v2-spacers, 14);
    }

    .ride-checkbox__images {
      height: 20px;
      width: 20px;
    }
  }

  &__submit-button {
    margin-top: map-get($v2-spacers, 36);
    width: 100%;
  }
}
