.tax-advisor-section {
  @include shadow3();

  background: $ride-palette-white;
  margin-top: 2em;

  & + .tax-advisor-section {
    margin-top: 4.25em;
  }

  &__header {
    padding: 1.5em 1.75em;
  }

  &__header + hr {
    margin: 0;
  }

  &__title {
    &:last-child {
      margin: 0;
    }

    a {
      color: $ride-palette-dark-green;
    }
  }

  .ride-table-cell {
    padding-right: 1em;
  }

  &__brokerage-accounts-pill {
    display: block;
    margin-left: -0.75em;
    padding-left: 0.9em;
    padding-right: 0.9em;
    border-radius: 0.9em;
  }

  &__brokerage-accounts-pill + &__brokerage-accounts-pill {
    margin-top: 0.5em;
  }

  &__no-data {
    padding: 23px 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include h9-light();
    color: $ride-extra-light-grey;
  }
}
