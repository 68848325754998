.fiscal-year-starting-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: $neutral-50;
  padding: map-get($v2-spacers, 24);
  border-radius: map-get($v2-spacers, 12);
  gap: map-get($v2-spacers, 32);

  &__fields {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    gap: map-get($v2-spacers, 16);

    > .ride-dropdown-select {
      width: 100%;
    }
  }

  &__button {
    width: 100%;
  }
}
