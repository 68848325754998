.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: vh-fix(100);
  background-color: $ride-palette-grey-100;
  background-image: url("/assets/pattern.svg");
  background-position: top center;

  &__container {
    flex: 1 1 auto !important;
  }

  &__header {
    margin: map-get($spacers, 47) auto;
    margin-bottom: 0;
    text-align: center;
  }

  &__logo {
    height: map-get($spacers, 45);
    margin-bottom: map-get($spacers, 47);
  }

  &__children {
    display: flex;
    justify-content: center;
    margin-bottom: map-get($spacers, 6);
  }
}
