.lei-application {
  margin-top: 3rem;

  .alert-bar__text-container {
    position: relative;
    flex-grow: 1;
  }

  &__value {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__cta {
    max-width: 200px;
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    &__cta {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__content {
      max-width: 400px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      max-width: 650px;
    }
  }
}
