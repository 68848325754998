.ride-table-actions {
  margin-bottom: 15px;
  &-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 55px;
    height: 0;
    &--with-warning {
      bottom: 130px;
    }
  }

  &-container {
    padding: 0 30px;
    position: relative;
    bottom: 15px;
  }

  &-panel {
    bottom: 0;
    width: 100%;
    height: 70px;
    padding: 24px 25px;
    background: $success;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
  }
}
