.full-screen-modal {
  width: 100vw;
  min-height: vh-fix(100);
  max-width: initial;
  max-height: initial;
  margin: 0;
  display: flex;

  .close {
    @include media-breakpoint-up(lg) {
      margin-right: 5.5rem;
      margin-top: -0.5rem;
    }
  }

  .modal-header {
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
    width: 100%;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 2.3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 2.25rem;
    }
  }

  &__body {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    max-width: 800px;

    @include media-breakpoint-up(md) {
      background-color: $ride-palette-grey-100;
      border-radius: 10px;
    }
  }

  &__controls {
    max-width: 37rem;
    padding-top: 4rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__narrow-container {
    max-width: 305px;
    margin-left: auto;
    margin-right: auto;
  }

  &--dark {
    .modal-content {
      background-color: $ride-palette-black;
    }

    .close {
      color: $ride-palette-grey-100;
    }
  }
}
