%client-input {
  height: 3rem;
  font-size: 1rem;
  border: 1px solid $ride-input-border-color;
  border-radius: 12px;
  outline: none;
}

%client-non-select-input {
  box-shadow: none !important; // !important only to override the bootstrap CSS
}

.client-input {
  &__label {
    @include h9();
  }

  &__input {
    @extend %client-input;
    @extend %client-non-select-input;
    padding: 0.75rem 1rem;

    &:focus,
    &:active {
      border-width: 2px;
      border-color: $ride-input-border-color-focus;
    }

    &--error {
      border-width: 2px;
      border-color: $ride-palette-bright-red;
    }
  }

  &__error {
    @include h10();
    color: $ride-palette-bright-red;
  }
}
