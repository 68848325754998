.admin-service-package-update-success {
  max-width: 47rem;
  margin: 2.125rem auto 4.5rem;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  &__message {
    color: $text-icon-secondary-black;
    white-space: break-spaces;
  }
}
