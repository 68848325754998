.add-existing-gmbh {
  max-width: 550px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;

  @include media-breakpoint-up(md) {
    max-width: 720px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1100px;
  }
}
