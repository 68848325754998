// Check .upgrade-admin-service-to-select-tier-banner for design system
.pta-switch-offer-partner-banner {
  margin-top: 3rem;
  padding: map-get($v2-spacers, 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: map-get($v2-spacers, 4);
  background: $informational-100;
  border-radius: map-get($v2-spacers, 4);

  &__title {
    font-weight: $font-weight-bold;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__button {
    cursor: pointer;
    min-width: 8rem;
  }
}
