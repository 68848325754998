.short-order-form-checkout-step {
  &__your-order {
    align-self: stretch;
  }

  &__your-order-header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  &__name-fields {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__selected-products {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
  }

  &__price-summary {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-top: 1px solid $neutral-100;

    &-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__discount-code {
    align-self: stretch;
  }

  &__checkboxes {
    border-top: 1px solid $neutral-200;
    width: 100%;
    padding-top: map-get($v2-spacers, 16);

    // TODO: these are hacks. We have to create a proper V2 component for checkbox
    .text-link--SECONDARY {
      color: $accent-700;
    }

    .ride-checkbox__label {
      font-size: map-get($v2-spacers, 16);
      font-weight: $ds-font-weight-light;
      line-height: map-get($v2-spacers, 24);
      margin-bottom: map-get($v2-spacers, 16);
    }

    .ride-checkbox__images {
      width: map-get($v2-spacers, 24);
      height: map-get($v2-spacers, 24);
    }

    .ride-checkbox__input + .ride-checkbox__images span {
      color: $text-icon-primary-black;
    }
  }

  .ride-input,
  .ride-dropdown-select {
    margin-bottom: map-get($v2-spacers, 16);
  }

  .order-form__discount-code,
  .order-form__input-row {
    margin-top: 0;

    .ride-button-v2--BIG {
      min-width: map-get($v2-spacers, 144);
      max-width: map-get($v2-spacers, 144);
    }

    .order-form__input {
      margin-bottom: 0;
    }
  }
}
