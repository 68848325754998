.admin-service-onboarding-thank-you-page {
  display: flex;
  flex-direction: column;
  width: 47rem;
  margin: 3rem auto;

  &__title {
    margin-bottom: 0.5rem;
  }

  &__button {
    margin-top: 3rem;
    align-self: end;
    width: 18rem;
  }
}
