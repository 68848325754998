.social-icon {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &-linkedin {
    background-image: url("/assets/client/social/linkedin.svg");
  }

  &-facebook {
    background-image: url("/assets/client/social/facebook.svg");
  }

  &-twitter {
    background-image: url("/assets/client/social/twitter.svg");
  }

  &-youtube {
    background-image: url("/assets/client/social/youtube.svg");
  }

  &-instagram {
    background-image: url("/assets/client/social/instagram.svg");
  }
}
