.lost-password-success {
  text-align: center;
  width: 306px;
  margin: auto;

  &__success-message {
    @include h5();
    color: $ride-soft-orange;
    padding-top: map-get($spacers, 14);
    padding-bottom: map-get($map: $spacers, $key: 405);
  }

  &__success-note {
    @include h8-light();
    color: $ride-palette-grey-600;
  }
}
