.folder-breadcrumb {
  font-size: smaller;

  &__separator::before {
    content: "/";
  }

  &__separator {
    margin-left: 4px;
    margin-right: 4px;
  }
}
