.step-progress-bar {
  display: flex;
  flex-direction: column;

  &__item {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: map-get($v2-spacers, 16);
  }

  &__bullet {
    display: flex;
    width: map-get($v2-spacers, 24);
    height: map-get($v2-spacers, 24);
    color: $text-icon-primary-white;
    background-color: $neutral-200;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    font-weight: $ds-font-weight-heavy;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 16);

    &--enabled {
      background-color: $primary-success-500;
    }
  }

  &__label {
    &--disabled {
      color: $neutral-600;
    }

    &--active {
      color: $text-icon-primary-black;
    }

    &--complete {
      color: $text-icon-primary-black;
    }
  }

  &__bar-inbetween {
    z-index: 0;
    width: 0;
    height: map-get($v2-spacers, 56);
    background-color: $neutral-200;
    outline: 1px solid $neutral-200;
    position: relative;
    left: 0.75rem;

    &--complete {
      background-color: $primary-success-700;
      outline: 1px solid $primary-success-700;
    }
  }
}

.step-progress-bar--horizontal {
  @include media-breakpoint-down(md) {
    flex-direction: row;
    align-items: center;

    .step-progress-bar__label {
      display: none;
    }

    .step-progress-bar__bar-inbetween {
      position: unset;
      left: unset;
      height: 0;
      width: 6rem;
      flex-shrink: 1;
    }
  }
}
