.node-tree {
  $mobile-padding: 20px;
  $desktop-padding: 30px;
  $indentation-size: 30px;
  $toggle-icon-span-size: $indentation-size;

  @mixin node-tree-indentation($extra-file-indentation: 0) {
    &--level-0 {
      padding-left: 20px;

      @include media-breakpoint-up(md) {
        padding-left: $desktop-padding;
      }
    }

    @for $i from 1 through 5 {
      &--level-#{$i} {
        padding-left: 20px + $extra-file-indentation + $indentation-size * $i;

        @include media-breakpoint-up(md) {
          padding-left: $extra-file-indentation + $indentation-size + $indentation-size * $i;
        }
      }
    }
  }

  &__operation-wrapper {
    &--first {
      display: flex;
      flex: auto;
      align-items: center;
    }
  }

  &__upload {
    float: right;
    color: $ride-palette-green;
    cursor: pointer;

    &:hover svg {
      color: $ride-palette-dark-green !important;
    }
  }

  &__folder-label {
    @include node-tree-indentation();
    @include h8();
    color: $ride-palette-black;
    line-height: 32px;
    cursor: pointer;
    padding-right: $mobile-padding;

    @include media-breakpoint-up(md) {
      padding-right: $desktop-padding;
    }

    > svg {
      color: $ride-palette-green;
      margin-right: 5px;
      vertical-align: text-bottom;
    }

    .button-expand {
      padding-right: 5px;
      vertical-align: text-bottom;
      width: $indentation-size;

      svg {
        color: $ride-palette-grey-600 !important;
      }
    }

    &--hover-with-file {
      border: solid 3px $ride-palette-green;
      background-color: $ride-palette-light-green;
    }

    &--empty {
      cursor: default;

      .void-icon {
        display: inline-block;
        padding-left: $indentation-size;
      }
    }

    + .node-tree__file {
      border-top: 1px solid $ride-palette-grey-100;
    }
  }

  &__file-label {
    @include node-tree-indentation($indentation-size);
    @include h8-light();
    line-height: 32px;
  }

  &__folder-label,
  &__file-label {
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
  }

  &__file-name {
    cursor: pointer;
    margin-right: map-get($spacers, 21);
  }

  &__file-name-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:hover {
      .node-tree__file-operation {
        display: block;
      }
    }
  }

  &__empty-label {
    @include h8-light();
    color: $ride-palette-grey-600;
    padding-left: 5px;
  }

  &__file-operation {
    display: none;
    cursor: pointer;
    width: 24px;
    height: 100%;
    float: right;
    vertical-align: text-bottom;
    background-position: center center;
    background-repeat: no-repeat;
    color: $ride-palette-grey-600;
    margin-right: $mobile-padding;

    @include media-breakpoint-up(md) {
      margin-right: $desktop-padding;
    }

    &--delete {
      :hover {
        color: $ride-palette-red;
      }
    }

    &--download {
      :hover {
        color: $ride-palette-green;
      }
    }
  }

  &__folder,
  &__file {
    & + & {
      border-top: 1px solid $ride-palette-grey-200;
    }

    &:not(.ground-level) + &:not(.ground-level) {
      border-top: 1px solid $ride-palette-grey-100;
    }
  }

  &__file {
    &:hover {
      background: $ride-palette-tilt;
    }
  }
}
