.wepa-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__small {
    font-size: 10px;
    overflow-wrap: anywhere;
  }

  &__title {
    min-width: 50%;
    max-width: 50%;
  }

  &__sync-wepa-company {
    float: right;
  }

  &__sync-button {
    margin: 0.5em auto 0 2em;
    float: right;
    vertical-align: center;
  }
}
