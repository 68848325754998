.bank-account-details {
  background-color: $ride-palette-black;
  padding-top: 1.5rem;
  padding-bottom: map-get($spacers, 3);

  &__first-line {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: map-get($spacers, 3);
    margin-top: map-get($spacers, 3);
  }

  &__bank-account-name {
    @include h3();
    color: $ride-palette-white;
    margin-left: map-get($spacers, 21);

    @include media-breakpoint-up(lg) {
      @include h1();
    }
  }

  &__properties {
    @include media-breakpoint-up(lg) {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: map-get($spacers, 3);
      margin-top: map-get($spacers, 3);
    }
  }

  &__property-value {
    color: $ride-palette-white;
    &--balance {
      @include h3-light();
      line-height: 1.1;
    }

    &--company-name {
      margin-top: map-get($spacers, 23);
    }
  }

  &__property {
    @include h9-light();
    line-height: 1.5;
    padding-bottom: map-get($spacers, 3);
    padding-top: map-get($spacers, 3);
    border-top: 1px solid $ride-palette-grey-900;
    color: $ride-extra-light-grey;

    @include media-breakpoint-up(lg) {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // align-items: flex-start;
      border-top: none;
      padding: 0 30px;

      & + & {
        border-left: 1px solid $ride-palette-grey-900;
      }
    }
  }
}
