.add-new-bank-account {
  padding-left: 35px;
  padding-right: 35px;

  &__title {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 16px;
    color: $ride-palette-black;
  }

  &__button {
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    background-color: $ride-palette-white;
    border-radius: 8px;
    padding-top: 25px;
    padding-right: 35px;
    padding-left: 35px;
    padding-bottom: 55px;
    margin: auto;
    max-width: 728px;
  }
}
