.tax-advisors-list-filter {
  flex-grow: 0.3333;

  &__container {
    display: flex;
  }
}

.tax-advisors-list-filter + .tax-advisors-list-filter {
  margin-left: 1rem;
}
