.migrate-3pta-modal {
  max-width: 47rem;
  margin: 2.125rem auto 4.5rem;
  padding: 0 map-get($v2-spacers, 16);
}

.migrate-3pta {
  display: flex;
  flex-direction: column;
  width: 37rem;
  margin-left: auto;
  margin-right: auto;

  &__bullet-point {
    margin-top: 0.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__page-title {
    margin-bottom: 0.5rem;
  }

  &__agree {
    margin-bottom: 3rem;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 3.75rem 0;
  }

  &__payment-section {
    &__options {
      margin: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}
