.company-admin-service {
  &__link-title {
    font-weight: 800;
    font-size: 1rem;
    color: $white;
    margin-top: -0.1rem;
  }

  &__link-continue {
    font-weight: 400;
    font-size: 1rem;
    color: $white;
    text-decoration: underline;
    margin-top: -0.1rem;
    padding-left: 0.875rem;

    &:hover {
      font-weight: 800;
    }

    a {
      text-decoration: none;
      color: $white;
    }
  }

  &__link-cancel {
    font-weight: 400;
    font-size: 0.9rem;
    color: $ride-palette-grey-600;
    text-decoration: underline;
    margin-top: -0.065rem;
    padding-left: 0.875rem;
    cursor: pointer;

    &:hover {
      font-weight: 800;
    }
  }

  &__message-ordered {
    font-weight: 500;
    font-size: 0.75rem;
    color: $ride-palette-grey-900;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
      margin-top: 1.25rem;
      text-align: center;
    }
  }
}
