.shareholder-id-file {
  padding-top: map-get($v2-spacers, 40);
  padding-left: map-get($v2-spacers, 48);
  padding-right: map-get($v2-spacers, 48);
  padding-bottom: map-get($v2-spacers, 40);

  display: flex;
  flex-direction: column;
  gap: map-get($v2-spacers, 40);
}
