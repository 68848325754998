.no-results-placeholder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 125px;
  margin-bottom: 125px;

  &.result-card {
    margin: 50px 0;
  }

  &__image {
    margin-bottom: 25px;
  }
}
