.available-club-deals {
  background-color: $ride-palette-white;

  &__wrapper {
    max-width: map-get($spacers, 305);
    margin: auto;
  }

  &__header {
    padding-top: map-get($spacers, 21);
  }

  &__logo-description {
    color: $ride-palette-extra-dark-grey;
    line-height: 31px;
    margin-left: map-get($spacers, 307);
    font-size: 0.7rem;
  }

  &__logo-section {
    padding-bottom: map-get($spacers, 308);
    margin-left: map-get($spacers, 309);
    display: flex;
  }

  &__title-header {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    padding-bottom: map-get($spacers, 35);
    overflow-wrap: break-word;
  }

  &__top-description {
    font-size: 16px;
    padding-bottom: map-get($spacers, 35);
    color: $ride-palette-extra-dark-grey;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .club-deal-card {
      margin-bottom: map-get($spacers, 35);
    }
  }

  &__bottom-description {
    font-style: italic;
    font-size: 14px;
    color: $ride-palette-grey-900;
    padding-bottom: map-get($spacers, 52);
  }

  @include media-breakpoint-up(md) {
    background-color: $ride-palette-white;
    width: 46.563rem;
    border-radius: map-get($spacers, 23);
    margin: auto;

    &__wrapper {
      max-width: 715px;
      margin-left: 35px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 10px;

      .club-deal-card {
        margin-bottom: 20px;
        margin-right: 25px;
      }
    }

    &__header {
      padding-top: 25px;
    }
    &__title-header {
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 33px;
      padding-bottom: 30px;
    }

    &__top-description {
      padding-bottom: 30px;
      color: $ride-palette-extra-dark-grey;
    }

    &__bottom-description {
      margin-right: 52px;
    }

    &__logo-section {
      padding-bottom: 1.5px;
      margin-left: 2px;
      display: flex;
    }
  }
}
