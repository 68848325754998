.process-group-wrapper {
  padding-left: map-get($spacers, 47);
  z-index: 10;

  @include media-breakpoint-up(md) {
    padding-left: map-get($spacers, 6);
  }

  &__heading {
    margin-top: map-get($spacers, 4) * -1;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      margin-top: map-get($spacers, 48) * -1;
    }

    &--COMPLETED {
      font-weight: normal;
    }

    &--IN_PROGRESS {
      font-size: 1.25rem;

      @include media-breakpoint-up(md) {
        font-size: 1.75rem;
      }
    }

    &:not(:hover) .button-expand {
      visibility: hidden;
    }

    .button-expand {
      padding-top: map-get($spacers, 15);
    }
  }

  &__in-progress-steps {
    @include h9-light();
    color: $ride-palette-bright-red;
    font-style: italic;
    flex-basis: 100%;
  }
}
