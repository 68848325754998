.ride-input {
  &__label {
    @include h9();
    display: block;
    color: $ride-extra-dark-grey;
    line-height: 1;
    margin: 0 0 map-get($spacers, 15) 0;
  }

  &__label-description {
    @include h9();
    display: block;
    color: $ride-extra-light-grey;
    line-height: 1;
    margin: 0 0 map-get($spacers, 15) 0;
  }

  &__input {
    @include h8-light();
    line-height: 1;

    display: block;
    border: 1px solid $ride-palette-grey-300 !important;
    border-radius: $border-radius-xs;
    box-shadow: none !important;
    padding: map-get($spacers, 22) map-get($spacers, 21);
    width: 100%;
    flex-grow: 2;

    &--as-textarea {
      height: 230px;
      line-height: 1.5;
    }

    &:disabled {
      color: $ride-extra-dark-grey;
    }
  }

  &--ACCEPTED &__input {
    border-color: $ride-palette-green !important;
  }

  &--ERROR &__input {
    border-color: $ride-palette-error !important;
  }

  & input[disabled] {
    color: $ride-extra-light-grey !important;

    background-color: $ride-palette-grey-300 !important;
    border-color: $ride-palette-grey-300 !important;

    &::placeholder {
      color: $ride-extra-light-grey !important;
    }
  }

  &__optional {
    @include h10-light();
    line-height: 1;

    float: right;
    color: $ride-palette-grey-600;
    margin: 0 0 map-get($spacers, 21) 0;
  }

  &--active &__input,
  & &__input:focus {
    border-color: $ride-palette-black !important;
    outline: none;
  }

  &__wrapper {
    display: flex;
    width: 100%;
  }
}

.react-tel-input {
  font-family: inherit !important;
  font-size: 1rem !important;

  .flag-dropdown,
  .flag-dropdown.open {
    border: none !important;
    background: none !important;
  }

  .selected-flag {
    padding: 0 0 0 1rem !important;
  }
  .selected-flag:hover,
  .selected-flag:active,
  .selected-flag:focus,
  .selected-flag.open {
    background: none !important;
  }

  input.form-control {
    width: 100%;
  }

  .form-control.invalid-number {
    background-color: $ride-palette-white !important;
  }
}
