@keyframes slide {
  from {
    left: -33%;
  }
  to {
    left: 100%;
  }
}

.loading-bar {
  position: relative;
  height: 2px;
  background-color: $ride-palette-grey-300;
  margin-top: map-get($spacers, 21);
  margin-bottom: map-get($spacers, 3);
  overflow: hidden;

  &__trace {
    position: absolute;
    left: -33%;
    height: 100%;
    width: 33%;
    background-color: $ride-palette-green;
    animation: slide 1.5s infinite ease-in-out;
  }
}
