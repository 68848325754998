.task-card {
  border-radius: 0.5rem;
  background-color: $neutral-0;
  box-shadow: 0 0 1rem 0 #1f242812;

  &__header-bar {
    padding: 0.875rem 1.5rem;
    border-radius: 0.5rem;
  }

  &__header {
    height: 56px;
    display: grid;
    grid-template:
      "icon title" 1fr
      "icon badge" 1fr
      / 52px 1fr;
    grid-gap: 0 1rem;

    &--disabled {
      color: $neutral-500;
    }
  }

  &__completion-donut {
    grid-area: icon;
    align-self: center;
  }

  &__title {
    grid-area: title;
    align-self: center;
  }

  &__badge {
    grid-area: badge;
    align-self: flex-end;
  }

  &__content {
    padding: 1rem 5rem;
  }
}
