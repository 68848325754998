.price-section {
  &__price-information-row {
    margin-top: map-get($v2-spacers, 18);
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &__price-information {
    margin-top: map-get($v2-spacers, 24);
  }

  &__total-price {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.3;
  }

  &__divider-2 {
    margin-top: map-get($v2-spacers, 24);
  }

  &__divider-3 {
    margin-top: map-get($v2-spacers, 20);
  }
}
