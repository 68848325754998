.mobile-view-error {
  color: $ride-palette-black;
  font-size: map-get($v2-spacers, 16);

  &__title {
    font-weight: 500;
    font-size: map-get($v2-spacers, 28);
    line-height: map-get($v2-spacers, 36);
    margin-bottom: map-get($v2-spacers, 14);
  }

  &__subtitle {
    font-weight: 400;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    margin-bottom: map-get($v2-spacers, 24);
  }

  &__title-image {
    margin-bottom: 2.813rem;
  }

  &__title,
  &__title-image,
  &__subtitle,
  &__back-button {
    text-align: center;
  }
}

.button--medium {
  font-size: map-get($v2-spacers, 14) !important;
  padding: map-get($v2-spacers, 12) map-get($v2-spacers, 16) !important;
}
