.blank-page {
  padding-top: $spacer;
  background-color: $white;
  flex: 1;

  &--default {
  }

  &--narrow {
    max-width: 37.5rem;
    margin: 0rem auto map-get($v2-spacers, 120) auto;
  }

  &__title {
    font-weight: 500;
    font-size: 3.125rem;
    line-height: 1.2;
    color: $ride-palette-black;
  }

  &__sub-title {
    color: $ride-palette-black;
    font-size: 1rem;
    margin-top: 1rem;
  }

  @include media-breakpoint-down(md) {
    &__title {
      font-size: 2rem;
      line-height: 1.25;
    }
  }
}
