.confirm-legal-documents {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid $border-color;
  border-radius: map-get($v2-spacers, 24);

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }
}
