.select-company-address {
  .ride-checkbox-v2 {
    margin-top: map-get($v2-spacers, 22);
  }

  .ride-new-entry {
    margin-top: map-get($v2-spacers, 22);
  }

  .chapters-order-layout__step-heading {
    margin-bottom: map-get($v2-spacers, 36) !important;
  }

  .chapters-order-layout__step-navigation-controls {
    margin-top: map-get($v2-spacers, 40) !important;
  }
}
