.ride-banner {
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #8f80d0 0%, #bb98df 100%);
  border-radius: map-get($v2-spacers, 8);
  color: white;
  position: relative;

  &__background-wave {
    position: absolute;
    background: url("/assets/ride-banner-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: map-get($v2-spacers, 36);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  &__title {
    margin-left: map-get($v2-spacers, 68);
    margin-right: map-get($v2-spacers, 68);
    margin-top: map-get($v2-spacers, 24);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  &__message {
    margin-left: map-get($v2-spacers, 68);
    margin-right: map-get($v2-spacers, 68);
    margin-top: map-get($v2-spacers, 6);
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  &__button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: map-get($v2-spacers, 68);
    margin-right: map-get($v2-spacers, 68);
    margin-top: map-get($v2-spacers, 16);
    margin-bottom: map-get($v2-spacers, 24);
    padding-top: map-get($v2-spacers, 10);
    padding-bottom: map-get($v2-spacers, 10);
    background-color: $ride-palette-white !important;
    color: #8182d1 !important;
    border-radius: map-get($v2-spacers, 4) !important;
    cursor: pointer;
  }

  &__button-text {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 18);
  }

  &__button-icon {
    display: inline-block;
    width: map-get($v2-spacers, 20);
    height: map-get($v2-spacers, 20);
    background-image: url("/assets/sharedComponents/arrow-top-right-purple.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: map-get($v2-spacers, 8);
  }
}
