.completion-donut {
  min-width: 52px;
  height: 52px;

  &__number {
    color: $primary-success-600;

    &--disabled {
      color: $neutral-100;
    }
  }

  &__sparkle {
    &--disabled {
      color: $neutral-100;
    }

    &--ZERO_PERCENT {
      color: $neutral-100;
    }

    &--SIXTEEN_PERCENT {
      color: $neutral-100;
    }

    &--SIXTY_PERCENT {
      color: $neutral-100;
    }

    &--ONE_HUNDRED_PERCENT {
      color: $primary-success-600;
    }
  }
}
