.chapter-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__title {
    margin-top: map-get($v2-spacers, 80);
    margin-bottom: map-get($v2-spacers, 44);
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 32);
    line-height: map-get($v2-spacers, 45);
  }

  &__message {
    margin-bottom: map-get($v2-spacers, 32);
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 24);
    line-height: map-get($v2-spacers, 36);
  }
}
