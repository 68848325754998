.advertisement-campaign-banner {
  .ride-tile__name .text-short {
    overflow: unset !important;
    text-overflow: unset !important;
    line-clamp: unset !important;
    -webkit-line-clamp: unset !important;
  }

  &__footer {
    padding-left: 0.875rem;
    padding-bottom: 1.25rem;
    text-decoration: underline;
    width: fit-content;

    @include media-breakpoint-up(lg) {
      padding-left: 1.5rem;
    }
  }

  &__footer:hover {
    text-decoration: none;
  }
}
