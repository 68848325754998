@mixin box-hover-shadow() {
  box-shadow: 0 8px 40px rgba($ride-palette-grey-600, 0.4);

  &:hover {
    box-shadow: 0 0 10px rgba($ride-palette-grey-600, 0.1);
  }
}

.ride-tile {
  @include box-hover-shadow();

  position: relative;
  border-radius: $border-radius-md;
  line-height: 1.5;
  transition: all $ride-transition-time;

  &--white {
    background-color: $white;
  }

  &--rideGradient {
    background-image: linear-gradient(109deg, #48ffaf 8%, #82d6e8 99%);
  }

  &--link {
    cursor: pointer;
  }

  &__body,
  &--entireTile {
    &:hover .ride-tile__icon {
      .default-icon {
        display: none;
      }

      .hover-icon {
        display: block;
      }
    }
  }

  & + & {
    margin-top: map-get($spacers, 405);

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 475);
    }
  }

  &__container {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      padding: map-get($spacers, 4);
    }
  }

  &__image-slot {
    align-self: center;
    padding-right: map-get($spacers, 3);

    @include media-breakpoint-up(lg) {
      padding-right: map-get($spacers, 35);
    }
  }

  &__name-column {
    padding-top: map-get($spacers, 32);
    padding-bottom: map-get($spacers, 25);
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 5);

    @include media-breakpoint-up(lg) {
      padding: 0;
      padding-right: map-get($spacers, 35);

      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }
  }

  &__details-slot {
    border-top: 1px solid $ride-palette-grey-300;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    padding-top: map-get($spacers, 35);
    padding-bottom: map-get($spacers, 35);

    @include media-breakpoint-up(lg) {
      border-top: 0;
      min-width: 380px;
      display: flex;
      border-left: 1px solid $ride-palette-grey-300;
    }
  }

  &__name {
    @include h7();
    line-height: 1.2;
    margin-bottom: map-get($spacers, 21);

    .text-short {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      display: -webkit-box;
    }

    @include media-breakpoint-up(lg) {
      @include h4();

      .text-short {
        max-height: 3.6rem;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }
  }

  &__icon {
    position: absolute;

    top: map-get($spacers, 25);
    right: map-get($spacers, 3);

    .hover-icon {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      top: map-get($spacers, 4);
      right: map-get($spacers, 4);
    }
  }

  &__button {
    position: absolute;
    right: map-get($v2-spacers, 32);
    top: calc(50% - 24px);
  }

  &__delete-button {
    position: absolute;
    right: map-get($v2-spacers, 60);
    top: map-get($v2-spacers, 24);
    width: min-content;
  }
}
