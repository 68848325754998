.admin-service-order {
  &--success {
    text-align: center;

    img {
      margin-top: 78px;
    }

    h3 {
      margin-top: 15px;
    }
  }

  &--error {
    margin-top: 1em;
  }

  &--checkbox {
    margin: 15px auto auto;
    max-width: fit-content;
  }

  &--offer {
    max-width: 600px;
    margin: auto;
  }

  &--button {
    text-align: center;
    margin-top: 41px;
    margin-bottom: 41px;
  }

  &--package-list {
    padding-left: 9rem;
    padding-right: 8.5rem;

    li {
      font-size: 0.85em !important;
    }

    @include media-breakpoint-down(md) {
      padding-left: 4rem;
      padding-right: 2.5rem;
    }
  }
}

.admin-service-ordering {
  margin: auto !important;
}
