.ride-form {
  background-color: $ride-palette-grey-100;
  color: $ride-palette-black;

  @include media-breakpoint-down(sm) {
    background-color: transparent;
  }

  &--label {
    font-weight: 600;
    font-size: 14px;
  }

  &--prompt-modal-text-size {
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.3rem;
    }
  }
}
