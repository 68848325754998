.pay-out-review-step {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__heading {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 18);
    line-height: map-get($v2-spacers, 20);
  }

  &__heading-view {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: map-get($v2-spacers, 32);
    margin: map-get($v2-spacers, 32) auto map-get($v2-spacers, 72);
    align-items: center;
  }

  &__transfer-info {
    display: flex;
    flex-direction: row;
  }

  &__company-info {
    display: flex;
    flex-direction: column;
    padding-right: map-get($v2-spacers, 128);
  }

  &__company-name {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    overflow-wrap: break-word;
  }

  &__company-iban {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-grey-medium-dark;
  }

  &__value {
    font-weight: $font-weight-bold;
  }
}
