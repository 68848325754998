.club-deal-card {
  @include box-hover-shadow();

  &--disabled {
    &:hover {
      box-shadow: 0px 8px 40px rgba($ride-palette-grey-600, 0.4);
    }

    cursor: default;
  }

  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: $border-radius-md;
  line-height: 1.5;
  min-height: 167px;
  transition: all $ride-transition-time;
  overflow: hidden;
  width: 305px;

  @include media-breakpoint-up(sm) {
    width: 325px;
  }

  &__overlay {
    height: 0;
    overflow: visible;
    z-index: 1;
    margin-left: auto;
    position: relative;
    top: map-get($spacers, 25);
    right: map-get($spacers, 3);
  }

  &__image {
    flex-grow: 0;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 160px;

    &--no-image {
      background-image: url("/assets/investments/real-estate-placeholder.svg");
      background-size: contain;
      background-color: $ride-palette-grey-100;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__body {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  &__name {
    @include h6-light();
    margin-bottom: map-get($map: $spacers, $key: 2);

    &--disabled {
      opacity: 0.5;
    }
  }

  &__details {
    display: flex;
  }

  &__content {
    flex-grow: 1;

    &--disabled {
      opacity: 0.5;
    }
  }

  &__field {
    display: flex;
  }

  &__field-name {
    @include h10-light();
    color: $ride-palette-grey-600;
    margin-right: 0.25rem;
  }

  &__field-value {
    @include h10-light();
  }

  &__arrow-icon {
    display: flex;
    align-items: flex-end;

    .hover-icon {
      display: none;
    }

    &--disabled {
      visibility: hidden;
    }
  }

  &__completion {
    @include h11-light();
    background-color: $ride-palette-black;
    border-radius: 3px;
    color: $success;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;

    &--disabled {
      background-color: $ride-palette-pale-cyan;
      color: $ride-palette-black;
    }
  }

  &:hover &__arrow-icon {
    .default-icon {
      display: none;
    }

    .hover-icon {
      display: block;
    }
  }
}
