.ride-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;

  &__dialog {
    flex-direction: column;
    display: flex;
    width: 43.5rem;
    height: 41.0625rem;
    border: 1px solid $ride-palette-grey-500;
    border-radius: map-get($v2-spacers, 8);
    background-color: $ride-palette-white;
    margin: auto;
    z-index: 51;
    overflow: hidden;

    &--fit-content-height {
      height: fit-content;
    }

    &--fit-content-width {
      width: fit-content;
    }
  }

  &__backdrop {
    z-index: 50;
    display: block;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $ride-palette-grey-500;
    padding: map-get($v2-spacers, 16) map-get($v2-spacers, 32);
  }

  &__title {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 20);
    line-height: map-get($v2-spacers, 28);
    color: $ride-palette-black;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: map-get($v2-spacers, 40) map-get($v2-spacers, 48);
    overflow-y: auto;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: map-get($v2-spacers, 16);
    border-top: 1px solid $ride-palette-grey-500;
    padding: map-get($v2-spacers, 16) map-get($v2-spacers, 24);
  }
}

@include media-breakpoint-down(xs) {
  .ride-modal {
    &__backdrop {
      display: none;
    }

    &__dialog {
      border-radius: 0;
      height: vh-fix(100);
    }

    &__body {
      padding: map-get($v2-spacers, 24) map-get($v2-spacers, 16);
    }
  }
}

body:has(.ride-modal) {
  overflow: hidden;
}
