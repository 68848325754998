.upload-file-area {
  &__passport-example-image {
    margin-top: map-get($v2-spacers, 24);
    background-image: url("/assets/low-tax-broker/passport-example.png");
    width: 200px;
    height: 141px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__id-front-example-image {
    margin-top: map-get($v2-spacers, 24);
    background-image: url("/assets/low-tax-broker/eu-id-front-example.png");
    width: 200px;
    height: 115px;
    opacity: 0.6;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__id-back-example-image {
    margin-top: map-get($v2-spacers, 24);
    background-image: url("/assets/low-tax-broker/eu-id-back-example.png");
    width: 200px;
    height: 126px;
    opacity: 0.6;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__example-label-image {
    background-image: url("/assets/low-tax-broker/example-label.png");
    top: 70px;
    position: absolute;
    width: 285px;
    height: 150px;
    background-repeat: no-repeat;
  }

  &__section-title {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__uploaded-file-section {
    margin-top: map-get($v2-spacers, 32);
  }

  &__file-upload-box {
    position: relative;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: map-get($v2-spacers, 14);
    padding: map-get($v2-spacers, 30);
    border-radius: map-get($v2-spacers, 12);
    border: 1px dashed $ride-palette-grey-700;
    margin-bottom: map-get($v2-spacers, 32);

    &--inside-drag-and-drop {
      margin-bottom: 0;
      gap: 0;
    }
  }

  &__drag-and-drop-info {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    margin-top: auto;
    margin-bottom: auto;
  }

  &__file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__browse-files-button {
    background: $ride-palette-black;
    color: white;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    border-radius: map-get($v2-spacers, 12);
    padding: map-get($v2-spacers, 10) map-get($v2-spacers, 16);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    width: 100%;
    text-align: center;

    &:hover {
      color: $ride-palette-white;
      background-color: rgba($ride-palette-black, 0.92);
    }

    &--inside-drag-and-drop {
      max-width: 200px;
      margin: 0.88rem auto 0;
    }
  }
}
