.shareholder-details-section-title {
  padding: 0;
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-text {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 20);
    line-height: map-get($v2-spacers, 20);
  }

  &-prefix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: map-get($v2-spacers, 32);
    height: map-get($v2-spacers, 32);
    border-radius: 50%;
    margin-right: map-get($v2-spacers, 16);
    background-color: $ride-palette-grey-100;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  &-success-icon {
    margin-right: map-get($v2-spacers, 16);
    border-radius: 50%;
    width: map-get($v2-spacers, 32);
    height: map-get($v2-spacers, 32);
    background: #e7faf2 url("/assets/sharedComponents/check-green-icon.svg") no-repeat center !important;
  }
}
