.address-search {
  &__list {
    z-index: 9999;
    overflow-y: auto;
    max-height: 12.25rem;
    border: 1px solid #eaebeb;
    margin-top: map-get($v2-spacers, 16) !important;
    border-radius: map-get($v2-spacers, 12);
    font-size: map-get($v2-spacers, 16);

    left: initial !important;
    top: initial !important;
  }

  &__list-item {
    padding-left: map-get($v2-spacers, 16);
    padding-right: map-get($v2-spacers, 16);
    padding-top: map-get($v2-spacers, 10);
    padding-bottom: map-get($v2-spacers, 10);
    margin: 0rem !important;
    :last-child {
      padding-bottom: 1.25rem !important;
    }
  }

  &__list-item:hover {
    background: #fafafa;
  }
}
