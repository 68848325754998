.chain-ownership {
  &__label {
    @include h11-light();
    display: inline-block;
    line-height: 1;
    border-radius: $border-radius-xxs;
    color: $ride-palette-black;
    background-color: $ride-palette-yellow;
    padding: map-get($spacers, 14) map-get($spacers, 15);

    @include media-breakpoint-up(lg) {
      @include h9-light();
    }
  }

  &__label-private {
    @include h10-light();
    color: $ride-palette-grey-600;
    line-height: 16px;

    svg {
      margin-right: map-get($spacers, 14);
      vertical-align: middle;
      margin-top: -4px;
    }
  }

  &__label-in-process {
    font-size: 0;
  }

  &__label-ownership-hierarchy {
    line-height: initial;
  }

  &__label-structure-icon {
    flex-shrink: 0;
  }

  &__label-ownership {
    @include h10-light();
    line-height: 16px;
    color: $ride-palette-purple;
    display: flex;
    flex-direction: row;
    align-items: center;

    .ownership + .ownership {
      color: $ride-palette-yellow-dark;
    }

    svg {
      margin-right: map-get($spacers, 14);
      vertical-align: middle;
      margin-top: -4px;
    }
  }
}
