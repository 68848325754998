.order-application-submitted {
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16rem;

  &__title {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: map-get($v2-spacers, 14);
  }

  &__description {
    margin-bottom: 3.313rem;
    font-size: map-get($v2-spacers, 16);
    font-weight: 500;
  }

  &__download-pdf-summary-button {
    margin-bottom: map-get($v2-spacers, 22);
  }
}
