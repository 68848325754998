.invite-client {
  &__question-text {
    font-size: 16px;
  }

  &__variants {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__client-email {
    width: 305px;
  }
}
