.migrate-admin-service-package {
  max-width: 47rem;
  margin: 2.125rem auto 4.5rem;
  padding: 0 map-get($v2-spacers, 16);

  &__section + &__section {
    margin-top: 3rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
