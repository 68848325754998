.invite-user-form {
  &-submit {
    margin: $space auto;
    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  &__email {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__col {
    flex-basis: auto;
    @include media-breakpoint-up(md) {
      flex-basis: 0;
    }
  }
}
