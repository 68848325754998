.documents-section-admin-operations {
  &__button {
    margin-right: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 3);
  }
}

.documents-section {
  &__title {
    margin-top: 0.25rem !important;
  }
}
