.process-group {
  margin-bottom: map-get($spacers, 4);

  &__heading {
    font-size: 1.68rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 2.06rem;
      margin-top: 0;
    }

    &--COMPLETED {
      font-weight: normal;
    }
  }

  &__description {
    font-size: 1.1rem;

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, 25);
    }
  }

  &--COMPLETED + &--IN_PROGRESS {
    &::before {
      content: "";
      height: 40px;
      display: block;
      border-left: 1px solid $black;
    }
  }
}

.process-group-bis {
  border-left: 1px solid $dark;
  border-bottom: 1px solid transparent;
  min-height: 50px;
  margin-top: -2px;
  margin-left: 0.5px;

  @include media-breakpoint-up(md) {
    min-height: 100px;
  }

  &--IN_PROGRESS {
    margin-left: 0;
    border-left: 2px solid $dark;
  }

  &--before-in-progress {
    min-height: 80px;

    @include media-breakpoint-up(md) {
      min-height: 110px;
    }
  }

  &:last-of-type {
    border: 0;
  }

  &__description {
    color: $ride-palette-grey-600;
    font-size: 0.75rem;
    margin-top: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 47);

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, 25);
      font-size: 1rem;
    }
  }

  .step-button {
    z-index: 1;
  }
}
