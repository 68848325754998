.ride-button {
  line-height: 1.1;
  border-radius: 40px;
  width: auto;
  height: 1.75rem;
  font-size: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: all $ride-transition-time;

  span {
    display: inline-block;

    @include media-breakpoint-up(md) {
      margin-top: 1px;
    }
  }

  svg {
    height: 1rem;
    width: 1rem;
    margin-left: -1px;
    margin-top: -3px;
    stroke-width: 3px;
    transition: all $ride-transition-time;

    @include media-breakpoint-up(md) {
      width: 23px;
      height: 23px;
      margin-top: -3px;
    }
  }

  @include media-breakpoint-up(md) {
    height: 2.5rem;
    font-size: 1.125rem;
    padding: 0.66rem 1.33rem;
  }

  &--min-width {
    min-width: 180px;
  }

  &--dark {
    color: $light;
    background-color: $dark;

    svg {
      color: $success;
    }

    &:hover,
    &:not(:disabled):not(.disabled):active {
      color: $dark;
      background-color: $success;

      svg {
        color: $dark;
      }
    }
  }

  &--dark__outline {
    border: 2px solid $dark;
    color: $dark;
    border-radius: 50px;
    background: none;
    box-shadow: none;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    text-align: center;

    &:hover {
      border: 2px solid $dark;
      color: $dark;
      background: none;
      box-shadow: none;
    }

    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  &--danger__outline {
    border: 2px solid $trader-gmbh-danger-color;
    color: $trader-gmbh-danger-color;
    border-radius: 50px;
    background: none;
    box-shadow: none;
    text-align: center;

    &:hover {
      border: 2px solid $trader-gmbh-danger-color;
      color: $trader-gmbh-danger-color;
      background: none;
    }

    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  &--cross-icon {
    margin-top: -2px;
    @include media-breakpoint-down(sm) {
      margin-top: -2.7px;
    }
  }

  &--primary {
    color: $dark;
    background-color: $success;
    font-weight: 800;
    font-size: 1.125rem;

    svg {
      color: $dark;
    }

    &:hover,
    &:not(:disabled):not(.disabled):active {
      color: $success;
      background-color: $dark;

      svg {
        color: $success;
      }
    }
  }

  &--lg {
    padding: 1rem 2rem;
    height: auto;
  }

  &.hamburger {
    &:hover {
      .hamburger-inner {
        &,
        &:before,
        &:after {
          background-color: $light;
        }
      }
    }
  }

  &--success-outline {
    border: 2px solid $success;
    color: $ride-palette-grey-100;
    border-radius: 50px;
    background: none;
    box-shadow: none;
    text-align: center;

    svg {
      color: $success;
      margin-top: -3px;
      font-size: 1.5rem;
    }

    &--text-align-left {
      @include media-breakpoint-down(sm) {
        text-align: left;
        font-size: 15px;

        svg {
          color: $success;
          margin-top: -2px;
        }
      }
    }

    &:hover {
      border: 2px solid $success;
      color: $ride-palette-grey-100;
      background: none;
    }

    svg {
      color: $success;
    }

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }

  &--strong {
    font-weight: 600;
  }

  &--success-fill {
    border: 2px solid $success;
    color: $dark;
    border-radius: 50px;
    background: $success;
    box-shadow: none;
    text-align: center;

    svg {
      margin-top: -3px;
      font-size: 1.5rem;
    }

    &--text-align-left {
      @include media-breakpoint-down(sm) {
        text-align: left;
        font-size: 15px;

        svg {
          margin-top: -2px;
        }
      }
    }

    &:hover {
      border: 2px solid $success;
      color: $dark;
      background: $success;
    }

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}

.ride-circular-button {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: 0;
  margin-bottom: 0;
  color: $dark;
  font-size: 24px;
  font-weight: 800;
  border: solid 1px $dark;
  box-shadow: none;
  padding: 2px 0 0 0;
  pointer-events: none;

  svg {
    width: 35px;
    height: 35px;
  }

  @include media-breakpoint-down(sm) {
    width: 35px;
    height: 35px;
    margin-left: -17px;
    font-size: 14px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.ride-circular-button-dark {
  @extend .ride-circular-button;

  color: $ride-palette-tilt;
  background-color: $dark;
}
