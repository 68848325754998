.breakpoint-up {
  display: none;

  &--xs {
    @include media-breakpoint-up(xs) {
      display: contents;
    }
  }

  &--sm {
    @include media-breakpoint-up(sm) {
      display: contents;
    }
  }

  &--md {
    @include media-breakpoint-up(md) {
      display: contents;
    }
  }

  &--lg {
    @include media-breakpoint-up(lg) {
      display: contents;
    }
  }

  &--xl {
    @include media-breakpoint-up(xl) {
      display: contents;
    }
  }
}
