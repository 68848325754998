.ride-navbar-obsolete {
  &__container {
    max-width: 1260px;
  }

  min-height: $ride-header-height;

  .dropdown-toggle::after {
    border-bottom: 2px solid $ride-palette-grey-600;
    border-right: 2px solid $ride-palette-grey-600;
    border-top: 0;
    border-left: 0;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    position: relative;
    top: 1px;
    left: 3px;
  }

  .show .dropdown-toggle {
    color: $ride-palette-white !important;
  }

  .show .dropdown-toggle::after {
    border-bottom: 2px solid $ride-palette-white;
    border-right: 2px solid $ride-palette-white;
  }

  .ride-navbar-dropdown .dropdown-menu {
    border-radius: 10px;
    box-shadow: 0 1px 40px rgb(0 0 0 / 10%);
    padding: 0;
    width: 260px;
    margin-left: -130px !important;
    left: 50% !important;
    right: auto !important;

    &::before {
      content: "";
      display: block;
      position: absolute;
      border-style: solid;
      border-width: 0 7px 13px 7px;
      border-color: transparent transparent #ffffff transparent;
      top: -13px;
      left: calc(50% - 7px);
    }
  }

  .dropdown-item {
    padding: 5px 1.5rem 10px 1.5rem;
    font-size: 1rem;
  }

  .dropdown-item:first-child {
    border-radius: 8px 8px 0 0;
    padding-top: 10px;
  }

  .dropdown-item:last-child {
    border-radius: 0 0 8px 8px;
    padding-bottom: 10px;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background: $ride-palette-grey-100;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background: $ride-palette-grey-100;
    color: $ride-palette-dark-green;
  }

  &__nav-hamburger {
    > .button {
      border-radius: 0;
      padding: 0 !important;
      min-width: 0 !important;
      color: $success !important;
      background: transparent !important;
      border: none;
    }

    .hamburger-box {
      top: 4px;
    }

    .hamburger-inner {
      &,
      &:after,
      &:before {
        height: 0.21875rem;
      }
    }
  }

  .nav-item {
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
    display: flex;
    align-items: center;

    .nav-link {
      border: 0;
      color: $ride-palette-grey-600;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      font-size: 0.875rem;
    }
  }
}

.ride-navbar {
  background: var(--neutral-0, $ride-palette-white) !important;
  font-family: $font-family-figtree;
  font-size: 20px;
  line-height: normal;
  padding: 0;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 48px;
    border-bottom: 1px solid $neutral-50;
  }

  .show .dropdown-toggle {
    color: $text-icon-primary-black !important;
    font-weight: $ds-font-weight-heavy;
  }

  .dropdown-toggle::after {
    background-image: url("/assets/uiLibrary/icons/chevron.svg");
    padding: 0.5rem;
    border-top: 0;
  }

  .dropdown-item:hover {
    background: $neutral-50;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    font-weight: $ds-font-weight-heavy;
    color: $text-icon-primary-black;
    background: $neutral-50;
  }

  &__nav-hamburger {
    > .button {
      border-radius: 0;
      padding: 0 !important;
      min-width: 0 !important;
      background-color: transparent;

      &--secondary:focus,
      &--secondary.focus {
        box-shadow: 0 0 0 transparent !important;
      }
    }

    .hamburger-box {
      top: 4px;
    }

    .hamburger-inner {
      &,
      &:after,
      &:before {
        height: 0.21875rem;
        background-color: $text-icon-primary-black !important;
      }
    }
  }

  .nav-item {
    color: $text-icon-primary-black;

    .nav-link {
      width: max-content;
      display: flex;
      padding: 8px;
      border-bottom: none;
      color: $text-icon-primary-black;

      &.active {
        color: $text-icon-primary-black;
        font-weight: $ds-font-weight-heavy;
      }

      &:hover {
        background: $neutral-50;
        color: $text-icon-primary-black;
      }

      &:focus {
        color: $text-icon-primary-black !important;
      }
    }
  }
}
