.review-shareholders-details {
  &__title {
    margin-bottom: map-get($v2-spacers, 112);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 112);
  }
}
