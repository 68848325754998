.admin-service-product-card {
  &__tiers {
    gap: 1rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }
}
