.login-form {
  .ride-input {
    margin-bottom: map-get($spacers, 405);
  }

  .alert-message {
    margin-bottom: map-get($spacers, 37);
  }

  &__panel-content {
    margin-bottom: map-get($spacers, 47);
  }

  &__link {
    @include h9-light();
    margin-bottom: map-get($spacers, 53);
    display: block;
  }

  &__remember-me {
    margin-bottom: map-get($spacers, 4);
    text-align: center;

    label {
      margin-bottom: 0;
    }
  }

  &__submit_area {
    text-align: center;
  }

  &__footer {
    text-align: center;
    margin-top: map-get($spacers, 5);
  }

  .login-form__email-container {
    position: relative;
  }

  .login-form__edit-email {
    position: absolute;
    right: 0;
    top: -0.25rem;
    z-index: 1;
  }

}
