.modal-form-layout {
  display: flex;
  flex-direction: column;
  background: $white;

  @include media-breakpoint-up(md) {
    background: $ride-palette-grey-100;
  }
  flex: 1;

  &__back {
    @include h8-light();
    margin-top: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, 48);
      padding-top: map-get($spacers, 32);
      position: absolute;
    }
  }

  &__container {
    flex: 1 1 auto !important;
    position: relative;
  }

  &__title {
    @include h4();
    text-align: left;
    margin-top: map-get($spacers, 35);
    margin-bottom: map-get($spacers, 54);

    @include media-breakpoint-up(md) {
      @include h2();
      text-align: center;
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: auto;
      margin-right: auto;
      margin-top: map-get($spacers, 52);
    }
  }

  @include media-breakpoint-up(md) {
    &__children {
      background-color: $white;
      border-radius: $border-radius-md;
      max-width: 592px;
      margin: auto;
      margin-bottom: $spacer;
      padding-top: map-get($spacers, 5);
      padding-bottom: map-get($spacers, 65);
      padding-left: map-get($spacers, 5);
      padding-right: map-get($spacers, 5);
    }
  }
}
