.experience-level-per-areas {
  &__select {
    margin-bottom: map-get($v2-spacers, 32);

    &__label {
      font-weight: $font-weight-bold;
      font-size: map-get($v2-spacers, 14);
      line-height: map-get($v2-spacers, 21);
      margin-bottom: map-get($v2-spacers, 8);
    }

    &__input {
      font-weight: $font-weight-bold;
      font-size: map-get($v2-spacers, 16);
      line-height: map-get($v2-spacers, 24);
      color: $ride-palette-grey-600;

      .css-yk16xz-control,
      .css-1pahdxg-control {
        border: 1px solid $ride-input-border-color;
        border-radius: map-get($v2-spacers, 12);
        padding-top: map-get($v2-spacers, 12);
        padding-bottom: map-get($v2-spacers, 12);
        padding-left: map-get($v2-spacers, 16);
      }

      .css-26l3qy-menu {
        color: $ride-palette-black !important;
      }
    }
  }
}
