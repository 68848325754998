.company-information-step {
  &__question-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__your-company {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: map-get($v2-spacers, 24);
    padding-top: 1rem;
  }

  &__question-yes-no {
    .ride-radio-select__options-container {
      display: flex;
      margin-top: map-get($v2-spacers, 24);

      .ride-radio-select__option {
        flex: 1;
        text-align: right;
      }
    }
  }

  &__select-legal-form {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &--v2 {
      margin-top: 1rem;
    }
  }

  &__modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  &__company-search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.25rem;
    align-self: stretch;
  }

  &__company-search-info {
    color: $text-icon-disabled-black;
  }

  &__company-not-listed {
    align-self: flex-start;
  }

  &__corporate-shareholder-question {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  &__corporate-shareholder-question-old-version {
    margin-top: 3rem;
  }

  &__corporate-shareholders-info {
    color: $text-icon-secondary-black;

    &--v2 {
      margin-bottom: 0.5rem;
    }
  }

  &__corporate-shareholders-options {
    margin-top: 0.5rem;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 3rem;

    .ride-checkbox__label {
      font-size: 1rem;
      font-weight: $ds-font-weight-light;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    .ride-checkbox__images {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
