.ride-table {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__body {
    border-radius: 0.75rem;
    border: 1px solid var(--Neutral-100, #e6ebe7);

    &__headers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      background: var(--Neutral-100, #e6ebe7);
      border-radius: 0.75rem 0.75rem 0 0;

      &__header {
        min-width: 8.75rem;
      }
    }

    &__rows {
      display: flex;
      flex-direction: column;

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.75rem 1rem;

        &:last-child {
          border-radius: 0 0 0.75rem 0.75rem;
        }

        &--even {
          background: var(--Neutral-0, #fff);
        }

        &--odd {
          background: var(--Neutral-50, #f5f7f5);
        }

        &__entry {
          min-width: 8.75rem;
        }
      }
    }
  }
}
