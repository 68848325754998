.ride-currency-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  $currency-symbol-space: map-get($v2-spacers, 16);
  $input-v-padding: map-get($v2-spacers, 12);
  $input-h-padding: map-get($v2-spacers, 16);

  &__label {
    display: block;
    flex-grow: 0;
    margin-bottom: map-get($v2-spacers, 8);

    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 22);
  }

  &__input {
    width: 100%;

    outline: none;
    box-shadow: none;

    box-sizing: border-box;
    height: map-get($v2-spacers, 48);

    padding-top: $input-v-padding;
    padding-bottom: $input-v-padding;
    padding-right: $input-h-padding;
    padding-left: $input-h-padding + $currency-symbol-space;

    border-style: solid;
    border-color: $ride-palette-grey-300;
    border-width: 1px;
    border-radius: map-get($v2-spacers, 12);

    &,
    &__currency-symbol {
      color: $ride-palette-black;
      font-style: normal;
      font-weight: $font-weight-bold;
      font-size: map-get($v2-spacers, 16);
      line-height: map-get($v2-spacers, 22);
    }

    &__currency-symbol {
      position: absolute;
      pointer-events: none;

      box-sizing: border-box;
      width: calc($currency-symbol-space + 1px);

      margin-top: $input-v-padding;
      margin-bottom: $input-v-padding;
      margin-left: $input-h-padding;

      padding: 0;

      border: none;
      top: 1px;
      bottom: 1px;
      left: 1px;
    }

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
    }

    &-placeholder,
    &::placeholder {
      color: $ride-palette-grey-600;
      font-weight: $font-weight-medium;
    }

    &:focus {
      border-color: $ride-palette-black !important;
      outline: none;
    }
  }
}
