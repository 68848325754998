.ride-dropdown-item {
  cursor: pointer;
  padding: 0.5rem 1rem;

  &--Danger {
    color: $danger-600;
  }

  &:hover,
  &:active {
    background-color: $neutral-50;
  }

  &__sublabel {
    display: block;
    color: $text-icon-secondary-black;

    &--Danger {
      color: $danger-600;
    }
  }
}
