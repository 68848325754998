.tax-advisor-client-card-legacy {
  &__list {
    @include h10();

    .key {
      @include h10-light();
      color: $ride-palette-grey-900;
    }

    @include media-breakpoint-up(lg) {
      margin-top: map-get($spacers, 14);
    }
  }
}
