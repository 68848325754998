.bank-account-transaction-card {
  border-radius: 10px;
  &__date {
    color: $ride-palette-grey-600;
  }

  &__balance {
    &--positive {
      color: $ride-palette-green;
    }

    &--negative {
      color: $ride-palette-red;
    }
  }
}
