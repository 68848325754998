.ride-admin-breadcrumb {
  &-title {
    margin-top: 75px;
    margin-bottom: 50px;

    & > h2 {
      margin: 0;
    }
  }
}
