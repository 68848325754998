.file-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: map-get($v2-spacers, 14) map-get($v2-spacers, 24);
  background: $ride-palette-light-grey;
  border-radius: map-get($v2-spacers, 12);

  &__file-icon .ride-icon {
    width: map-get($v2-spacers, 36);
    height: map-get($v2-spacers, 36);
    border-radius: map-get($v2-spacers, 8);
  }

  &__file-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-left: map-get($v2-spacers, 16);
  }

  &__name {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 20);
    color: $ride-palette-black;
  }

  &__size {
    font-weight: $font-weight-normal;
    font-size: map-get($v2-spacers, 12);
    line-height: map-get($v2-spacers, 18);
    color: $ride-palette-grey-600;
  }
}
