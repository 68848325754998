.general-information-refactor {
  border-top: 1px solid $ride-palette-grey-500; //from review-entry + review-entry to match
  padding-bottom: map-get($v2-spacers, 36); //from review-entry to match

  .review-entry {
    padding-bottom: 0px; //reset from what was defined in .review-entry to not duplicate
  }

  .ltb-review-field {
    padding-left: map-get($v2-spacers, 48); //from review-entry to match
    padding-right: map-get($v2-spacers, 48); //from review-entry to match
    margin-top: map-get($v2-spacers, 30); //from review-entry__label to match

    &__label {
      margin-top: map-get($v2-spacers, 30); //from review-entry__label to match
      line-height: 1.5; //update from review-entry__label to match
      color: $ride-extra-light-grey; // update from review-entry__label to match
      margin-bottom: 0px; //remove from what was defined in .ltb-review-field__label
    }

    &__label:not(:first-child) {
      margin-top: map-get($v2-spacers, 30); //from review-entry + review-entry
    }

    &__value {
      margin-top: map-get($v2-spacers, 8); //from review-entry__value to match
      line-height: 1.5; //update from review-entry__value to match
    }
  }
}
