.sources-of-wealth-spread {
  .percentage-input-card {
    margin-bottom: map-get($v2-spacers, 32);
  }

  .percentage-total {
    color: $ride-palette-grey-600;
    font-size: map-get($v2-spacers, 14);
    line-height: 1.428;

    &--error {
      color: $ride-palette-red;
    }
    &--success {
      color: $ride-palette-green;
    }
  }
}
