.ride-icon-button {
  border-radius: map-get($v2-spacers, 8);
  padding: map-get($v2-spacers, 10);
  background: $neutral-0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--BIG {
    width: map-get($v2-spacers, 44);
    height: map-get($v2-spacers, 44);
  }

  &--SMALL {
    width: map-get($v2-spacers, 36);
    height: map-get($v2-spacers, 36);
  }

  &:hover {
    background: $neutral-50;
  }

  &:active {
    background: $neutral-100;
  }

  &:disabled {
    background: $neutral-50;
    color: $neutral-200;
  }
}
