.open-bank-account-own-task {
  &__field {
    @include h9-light();
    color: $ride-palette-black;
  }

  &__label {
    color: $ride-palette-grey-600;
    display: inline-block;
    padding-right: 5px;
  }

  &__section {
    padding-top: 13px;
  }
}
