.setup-payment-card {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  &__content {
    padding: 1.5rem;
    border-radius: 0.75rem;
    background: $neutral-50;
  }

  &__options {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    gap: 1rem;
    margin-top: 0.625rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $neutral-200;
  }

  &__sepa-info,
  &__manual-bank-transfer-info {
    display: flex;
    flex-direction: column;
  }

  &__payment-information {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid $neutral-200;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
  }

  &__observation {
    color: $text-icon-disabled-black;
  }

  &__sepa-price {
    color: $primary-success-600;
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__alert {
    margin-top: 1rem;
  }

  &__payment-date-info {
    color: $text-icon-disabled-black;
    text-align: center;
  }

  &__copyable-iban {
    width: fit-content;
  }
}
