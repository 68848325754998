.short-order-form-step-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  max-width: 37.5rem;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    margin: 0;
  }

  &__header {
    &--without-controls {
      margin-top: 4.25rem;

      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }
    }
  }

  &__controls {
    margin: 1rem 0;
  }

  &__title {
    margin-bottom: 0.5rem;
    display: block;
    hyphens: auto;
  }

  &__subtitle {
    display: block;
    color: $text-icon-secondary-black;
  }

  &__content {
    min-height: 39.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 3rem;

    @include media-breakpoint-down(md) {
      min-height: revert;
      max-width: 100%;
    }
  }

  &__continue-button-section {
    display: grid;
    border-top: 1px solid $neutral-200;
    padding: 1rem 0;
    margin-bottom: 1.5rem;
    align-self: stretch;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  &__continue-button {
    display: flex;
    justify-self: flex-end;
    width: 18rem;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
