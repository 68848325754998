.ride-outlined-checkbox {
  border: 3px solid $ride-palette-black;
  padding: map-get($spacers, 42) 0 map-get($spacers, 42) map-get($spacers, 41);
  border-radius: $border-radius-lg;
  color: $ride-palette-black;
  width: 21.563rem;
  text-align: left;
  @include media-breakpoint-up(lg) {
    width: 25.769rem;
  }

  &--active {
    border-color: $success;
  }

  &--unavailable {
    border-color: $ride-palette-grey-300;
    color: $ride-palette-grey-300;
  }

  label {
    font-size: 1.5rem;
  }

  svg {
    top: -0.313rem;
    width: 1.594rem;
    height: 1.594rem;
    vertical-align: initial !important;
  }

  .ride-checkbox__images {
    width: 1.594rem;
    height: 1.594rem;
    vertical-align: sub;
    margin-right: 0.875rem;
  }
}
