.pay-out-input-step {
  display: flex;
  flex-direction: column;

  &__transfer-view {
    display: flex;
    flex-direction: row;
    width: fit-content;
    gap: map-get($v2-spacers, 44);
    margin: map-get($v2-spacers, 40) auto map-get($v2-spacers, 72);
    align-items: center;
  }

  &__account-text {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    width: 12.5rem;
    text-align: right;
  }

  &__company-info {
    display: flex;
    flex-direction: column;
    width: 12.5rem;
  }

  &__company-name {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    overflow-wrap: break-word;
  }

  &__company-iban {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-grey-medium-dark;
  }

  &__alert {
    margin-top: map-get($v2-spacers, 44);
  }

  .ride-currency-input {
    width: 18.75rem;
    margin: auto;
  }
}
