.tax-advisor-profile {
  font-size: $font-size-base;
  font-weight: 500;

  &__title {
    font-size: 0.75rem;
    color: $ride-palette-grey-600;
  }

  &__name {
    font-size: 0.75rem;
    color: $ride-palette-black;
  }

  &__image {
    background-image: url("/assets/client/profile.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    width: 65px;
    height: 65px;

    @include media-breakpoint-up(md) {
      width: 85px;
      height: 85px;
    }
  }
}
