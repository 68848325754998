.product-section-card {
  border: 1px solid #d1d7d2;
  border-radius: 0.5rem;
  height: auto;
  padding: 1rem;
  margin: 0;
  background: $neutral-0;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    max-width: 37.5rem;
  }

  &--disabled {
    .product-section-card__can-be-disabled {
      opacity: 0.6;
    }
  }

  &__tooltip-icon-wrapper {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    color: $primary-success-600;
    justify-content: center;
  }

  &__price {
    color: $primary-success-600;
  }

  &__main-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: -0.125rem;
    align-self: stretch;
  }

  &__icon-and-input-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.625rem;
    width: 100%;
  }

  &__icon-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: auto;
  }

  &__icon {
    height: 2rem;
    color: $primary-success-600;
  }
}
