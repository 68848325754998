.loginCodeForm {
  text-align: center;

  &__description {
    font-size: 1rem;
  }
  &__panel-content {
    margin-bottom: map-get($spacers, 47);
  }
  &__inputLabel {
    font-weight: bold;
    margin-top: 1.25rem;
  }
  &__codeInput {
    margin: 0.313rem auto;
  }
  &__reSendCodeLink {
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
  }

  &__countdown {
    font-size: 1rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
