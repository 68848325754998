.ride-sticker {
  width: fit-content;
  height: fit-content;
  border-radius: 0.375rem;

  &--RideGreen {
    background-image: linear-gradient(114deg, $success 7%, $ride-palette-pale-cyan 100%);
  }

  &--Transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  border: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  @include media-breakpoint-up(lg) {
    padding-top: 1rem;
    padding-bottom: 0.875rem;
    padding-right: 2.5rem;
    padding-left: 1.313rem;
  }

  padding-top: 8.3px;
  padding-left: 10.7px;
  padding-bottom: 7.4px;
  padding-right: 10.6px;

  &__text {
    font-size: 0.5rem;
    font-weight: 900;
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }

  &__icon {
    margin-right: 0.525rem;
    width: 1.719rem;
    height: 1.331rem;
    @include media-breakpoint-up(lg) {
      margin-right: 1rem;
      width: 3.313rem;
      height: 2.563rem;
    }
  }
}
