.investment-card-group {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: map-get($spacers, 54);

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, 49);
    }
  }

  &__title {
    display: flex;
  }

  &__title-text {
    @include h4();
    margin-left: 10px;
    place-self: center;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      @include h6();
    }
  }

  &__wrapper--first > &__vertical-line {
    height: 26px;
  }

  &__vertical-line {
    border-right: 1px solid $ride-palette-grey-200;
    width: 30px;
    height: 26px;

    @include media-breakpoint-up(md) {
      height: 36px;
    }
  }
}
