.selected-product-card {
  border: 1px solid #d1d7d2;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: $font-family-figtree;
  background: $neutral-0;

  &__product-information {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: $text-icon-primary-black;
    font-size: 1rem;
    font-weight: $ds-font-weight-heavy;
  }

  &__title-section {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__pricing-information {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: flex-end;
    align-items: flex-end;

    &__price {
      color: $primary-success-600;
      font-size: 1rem;
      font-style: normal;
      font-weight: $ds-font-weight-heavy;
      line-height: 1.25rem;
    }

    &__due {
      color: $text-icon-primary-black;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: $ds-font-weight-light;
      line-height: 1.125rem;
    }
  }

  &__icon {
    max-height: 1.5rem;

    svg * {
      fill: $primary-success-600;
    }
  }
}
