.tax-advisor-clients {
  margin-top: 3rem;

  @include media-breakpoint-up(lg) {
    margin-top: 2.5rem;
  }

  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 1.5rem;
  }

  &__search-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
  }

  &__search-loader {
    transition: opacity 0.2s ease-in-out;

    &--visible {
      opacity: 1;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }

  &__empty-list {
    color: $neutral-500;
  }
}
