%client-input {
  height: 3rem;
  font-size: 1rem;
  border: 1px solid $ride-input-border-color;
  border-radius: 12px;
  outline: none;
}

.client-select {
  &__label {
    @include h9();
  }

  &__container {
    border: none !important;
    outline: none !important;
    border-radius: 12px;
  }

  &__input input:focus {
    box-shadow: none;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicators &__dropdown-indicator {
    &,
    &:hover {
      color: $ride-palette-black;
      cursor: pointer;
    }
  }

  div &__control {
    cursor: pointer;

    @extend %client-input;
    padding: 1px;
    box-shadow: none;

    &--align-caret-right {
      div {
        flex-direction: row;
      }
    }

    &--align-caret-left {
      div {
        flex-direction: row-reverse;

        div.client-select__value-container {
          justify-content: flex-end;
        }
      }
    }

    &:hover {
      border-color: $ride-input-border-color;
    }

    &--menu-is-open {
      box-shadow: 0 -16px 40px -10px rgba(0, 0, 0, 0.1);
      z-index: 9999;
    }

    &--is-focused {
      &,
      &:hover {
        padding: 1px;
      }
    }
  }

  &__container--error &__control {
    &,
    &:hover {
      border-width: 2px;
      border-color: $ride-palette-bright-red;
      padding: 0;
    }
  }

  &__container &__menu {
    margin-bottom: 0;
    margin-top: -1rem;
    padding-top: 1.25rem;
    z-index: 1;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &__container &__menu-list {
    padding: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &__container &__option {
    font-size: 16px;

    & + & {
      border-top: 1px solid $ride-input-border-color;
    }

    &,
    &--is-selected {
      padding-left: 3rem;
      background-color: $ride-palette-white;
      color: $ride-palette-black;
    }

    &--is-focused,
    &:active,
    &:focus {
      background-color: $ride-palette-grey-300;
      cursor: pointer;
    }
  }
}
