.knowledge {
  margin-bottom: map-get($map: $spacers, $key: 5);

  &__header {
    @extend %view-header;
  }

  &__social-container {
    @include media-breakpoint-up(md) {
      max-width: 18.125rem;
    }
  }

  &__link {
    background-image: url("/assets/client/knowledge-arrow-link.svg");
    background-position: center right;
    background-repeat: no-repeat;
    display: block;
  }

  &__documents-list {
    position: relative;
  }

  &__documents-locked {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__text {
      max-width: 85%;
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        max-width: 48%;
      }
    }
  }
}
