.company-lei-number {
  &__upsell-container {
    font-family: Figtree, sans-serif;
    margin-top: 1rem;
  }

  &__checkbox {
    &__label {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }

  &__text-group {
    display: flex;
    flex-direction: column;
  }

  &__title {
  }

  &__description {
    color: darkgrey;
  }

  &__cta2 {
    color: #0e8127;
  }

  &__card {
    padding: 1.12rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
}
