.company-creation-list {
  &-row {
    align-items: flex-start;
  }

  &-cell-actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-table {
    background: $ride-palette-white;
    @include shadow3();
  }

  &-secondary-text {
    color: $ride-palette-grey-600;
  }

  &-small {
    @include h10-light();
  }

  &-button-fullwidth {
    width: auto;
    margin-top: 0;
    @include media-breakpoint-up(sm) {
      width: 100%;
      margin-top: $space;
    }
  }

  &-button-inline {
    margin-right: map-get($spacers, 2);
  }
}
