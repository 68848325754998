.ride-overview {
  background-color: $ride-palette-grey-100;
  padding-bottom: 125px;

  &__content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
    }
  }

  &__children {
    flex-grow: 1;
  }

  &__side {
    min-width: 280px;
    margin-top: -36px;
  }

  @include media-breakpoint-up(lg) {
    &__children {
      padding-right: map-get($spacers, 47);
    }

    &__side {
      margin-top: 0;
      padding-top: 125px;
    }
  }

  &__header {
    display: flex;
    color: $ride-palette-black;
    background-color: white;

    &__container {
      display: flex;

      @include media-breakpoint-up(sm) {
        padding: 0;
      }
    }

    &__column {
      flex-grow: 2;
      margin-bottom: map-get($spacers, 65);

      @include media-breakpoint-up(md) {
        margin-bottom: map-get($spacers, 55);
      }
    }

    &__icon {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__title {
      @include h3();
      line-height: 1;
      margin-top: map-get($spacers, 475);
      margin-bottom: map-get($spacers, 37);

      @include media-breakpoint-up(md) {
        @include h1();
        margin-top: map-get($spacers, 67);
        margin-bottom: map-get($spacers, 35);
      }
    }

    &__subtitle {
      @include h8-light();
      max-width: 514px;
    }

    &__button {
      margin-top: map-get($spacers, 25);
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-up(md) {
        margin-top: map-get($spacers, 41);
        justify-content: flex-start;
      }
    }

    &__button-override {
      @extend .button--medium;

      @mixin small-button() {
        @include h10-light();
        line-height: 1.5;
        $padding-size: 15px;
        border: 0;
        border-radius: $padding-size;
        padding: $padding-size/3 $padding-size;
        min-width: 105px;
      }

      @include media-breakpoint-down(sm) {
        @include small-button();
      }
    }
  }
}
