.ride-table-header {
  width: 100%;

  &__title {
    padding: 9px 30px;
    background-color: $ride-palette-grey-300;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      color: $ride-palette-black;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  &__sort {
    display: flex;
  }

  &__columns {
    padding: 23px 30px;
    border-bottom: 1px solid $ride-palette-grey-200;
    background-color: $ride-palette-tilt;

    @include media-breakpoint-up(md) {
      display: grid;
    }

    &[data-columns="1"] {
      grid-template-columns: repeat(1, 1fr);
    }

    &[data-columns="2"] {
      grid-template-columns: repeat(2, 1fr);
    }

    &[data-columns="3"] {
      grid-template-columns: repeat(3, 1fr);
    }

    &[data-columns="4"] {
      grid-template-columns: repeat(4, 1fr);
    }

    &[data-columns="5"] {
      grid-template-columns: repeat(5, 1fr);
    }

    &[data-columns="6"] {
      grid-template-columns: repeat(6, 1fr);
    }

    &[data-columns="7"] {
      grid-template-columns: repeat(7, 1fr);
    }
  }

  &__column-label {
    align-items: center;
    @include h10();
    color: $ride-extra-light-grey;
    display: flex;

    &--aligned {
      &-left {
        justify-self: left;
      }

      &-right {
        justify-self: right;
      }

      &-center {
        justify-self: center;
      }
    }

    &-text {
      margin-right: 5px;
    }
  }

  &__sort-icon {
    cursor: pointer;
    width: 22px;
    height: 22px;

    &:hover {
      color: $ride-palette-black;
    }
    &--active {
      color: $ride-palette-black;
    }
  }
}
