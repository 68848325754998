.company-actively-operating {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;

  &__description {
    margin-top: map-get($v2-spacers, 8);
  }

  &__subquestion {
    margin-top: map-get($v2-spacers, 24);
  }
}
