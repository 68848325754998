.ride-text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &__label {
    display: block;
    flex-grow: 0;
    margin-bottom: map-get($v2-spacers, 8);

    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 22);
  }

  &__input {
    outline: none;
    box-shadow: none;

    padding: map-get($v2-spacers, 12) map-get($v2-spacers, 16);

    color: $ride-palette-black;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 22);

    border-style: solid;
    border-color: $ride-palette-grey-300;
    border-width: 1px;
    border-radius: map-get($v2-spacers, 12);

    &--error {
      border-color: $ride-palette-red;
    }

    &::placeholder {
      color: $ride-palette-grey-600;
      font-weight: $font-weight-medium;
    }

    &:focus {
      border-color: $ride-palette-black !important;
      outline: none;
    }

    &--as-textarea {
      height: 14.375rem;
      line-height: map-get($v2-spacers, 24);
      padding: map-get($v2-spacers, 16);
    }
  }
}
