.shareholder-details-citizenship-information {
  display: flex;
  flex-direction: column;

  .shareholder-details-section-title {
    margin-bottom: map-get($v2-spacers, 32);
  }

  &__field + &__field {
    margin-top: map-get($v2-spacers, 30);
  }
}
