.ride-badge {
  font-family: $font-family-display;
  font-weight: $font-weight-normal;
  font-size: 0.9rem;
  padding: map-get($spacers, 1);
  border: 1px solid;
  border-radius: 3px;
  vertical-align: middle;
  white-space: nowrap;
}
