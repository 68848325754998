.view-security-questions-modal {
  margin: auto auto;

  &__entry {
    display: flex;
    flex-direction: column;

    & + & {
      margin-top: map-get($v2-spacers, 36);
    }
  }

  &__entry-label {
    font-weight: $font-weight-medium;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 16);
    color: $ride-palette-grey-600;
  }

  &__question {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 16);
    color: $ride-palette-black;
    margin-top: map-get($v2-spacers, 12);
  }

  &__answer {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    color: $ride-palette-black;
    margin-top: map-get($v2-spacers, 20);
  }
}
