.ride-checkbox {
  &__label {
    &--default {
      @include h10-light();
    }
    &--medium {
      @include h8-medium();
    }
    display: inline;
    line-height: 18px;
    cursor: pointer;
  }

  &__input {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }

  &__input:hover + &__images span {
    color: $ride-palette-grey-600;
  }

  &__input + &__images {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: map-get($map: $spacers, $key: 14);
    vertical-align: text-bottom;

    span {
      position: absolute;
      top: 0;
      left: 0;
      color: $ride-palette-grey-200;
    }

    .ride-checkbox__frame {
      opacity: 1;
      &--error {
        color: red;
      }
    }

    .ride-checkbox__selected {
      opacity: 0;
    }

    .ride-checkbox__indeterminate-selected {
      opacity: 0;
    }
  }

  &--normal &__input:checked + &__images {
    .ride-checkbox__frame {
      opacity: 0;
    }

    .ride-checkbox__selected {
      opacity: 1;
      color: $ride-palette-extra-dark-grey;
    }
  }

  &--indeterminate &__input:checked + &__images {
    .ride-checkbox__frame {
      opacity: 0;
    }

    .ride-checkbox__indeterminate-selected {
      opacity: 1;
      color: $ride-palette-extra-dark-grey;
    }
  }
}
