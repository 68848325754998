.natural-person {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;

  &__checkbox {
    font-size: 1rem;
  }
  &__input {
    margin-bottom: 1.875rem;
  }
}
