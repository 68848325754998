.investing-years-per-areas {
  &__input {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
  }

  //due to RideInput error message, using margin top instead of margin-bottom
  &__input + &__input {
    margin-top: map-get($v2-spacers, 32);
  }
}
