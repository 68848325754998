.file-uploaded {
  color: $ride-palette-pale-cyan;
  padding-top: map-get($spacers, 3);

  &__name {
    display: inline-block;
    margin-right: map-get($spacers, 21);
    vertical-align: middle;
  }

  &__delete {
    margin-top: map-get($spacers, 3);

    @include desktop() {
      margin-top: 0;
      float: right;
    }
  }
}
