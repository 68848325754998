.auth-header {
  text-align: center;
  margin-bottom: map-get($spacers, 47);

  &__title {
    @include h3();
    margin: 0;
    margin-bottom: map-get($spacers, 3);
    color: $ride-palette-black;
  }

  &__subtitle {
    @include h8-light();
    width: 285px;
    margin: auto;
    padding: 0;
    background-image: none;
    font-weight: 400;
    color: $ride-palette-black;
  }
}
