.company_assets_tile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $ride-palette-white;
  border: 1px solid $ride-palette-grey-200;
  border-radius: $ride-radius;
  height: 130px;
  width: 130px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    height: 150px;
    width: 150px;
  }

  &:hover {
    border-color: $ride-palette-grey-600;
  }

  &--selected {
    border-color: $ride-palette-black !important;

    .business_purpose_tile__label {
      @include h9();
    }
  }

  &__label {
    @include h9-light();
  }

  &__icon {
    $icon-size: 50px;
    height: $icon-size;
    width: $icon-size;
    margin-top: map-get($spacers, $key: 1);
    margin-bottom: map-get($spacers, $key: 32);

    @include media-breakpoint-up(md) {
      margin-top: map-get($spacers, $key: 25);
      margin-bottom: map-get($spacers, $key: 35);
    }

    &--REAL_ESTATE {
      background-image: url("/assets/sharedComponents/real-estate.svg");
    }

    &--HOLDING {
      background-image: url("/assets/sharedComponents/holding.svg");
    }

    &--SHARES_AND_FUNDS {
      background-image: url("/assets/sharedComponents/shares-and-funds.svg");
    }

    &--DERIVATIVES {
      background-image: url("/assets/sharedComponents/derivatives.svg");
    }
  }
}
