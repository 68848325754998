.download-and-agree-pending-task {
  &__check-icon {
    color: $primary-success-400;
  }
  &__already-accepted {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    height: 3rem;
    color: $text-icon-disabled-black;
  }
}
