.profile-details-phone-card {
  &__phone {
    @include h8();
  }

  &__phone-edit {
    display: block;
    text-align: right;
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      display: inline-block;
      float: right;
    }
  }
}
