.document-card {
  width: 18rem;
  height: 14.25rem;
  background: $ride-palette-grey-100;
  border-radius: map-get($v2-spacers, 12);
  padding: map-get($v2-spacers, 24);
  display: flex;
  flex-direction: column;

  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($v2-spacers, 20);

    .ride-badge-status {
      margin-right: auto;
      display: flex;
      align-items: center;
    }
  }

  &--text {
    font-size: map-get($v2-spacers, 20);
    line-height: map-get($v2-spacers, 28);
    font-weight: 500;
    color: $ride-palette-black;
    margin-bottom: map-get($v2-spacers, 22);
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &--button {
    transition: 0.3s ease-in-out;
    &:hover {
      color: $ride-palette-white;
      background-color: rgba($ride-palette-black, 0.92);
    }
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}
