.product-card {
  border: 1px solid #d1d7d2;
  border-radius: 0.5rem;
  height: auto;
  padding: 1rem;
  cursor: pointer;
  margin: 0;
  background: $neutral-0;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  &:has(input:checked:not(:disabled)) {
    border: 1px solid $primary-success-600;
    outline: 1px solid $primary-success-600;
  }

  &:has(input:not(:checked):disabled),
  &--disabled {
    .product-card__can-be-disabled {
      opacity: 0.6;
    }
  }

  &--not-clickable {
    cursor: default;
  }

  &__main-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: -0.125rem;
    align-self: stretch;
  }

  &__icon-and-input-area {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  &__input-and-tooltip-area {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
  }

  &__input-container {
    align-self: stretch;
    max-height: 1.5rem;
  }

  &__tooltip-icon-wrapper {
    display: flex;
    color: $primary-success-600;
  }

  &__icon-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: auto;
  }

  &__icon {
    flex: 1;
    height: 2rem;
    color: $primary-success-600;
  }

  &__price {
    color: $primary-success-600;
    line-height: 1.25rem;
  }

  &__price_free {
    line-height: 1.25rem;
  }

  &__learn-more-button {
    position: relative;
    bottom: -0.125rem;
    padding-left: 0 !important;
    color: $primary-success-600;
    line-height: 1rem;

    &:hover {
      color: $primary-success-700;
    }
  }

  &__checkbox {
    width: map-get($v2-spacers, 24);
    height: map-get($v2-spacers, 24);
    appearance: none;

    &:not(:checked) {
      background: url("/assets/uiLibrary/icons/checkbox-unchecked.svg") no-repeat center;
    }

    &:checked {
      background: url("/assets/uiLibrary/icons/checkbox-checked.svg") no-repeat center;
    }

    &:disabled:checked {
      background: url("/assets/uiLibrary/icons/checkbox-checked-gray.svg") no-repeat center;
    }
  }

  &__radio {
    width: map-get($v2-spacers, 24);
    height: map-get($v2-spacers, 24);
    appearance: none;

    &:not(:checked) {
      background: url("/assets/uiLibrary/icons/radio-unchecked.svg") no-repeat center;
    }

    &:checked {
      background: url("/assets/uiLibrary/icons/radio-checked.svg") no-repeat center;
    }

    &:disabled:checked {
      background: url("/assets/uiLibrary/icons/radio-checked-gray.svg") no-repeat center;
    }
  }
}
