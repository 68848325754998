.ride-search {
  &-icon {
    margin-right: 0.438rem;
  }

  &-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: $ride-palette-white;
    padding-left: 0.813rem;
    border-radius: 5px;
    border: solid 1px $ride-palette-grey-300;

    .form-group {
      margin: 0;
      width: 100%;
    }

    .company-creation-list-search {
      border: none !important;
      background: transparent !important;

      &:focus {
        box-shadow: none;
      }
    }

    .input-group {
      border: none !important;
      width: 100%;
    }

    .form-control {
      box-shadow: none;
    }
  }
}
