.investment-asset-management {
  &__title {
    @include h4-light();
    margin: map-get($spacers, 14) 0;
  }

  &__pair {
    @include h8-light();
    margin-top: map-get($spacers, 1);
  }

  &__key {
    color: $ride-extra-light-grey;
    margin-right: map-get($spacers, 1);
  }

  &__value {
    color: $ride-palette-black;
  }

  @include media-breakpoint-up(lg) {
    &__title {
      @include h2();
    }
  }
}
