.panel {
  width: 325px;
  background: $white;
  margin: auto;
  border-radius: $border-radius-md;
  box-shadow: 0px 8px 40px rgba($ride-palette-grey-600, 0.4);
  padding: map-get($spacers, 42) map-get($spacers, 35);
  @include h9-light();

  &__title {
    @include h8();
    text-align: center;
    margin-bottom: map-get($spacers, 405);
  }
}
