.trades-last-three-years {
  margin-bottom: map-get($v2-spacers, 30);

  &__label {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 14);
    line-height: map-get($v2-spacers, 21);
    margin-bottom: map-get($v2-spacers, 8);
  }

  &__input {
    font-weight: $font-weight-bold;
    font-size: map-get($v2-spacers, 16);
    line-height: map-get($v2-spacers, 24);
    color: $ride-extra-light-grey;
    border-radius: map-get($v2-spacers, 12);
  }

  .selected {
    color: $ride-palette-black;
  }
}
