.multiple-folders-selection {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-md;

  &-header {
    width: 100%;

    @include media-breakpoint-up(md) {
      padding-top: map-get($spacers, 47);
      border-top-right-radius: $border-radius-md;
      border-top-left-radius: $border-radius-md;
      position: absolute;
      top: 0;
      text-align: center;
    }

    &__info {
      font-size: 16px;
      font-weight: bold;
    }

    &__hint {
      @include h8-light();
      font-size: 14px;
    }
  }
}
